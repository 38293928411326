import React, { useState } from 'react';
import { PropertySearch } from 'types/SearchType';
import Image from 'theme/Image';
import DateUtils from 'services/DateUtils';
import Messages from 'services/i18n/Messages';
import StringUtils from 'services/StringUtils';
import {
  AspectRatio,
  BedOutlined, Favorite, FavoriteBorderOutlined,
  LuggageOutlined,
  PlaceOutlined, ThumbDownAlt, ThumbDownAltOutlined,
} from '@material-ui/icons';
import { TemplateIcon } from '@heroicons/react/outline';
import { IconButton } from '@material-ui/core';
import { useSearchesBackend } from 'network/queries/SearchQueries';
import { NotificationService } from 'lib/notification';
import signInService from 'services/SignInService';
import { CandidatureShort } from 'types/Candidature';
import PropertyCandidatureTags from 'pages/common/PropertyCandidatureTags';

type Props = {
  property: PropertySearch
  searchId?: string,
  candidatures?: CandidatureShort[]
  showSeen?: boolean
};

export default function SearchPropertyCard(
  {
    property,
    searchId,
    showSeen,
    candidatures,
  }: Props,
) {
  const [submitting, setSubmitting] = useState(false);
  let image: string | undefined;
  if (property.imageURL && property.imageURL !== '') {
    image = property.imageURL.slice(1, -1).split(',')[0];
  }

  const searchesQueries = useSearchesBackend();
  const { saveClientSearchProperty } = searchesQueries;

  const toShortList = () => {
    if (searchId) {
      setSubmitting(true);
      saveClientSearchProperty.mutateAsync({
        data: { isTrash: false, isShortList: !property.searchData?.isShortlist },
        propertyId: property?.id || '',
        searchId,
      }).then(() => NotificationService.notifySuccess(Messages.t('notifications.update')))
        .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
        .finally(() => setSubmitting(false));
    } else {
      signInService.setShowSignInModal(true, property?.id || '');
    }
  };

  const discardProperty = () => {
    if (searchId) {
      setSubmitting(true);
      saveClientSearchProperty.mutateAsync({
        data: { isTrash: !property?.searchData?.isTrash, isShortList: false },
        propertyId: property?.id || '',
        searchId,
      }).then(() => NotificationService.notifySuccess(Messages.t('notifications.update')))
        .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
        .finally(() => setSubmitting(false));
    } else {
      signInService.setShowSignInModal(true, property?.id || '');
    }
  };
  const candidature = candidatures
    && candidatures.filter((data) => (
      property.proID && property.proID === data.propertyID
    ) || (
      property.id === data.swtID
    ))[0];

  return (
    <div className={`property-search-card ${(property.data?.seen && showSeen) ? 'seen' : ''}`}>
      <div className="property-image-container">
        <Image alt="property" src={image} />
        <div className="image-overlay">
          <div className="tag-container">
            <PropertyCandidatureTags candidature={candidature} property={property} />
          </div>
          <div className="actions-container" role="presentation" onClick={(e) => e.preventDefault()}>
            <IconButton className="rounded-icon-button" disabled={submitting} onClick={toShortList}>
              {
                property.searchData?.isShortlist ? (
                  <Favorite />

                ) : (
                  <FavoriteBorderOutlined />
                )
              }
            </IconButton>
            <IconButton className="rounded-icon-button" disabled={submitting} onClick={discardProperty}>
              {
                property?.searchData?.isTrash ? (
                  <ThumbDownAlt />

                ) : (
                  <ThumbDownAltOutlined />
                )
              }
            </IconButton>
          </div>
        </div>
      </div>
      <div className="property-search-card-content">
        <div className="property-price-row">
          <div className="property-price">
            {
              property.price
            } €
          </div>
          <div>
            {
              DateUtils.getPrettyDateDeltaFromNow(property.publishedAt)
            }
          </div>
        </div>
        <div className="property-address">
          <PlaceOutlined />
          <span>{
            property.address
              ? property.address
              : `${StringUtils.getPrettyAddress(property.city)}, ${property.postalCode || ''}`
          }
          </span>
        </div>
        <div className="property-info-container">
          {
            !!property.rooms && (
              <div className="property-info">
                <TemplateIcon />
                <span> {Messages.t('property.rooms', { smart_count: property.rooms })}</span>
              </div>
            )
          }
          {
            !!property.bedrooms && (
              <div className="property-info">
                <BedOutlined />
                <span> {Messages.t('property.bedroom', { smart_count: property.bedrooms })}</span>
              </div>
            )
          }
          {
            !!property.area && (
              <div className="property-info">
                <AspectRatio />
                <span> {Messages.t('property.area', { smart_count: property.area })}</span>
              </div>
            )
          }
          {
            !!property.furnished && (
              <div className="property-info">
                <LuggageOutlined />
                <span>{Messages.t('property.furnished')}</span>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}
