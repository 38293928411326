import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Messages from 'services/i18n/Messages';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import {
  booleanToString,
  mailRegex,
  stringToBoolean,
  stringToNumber,
} from 'lib/form/FormUtils';
import PhoneNumberInputWrapper from 'lib/form/PhoneNumberInputWrapper';
import SelectWrapper from 'lib/form/SelectWrapper';
import { contractTypeTypeEnum, garantProSituationTypeEnum, proSituationTypeEnum } from 'types/forms/CandidateForm';
import SpinButton from 'theme/SpinButton';
import { useDocumentBackend } from 'network/queries/DocumentQueries';
import { NotificationService } from 'lib/notification';
import { Garant, GarantFrom, garantTypeEnum } from 'types/Garant';
import { useCandidatureBackend } from 'network/queries/CandidatureQueries';

type Props = {
  id?: string,
  garant?: Garant,
  contactList?: string[]
  candidatureId?: string
  onSubmitted?: () => void
};

export default function GarantInformationsForm(
  {
    garant,
    id,
    contactList,
    candidatureId,
    onSubmitted,
  }: Props,
) {
  const [submitting, setSubmitting] = useState(false);
  const [apiErrors] = useState({});
  const candidatureQueries = useCandidatureBackend();
  const { createGarant } = candidatureQueries;
  const documentQueries = useDocumentBackend();
  const { updateDocumentGroupContact } = documentQueries;
  const toGarantForm = (garantData: Garant): GarantFrom => ({
    ...garantData,
    monthlyIncome: garantData.monthlyIncome?.toString(),
    amount: garantData.amount?.toString(),
    trialPeriod: booleanToString(garantData.trialPeriod) || undefined,
  });

  const toGarant = (garantData: GarantFrom): Garant => ({
    ...garantData,
    amount: stringToNumber(garantData.amount) || undefined,
    monthlyIncome: stringToNumber(garantData.monthlyIncome) || undefined,
    trialPeriod: stringToBoolean(garantData.trialPeriod),
  });

  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors, dirtyFields },
  } = useForm<GarantFrom>({
    defaultValues: garant ? { ...toGarantForm(garant) } : undefined,
  });
  useEffect(() => {
    if (garant) {
      reset(toGarantForm(garant));
    }
  }, [id]);
  const formField = watch();

  const onSubmit: SubmitHandler<GarantFrom> = async (formData: GarantFrom) => {
    setSubmitting(true);
    const garantToUpdate = toGarant(formData);

    if (id && contactList) {
      const updateData = {};
      Object.keys(garantToUpdate).forEach((key) => {
        if (dirtyFields[key]) {
          updateData[key] = garantToUpdate[key];
        }
      });
      updateDocumentGroupContact.mutateAsync({
        documentGroupId: id,
        data: {
          updateData,
          isGarant: true,
          data: garantToUpdate,
          contacts: contactList,
        },
      })
        .then(() => {
          NotificationService.notifySuccess(Messages.t('notifications.update'));
          if (onSubmitted) {
            onSubmitted();
          }
        })
        .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      setSubmitting(true);
      createGarant.mutateAsync({
        data: {
          garant: garantToUpdate,
          candidatureId,
        },
      }).then(() => {
        NotificationService.notifySuccess(Messages.t('notifications.update'));
        if (onSubmitted) {
          onSubmitted();
        }
      })
        .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
        .finally(() => setSubmitting(false));
    }
  };

  const { type } = formField;
  const situationArr = formField.situation?.split(',');
  return (
    <div className="page-body">
      <form onSubmit={handleSubmit(onSubmit)} className="application-informations">
        <h5>{Messages.t('field.garantDatas')}</h5>
        <Controller
          name="type"
          control={control}
          render={(controller) => (
            <SelectWrapper
              apiErrors={apiErrors}
              disabled={!!garant}
              error={errors}
              control={controller}
              label={Messages.t('field.type')}
              values={Object.values(garantTypeEnum).map((key) => ({
                key, label: Messages.t(`garantType.${key}`),
              }))}
            />
          )}
        />
        {
          type && ((type === garantTypeEnum.PERSON) ? (
            <>
              <h5>{Messages.t('field.personalDatas')}</h5>
              <div className="form-row">
                <Controller
                  name="firstname"
                  control={control}
                  rules={{ required: { value: !formField.lastname, message: Messages.t('form.error.name') } }}
                  render={(controller) => (
                    <TextFieldWrapper
                      apiErrors={apiErrors}
                      error={errors}
                      type="text"
                      control={controller}
                      label={Messages.t('field.firstName')}
                    />
                  )}
                />
                <Controller
                  name="lastname"
                  control={control}
                  rules={{ required: { value: !formField.firstname, message: Messages.t('form.error.name') } }}
                  render={(controller) => (
                    <TextFieldWrapper
                      apiErrors={apiErrors}
                      error={errors}
                      type="text"
                      control={controller}
                      label={Messages.t('field.lastName')}
                    />
                  )}
                />
              </div>
              <div className="form-row">
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    pattern: {
                      value: mailRegex,
                      message: Messages.t('form.error.email'),
                    },
                  }}
                  render={(controller) => (
                    <TextFieldWrapper
                      apiErrors={apiErrors}
                      error={errors}
                      disabled
                      type="text"
                      control={controller}
                      label={Messages.t('field.email')}
                    />
                  )}
                />
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={(controller) => (
                    <PhoneNumberInputWrapper
                      apiErrors={apiErrors}
                      error={errors}
                      control={controller}
                      label={Messages.t('field.phoneNumber')}
                      flag
                    />
                  )}
                />
              </div>
              <h5>{Messages.t('field.professionalDatas')}</h5>
              <div className="form-row">
                <Controller
                  name="situation"
                  control={control}
                  rules={{ required: true }}
                  render={(controller) => (
                    <SelectWrapper
                      multiple
                      apiErrors={apiErrors}
                      error={errors}
                      control={controller}
                      label={Messages.t('field.professionalSituation')}
                      values={Object.values(garantProSituationTypeEnum).map((key) => ({
                        key, label: Messages.t(`professionalSituation.${key}`),
                      }))}
                    />
                  )}
                />
              </div>
              <div className="form-row">
                {
                  (situationArr && (situationArr.includes(proSituationTypeEnum.EMPLOYED)
                    || situationArr.includes(proSituationTypeEnum.OFFICIAL)
                    || situationArr.includes(proSituationTypeEnum.FREELANCER)))
                  && (
                    <>
                      <Controller
                        name="profession"
                        control={control}
                        render={(controller) => (
                          <TextFieldWrapper
                            apiErrors={apiErrors}
                            error={errors}
                            type="text"
                            control={controller}
                            label={Messages.t('field.profession')}
                          />
                        )}
                      />
                      <Controller
                        name="enterprise"
                        control={control}
                        render={(controller) => (
                          <TextFieldWrapper
                            apiErrors={apiErrors}
                            error={errors}
                            type="text"
                            control={controller}
                            label={Messages.t('field.enterprise')}
                          />
                        )}
                      />
                    </>
                  )
                }
              </div>
              {situationArr && situationArr.includes(proSituationTypeEnum.EMPLOYED)
                && (
                  <div className="form-row">
                    <Controller
                      name="leaseType"
                      control={control}
                      render={(controller) => (
                        <SelectWrapper
                          apiErrors={apiErrors}
                          error={errors}
                          control={controller}
                          label={Messages.t('field.leaseType')}
                          values={Object.values(contractTypeTypeEnum).map((key) => ({
                            key, label: Messages.t(`contractType.${key}`),
                          }))}
                        />
                      )}
                    />
                    <Controller
                      name="trialPeriod"
                      control={control}
                      render={(controller) => (
                        <SelectWrapper
                          apiErrors={apiErrors}
                          error={errors}
                          control={controller}
                          label={Messages.t('field.trialPeriod')}
                          values={[
                            { key: 'all', label: Messages.t('generics.all') },
                            { key: 'true', label: Messages.t('generics.YES') },
                            { key: 'false', label: Messages.t('generics.NO') },
                          ]}
                        />
                      )}
                    />
                  </div>
                )}
              <div className="form-row">
                <Controller
                  name="monthlyIncome"
                  control={control}
                  render={(controller) => (
                    <TextFieldWrapper
                      apiErrors={apiErrors}
                      error={errors}
                      type="text"
                      control={controller}
                      label={Messages.t('field.monthlyIncome')}
                    />
                  )}
                />
              </div>
            </>
          ) : (
            <div className="form-row">
              <Controller
                name="name"
                control={control}
                rules={{
                  required: true,
                }}
                render={(controller) => (
                  <TextFieldWrapper
                    apiErrors={apiErrors}
                    error={errors}
                    type="text"
                    control={controller}
                    label={Messages.t('field.name')}
                  />
                )}
              />
              {
                type === garantTypeEnum.ENTERPRISE ? (
                  <div />
                ) : (
                  <Controller
                    name="amount"
                    control={control}
                    render={(controller) => (
                      <TextFieldWrapper
                        apiErrors={apiErrors}
                        error={errors}
                        type="text"
                        control={controller}
                        label={Messages.t('field.amount')}
                      />
                    )}
                  />
                )
              }
            </div>
          ))
        }
        <SpinButton
          editing
          className="submit-button"
          spin={submitting}
          title={Messages.t('formButton.confirm')}
        />
      </form>
    </div>
  );
}
