import { FieldPath } from 'react-hook-form/dist/types';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';
import { Enum } from 'lib/type/enum';
import * as React from 'react';
import { FoyerComposition } from 'types/Pinel';

export interface CandidateFormType {
  start?: string,
  propertyId?: string,
  propertyUrl?: string,
  message?: string,
  moveInWanted: MoveInType,
  contacts: ContactForm[],
  contactAdd: string,
  save: string,
  occupantRelationship: RentingSituationType
  hasGarants: string,
  garants: GarantForm[],
  garantAdd: string,
  fiscalIncomesN2?: string,
  foyerCompositionN2?: FoyerComposition,
  foyerCompositionN2MoreThan3?: string,
}

export interface ContactForm {
  firstname: string
  lastname: string
  email: string,
  phoneNumber: string
  situation?: SituationType
  leaseType: ContractType
  trialPeriod: string,
  professionalMoveOut: string,
  moreThan10Employees: string,
  longDistance: string,
  profession: string,
  enterprise: string,
  monthlyIncome: string,
}

export interface GarantForm {
  type: GarantType
  firstname: string
  lastname: string
  email: string,
  phoneNumber: string
  situation?: SituationType
  leaseType: ContractType
  trialPeriod: string,
  profession: string,
  enterprise: string,
  monthlyIncome: string
  name: string
  amount: string
}

export type FormField = {
  pageId: FieldPath<CandidateFormType>,
  questionlabel: string,
  fieldLabel: string,
  fieldType?: React.InputHTMLAttributes<unknown>['type'];
  rules?: RegisterOptions
  selectOption?: { key: string, label: string }[],
  multiSelect?: boolean
  skipButton?: string,
  actionButton?: string,
  onSelectChange?: (value: string) => void
  type: 'textField' | 'properties' | 'select' | 'action' | 'generator' | 'phoneNumber' | 'start' | 'end',
  action?: (index: number) => void,
  condition?: (formfield: CandidateFormType) => boolean | undefined
};

export type MoveInType = 'ASAP' | 'NEXT_MONTH' | 'BEFORE_3_MONTH' | 'LATER';

export const moveInEnum: Enum<MoveInType> = {
  ASAP: 'ASAP',
  NEXT_MONTH: 'NEXT_MONTH',
  BEFORE_3_MONTH: 'BEFORE_3_MONTH',
  LATER: 'LATER',
};

export type SituationType = 'EMPLOYED' | 'STUDENT' | 'RETIRED' | 'FREELANCER' | 'OFFICIAL' | 'JOB_SEEKER';

export const proSituationTypeEnum: Enum<SituationType> = {
  STUDENT: 'STUDENT',
  EMPLOYED: 'EMPLOYED',
  RETIRED: 'RETIRED',
  FREELANCER: 'FREELANCER',
  OFFICIAL: 'OFFICIAL',
  JOB_SEEKER: 'JOB_SEEKER',
};

export const garantProSituationTypeEnum: Partial<Enum<SituationType>> = {
  EMPLOYED: 'EMPLOYED',
  RETIRED: 'RETIRED',
  FREELANCER: 'FREELANCER',
  OFFICIAL: 'OFFICIAL',
};

export type ContractType = 'CDD' | 'CDI' | 'Alternant' | 'Interimaire' | 'Intermittent';

export const contractTypeTypeEnum: Enum<ContractType> = {
  CDD: 'CDD',
  CDI: 'CDI',
  Alternant: 'Alternant',
  Interimaire: 'Interimaire',
  Intermittent: 'Intermittent',
};

export type RentingSituationType = 'SOLO' | 'COUPLE' | 'FLATSHARING';

export const rentingSituationTypeEnum: Enum<RentingSituationType> = {
  SOLO: 'SOLO',
  COUPLE: 'COUPLE',
  FLATSHARING: 'FLATSHARING',
};

export type GarantType = 'INSURANCE' | 'BANK' | 'ENTERPRISE' | 'PERSON';

export const garantTypeEnum: Enum<GarantType> = {
  PERSON: 'PERSON',
  INSURANCE: 'INSURANCE',
  BANK: 'BANK',
  ENTERPRISE: 'ENTERPRISE',
};
