import { Property, PropertyImage } from 'types/Property';
import { fuzzysearch } from 'lib/FuzzySearch';

export default class PropertyUtils {
  public static filterProperty(properties: Property[], filter: string): Property[] {
    return properties.filter((property) => this.propertyMatch(property, filter));
  }

  public static propertyMatch(property: Property, filter: string): boolean {
    return (property.address && fuzzysearch(filter, property.address))
      || (property.price && filter === property.price.toString())
      || (property.identifier && fuzzysearch(filter, property.identifier))
      || (property.city && fuzzysearch(filter, property.city))
      || (property.zipCode && (property.zipCode.includes(filter)))
      || false;
  }

  public static getSortedPropertyImage = (property?: Property): PropertyImage[] | undefined => {
    if (!property?.images) {
      return undefined;
    }
    return property
      .images?.sort((a, b) => ((((a.order !== null && a.order !== undefined) ? a.order : -1))
          - ((b.order !== null && b.order !== undefined) ? b.order : -1))
        || (b.createdAt || '').localeCompare(a.createdAt || ''));
  };

  public static getPropertyMainImage = (property?: Property): string | undefined => {
    if (!property?.images) {
      return undefined;
    }
    return PropertyUtils.getSortedPropertyImage(property)?.[0].imageURL;
  };
}
