import React, { ReactNode } from 'react';
import back from 'assets/images/backArrow.svg';
import Messages from 'services/i18n/Messages';
import { useHistory } from 'react-router-dom';

type Props = {
  label?: string,
  to?: string,
  backIcon?: ReactNode
};

export default function PageBackButton({ label, to, backIcon }: Props) {
  const history = useHistory();
  return (
    <button
      type="button"
      onClick={() => {
        if (to) {
          history.push(to);
        } else {
          history.goBack();
        }
      }}
      className="back-button"
    >
      {
        backIcon || (<img src={back} alt="back" />)
      }
      <h2>{label || Messages.t('formButton.back')}</h2>
    </button>
  );
}
