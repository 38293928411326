import { CandidatureShort } from 'types/Candidature';
import DocumentUtils from 'services/DocumentUtils';
import { Contact } from 'types/Contact';
import { Garant } from 'types/Garant';
import GarantUtils from 'services/GarantUtils';

export default class CandidatureUtils {
  public static computeCompletion(candidature: CandidatureShort) {
    const contactCompletion = candidature.contacts
      .reduce((acc, value) => acc
        + DocumentUtils.getCompletionPercentFromTagGroup(value.documentGroup), 0);
    const garantCompletion = candidature
      .garants?.reduce((acc, value) => acc
        + DocumentUtils.getCompletionPercentFromTagGroup(value.documentGroup), 0) || 0;
    return Math.floor((contactCompletion + garantCompletion)
      / (candidature.contacts.length + (candidature.garants?.length || 0)));
  }

  public static otherDocumentAreFull(candidature: CandidatureShort, documentGroupId: string) {
    for (let i = 0; i < candidature.contacts.length; i++) {
      if (candidature.contacts[i].documentGroupID === documentGroupId) {
        continue;
      }
      if (DocumentUtils.getCompletionPercentFromTagGroup(
        candidature.contacts[i].documentGroup,
      ) < 100) {
        return false;
      }
    }
    if (candidature.garants) {
      for (let i = 0; i < candidature.garants.length; i++) {
        if (candidature.garants[i].documentGroupID === documentGroupId) {
          continue;
        }
        if (DocumentUtils.getCompletionPercentFromTagGroup(
          candidature.garants[i].documentGroup,
        ) < 100) {
          return false;
        }
      }
    }
    return true;
  }

  public static isShared(candidature: CandidatureShort) {
    let oneIsShared = false;
    let oneIsNotShared = false;

    for (let i = 0; i < candidature.contacts.length; i++) {
      if (!candidature.contacts[i].isShared) {
        if (oneIsShared) {
          return false;
        }
        oneIsNotShared = true;
      } else {
        oneIsShared = true;
      }
    }
    if (candidature.garants) {
      for (let i = 0; i < candidature.garants.length; i++) {
        if (!candidature.garants[i].isShared) {
          if (oneIsShared) {
            return false;
          }
          oneIsNotShared = true;
        } else {
          oneIsShared = true;
        }
      }
    }
    return !oneIsShared || !oneIsNotShared;
  }

  public static getMatchingContactDocumentGroup(
    contactCreate: Contact,
    candidatures?: CandidatureShort[],
  ): Contact | undefined {
    if (!candidatures || contactCreate.documentGroupID) {
      return undefined;
    }
    for (let i = 0; i < candidatures.length; i++) {
      for (let j = 0; j < candidatures[i].contacts.length; j++) {
        const contact = candidatures[i].contacts[j];
        if (contact.documentGroupID
          && ((contactCreate.email && contact.email === contactCreate.email)
            || (contactCreate.phoneNumber && contact.phoneNumber === contactCreate.phoneNumber)
            || (contactCreate.firstname && contact.firstname === contactCreate.firstname
              && contactCreate.lastname && contact.lastname === contactCreate.lastname)
          )) {
          return contact;
        }
      }
    }
    return undefined;
  }

  public static getMatchingGarantDocumentGroup(
    garantCreate: Garant,
    candidatures?: CandidatureShort[],
  ): Garant | undefined {
    if (!candidatures || garantCreate.documentGroupID) {
      return undefined;
    }
    for (let i = 0; i < candidatures.length; i++) {
      const { garants } = candidatures[i];
      if (garants) {
        for (let j = 0; j < garants.length; j++) {
          const garant = garants[j];
          if (garant.documentGroupID
            && garant.type === garantCreate.type
            && GarantUtils.getDisplayedName(garant)
            === GarantUtils.getDisplayedName(garantCreate)) {
            return garant;
          }
        }
      }
    }
    return undefined;
  }
}
