import React, { useRef, useState, useEffect } from 'react';
import Messages from 'services/i18n/Messages';
import ContactItem from 'pages/client-app/candidature/candidatureDetails/ContactItem';
import ContactUtils from 'services/ContactUtils';
import GarantUtils from 'services/GarantUtils';
import CandidatureUtils from 'services/CandidatureUtils';
import { DocumentGroupCreation } from 'types/DocumentGroupCreation';
import { NotificationService } from 'lib/notification';
import { Routes } from 'routes/RoutesUtils';
import { APPLICATION_DOCUMENT, ID } from 'routes/Routes';
import { AssignToDocumentGroup } from 'types/forms/AssignToDocumentGroup';
import { useHistory } from 'react-router-dom';
import { useCandidatureBackend } from 'network/queries/CandidatureQueries';
import { useDocumentBackend } from 'network/queries/DocumentQueries';
import { CandidatureShort, CandidatureWithPotentialId } from 'types/Candidature';
import { AddCircleOutline, ChevronRight } from '@material-ui/icons';
import { DocumentGroup } from 'types/DocumentGroup';
import {
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import InformationsForm from 'pages/common/InformationsForm';
import sessionManager from 'services/sessionManager';
import GarantInformationsForm from 'pages/common/GarantInformationsForm';
import DialogWrapper from 'pages/common/DialogWrapper';
import DialogAddContactOrGarant from 'pages/client-app/candidature/candidatureDetails/DialogAddContactOrGarant';

type Props = {
  candidatureWithPontentialId?: CandidatureWithPotentialId,
  candidatureData?: CandidatureShort,
  id: string,
  submitting: boolean,
  setSubmitting: (val: boolean) => void
  potentialDocumentGroups?: DocumentGroup[]
};

// TODO clean this component
export default function CaseDetails(
  {
    candidatureWithPontentialId,
    candidatureData,
    id,
    submitting,
    setSubmitting,
    potentialDocumentGroups,
  }: Props,
) {
  const history = useHistory();
  const candidaturesQuery = useCandidatureBackend();
  const [showAddContactModal, setShowAddContactModal] = useState(false);
  const [showAddGarantModal, setShowAddGarantModal] = useState(false);
  const [createContactModal, setCreateContactModal] = useState(false);
  const [createGarantModal, setCreateGarantModal] = useState(false);
  const [isOpenCandidates, setIsOpenCandidates] = useState(true);
  const [isOpenGarants, setIsOpenGarants] = useState(true);
  const documentsQueries = useDocumentBackend();
  const { createDocumentGroup } = documentsQueries;
  const {
    assignDocumentGroup,
    createContact: createContactWs,
    createGarant: createGarantWs,
  } = candidaturesQuery;
  const refCandidate = useRef<HTMLDivElement>(null);
  const refGarant = useRef<HTMLDivElement>(null);
  const [containerCandidateHeight, setContainerCandidateHeight] = useState(
    (isOpenCandidates) ? refCandidate.current?.offsetHeight : 0,
  );
  const [containerGarantHeight, setContainerGarantHeight] = useState(
    (isOpenGarants) ? refGarant.current?.offsetHeight : 0,
  );

  const addDocumentGroup = (group: DocumentGroupCreation) => {
    setSubmitting(true);
    createDocumentGroup.mutateAsync(group)
      .then((res) => {
        NotificationService.notifySuccess(Messages.t('notifications.update'));
        history.push({
          pathname: Routes.withPath(APPLICATION_DOCUMENT, [{ label: ID, value: res.id }]),
          state: { fromCandidatureId: candidatureData?.id },
        });
      })
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => setSubmitting(false));
  };

  const assignToDocumentGroup = (assignList: AssignToDocumentGroup[]) => {
    setSubmitting(true);
    assignDocumentGroup.mutateAsync({ data: assignList, candidatureId: candidatureData?.id || '' })
      .then(() => {
        NotificationService.notifySuccess(Messages.t('notifications.update'));
      })
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => setSubmitting(false));
  };

  const candidatureIsShared = candidatureData && CandidatureUtils.isShared(candidatureData);

  useEffect(() => {
    if (isOpenCandidates) {
      setContainerCandidateHeight(refCandidate.current?.offsetHeight);
    } else {
      setContainerCandidateHeight(0);
    }
  }, [isOpenCandidates]);

  useEffect(() => {
    if (isOpenGarants) {
      setContainerGarantHeight(refGarant.current?.offsetHeight);
    } else {
      setContainerGarantHeight(0);
    }
  }, [isOpenGarants]);

  useEffect(() => {
    setContainerCandidateHeight(refCandidate.current?.offsetHeight);
    setContainerGarantHeight(refGarant.current?.offsetHeight);
  }, [id, candidatureData?.garants?.length, candidatureData?.contacts.length]);

  const createContact = (documentGroupId: string) => {
    setSubmitting(true);
    createContactWs.mutateAsync({
      data: {
        documentGroupId,
        candidatureId: candidatureData?.id || '',
      },
    }).then(() => {
      NotificationService.notifySuccess(Messages.t('notifications.update'));
    })
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => setSubmitting(false));
  };

  const createGarant = (documentGroupId: string) => {
    setSubmitting(true);
    createGarantWs.mutateAsync({
      data: {
        documentGroupId,
        candidatureId: candidatureData?.id,
      },
    }).then(() => {
      NotificationService.notifySuccess(Messages.t('notifications.update'));
    })
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => setSubmitting(false));
  };
  const hideModalAddContactOrGarant = () => {
    setShowAddContactModal(false);
    setShowAddGarantModal(false);
  };

  const showCreateContactModal = () => setCreateContactModal(true);
  const showCreateGarantModal = () => setCreateGarantModal(true);
  return (
    <>
      {
        (showAddContactModal || showAddGarantModal) && potentialDocumentGroups && (
          <DialogAddContactOrGarant
            potentialDocumentGroups={potentialDocumentGroups}
            onClose={hideModalAddContactOrGarant}
            submitting={submitting}
            showAddContactModal={showAddContactModal}
            showAddGarantModal={showAddGarantModal}
            createContact={createContact}
            createGarant={createGarant}
            showCreateContactModal={showCreateContactModal}
            showCreateGarantModal={showCreateGarantModal}
          />
        )
      }
      {
        createContactModal && (
          <DialogWrapper
            open
            onClose={() => setCreateContactModal(false)}
          >
            <DialogTitle>{Messages.t('contacts.create')}</DialogTitle>
            <DialogContent>
              <InformationsForm
                onSubmitted={() => setCreateContactModal(false)}
                candidatureId={candidatureData?.id || ''}
              />
            </DialogContent>
          </DialogWrapper>
        )
      }
      {
        createGarantModal && (
          <DialogWrapper
            open
            onClose={() => setCreateGarantModal(false)}
          >
            <DialogTitle>{Messages.t('contacts.create')}</DialogTitle>
            <DialogContent>
              <GarantInformationsForm
                onSubmitted={() => setCreateGarantModal(false)}
                candidatureId={candidatureData?.id || ''}
              />
            </DialogContent>
          </DialogWrapper>
        )
      }
      {
        candidatureWithPontentialId && (
          <>
            <div className="extendable-container">
              <div className="selector-row">
                <button
                  type="button"
                  className="content"
                  onClick={() => setIsOpenCandidates((prevState) => !prevState)}
                >
                  <div className="section-title"><h3>{Messages.t('occupants.title')}</h3></div>
                  <ChevronRight className={`extend-icon ${isOpenCandidates ? 'extended' : ''}`} />
                </button>
              </div>
              <div
                style={
                  (containerCandidateHeight !== 0 || containerCandidateHeight !== undefined) ? (
                    { height: containerCandidateHeight }
                  ) : { height: 0 }
                }
                className="list-container"
              >
                <div ref={refCandidate}>
                  {
                    candidatureWithPontentialId.contacts
                      .sort((a, b) => (a.order || 0) - (b.order || 0))
                      .map((contact) => (
                        <div className="selector-row selector-sub-row" key={contact.id}>
                          <ContactItem
                            candidatureId={id}
                            contactId={contact.id}
                            disableDelete={
                              contact.userAccountID === sessionManager.getSession()?.user_id
                            }
                            manuallyAssigned={contact.manuallyAssigned}
                            potentialDocumentGroups={potentialDocumentGroups}
                            key={contact.id}
                            documentGroup={contact.documentGroup
                              || contact.potentialDocumentGroup?.documentGroup}
                            name={ContactUtils.getDisplayedName(contact, 0)}
                            disabled={submitting}
                            candidatureWithPontentialId={candidatureWithPontentialId}
                            isShared={contact.isShared || candidatureIsShared}
                            onAssign={(name, documentGroupId, manuallyAssigned) => {
                              if (documentGroupId) {
                                assignToDocumentGroup([{
                                  documentGroupId,
                                  contactId: contact.id,
                                  manuallyAssigned,
                                }]);
                              } else {
                                addDocumentGroup({
                                  name,
                                  contactId: contact.id,
                                });
                              }
                            }}
                          />
                        </div>
                      ))
                  }
                  <div className="add-contact-button-container">
                    <button
                      onClick={() => {
                        setShowAddContactModal(true);
                      }}
                      className="add-contact-button"
                      type="button"
                    >
                      <AddCircleOutline /> {Messages.t('occupants.addOccupant')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      }

      <div className="extendable-container">
        <div className="selector-row">
          <button
            type="button"
            className="content"
            onClick={() => setIsOpenGarants((prevState) => !prevState)}
          >
            <div className="section-title"><h3>{Messages.t('garants.title')}</h3></div>
            <ChevronRight className={`extend-icon ${isOpenGarants ? 'extended' : ''}`} />
          </button>
        </div>
        <div
          style={
            (containerGarantHeight !== 0 || containerGarantHeight !== undefined) ? (
              { height: containerGarantHeight }
            ) : { height: 0 }
          }
          className="list-container"
        >
          <div ref={refGarant}>
            {
              candidatureWithPontentialId?.garants
              && candidatureWithPontentialId.garants
                .sort((a, b) => (a.order || 0) - (b.order || 0))
                .map((garant) => (
                  <div className="selector-row selector-sub-row content" key={garant.id}>
                    <ContactItem
                      candidatureId={id}
                      manuallyAssigned={garant.manuallyAssigned}
                      garantId={garant.id}
                      potentialDocumentGroups={potentialDocumentGroups}
                      key={garant.id}
                      candidatureWithPontentialId={candidatureWithPontentialId}
                      documentGroup={garant.documentGroup
                        || garant.potentialDocumentGroup?.documentGroup}
                      name={GarantUtils.getDisplayedName(garant)}
                      isShared={garant.isShared || candidatureIsShared}
                      disabled={submitting}
                      onAssign={(name, documentGroupId, manuallyAssigned) => {
                        if (documentGroupId) {
                          assignToDocumentGroup([{
                            documentGroupId,
                            garantId: garant.id,
                            manuallyAssigned,
                          }]);
                        } else {
                          addDocumentGroup({
                            name,
                            garantId: garant.id,
                          });
                        }
                      }}
                    />
                  </div>
                ))
            }
            <div className="add-contact-button-container">
              <button
                onClick={() => {
                  setShowAddGarantModal(true);
                }}
                className="add-contact-button"
                type="button"
              >
                <AddCircleOutline /> {Messages.t('occupants.addGarant')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
