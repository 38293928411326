import React from 'react';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { getFinalErrorMessage } from 'lib/form/FormUtils';
import { UseFormStateReturn } from 'react-hook-form/dist/types';
import Messages from 'services/i18n/Messages';

type Props = {
  control: {
    field: ControllerRenderProps<any, any>,
    fieldState: ControllerFieldState,
    formState: UseFormStateReturn<any>,
  }, // TODO Better type
  values: { key: string, label: string }[],
  error: FieldErrors,
  apiErrors?: { [key: string]: string[] }
  onChange?: (value: string) => void
  multiple?: boolean,
};

export default function ListWrapper(
  {
    control,
    values,
    error,
    apiErrors,
    onChange,
    multiple,
  }: Props,
) {
  const { field } = control;
  const errorMessage = getFinalErrorMessage(field.name, error, apiErrors);
  return (
    <div className="list-wrapper">
      {
        multiple && (
          <p className="multiple-options">{Messages.t('form.chooseMultiple')}</p>
        )
      }
      <div className="list-choices">
        {
          values.map((value) => (
            <button
              type="button"
              className={`list-choice ${field.value && field.value.includes(value.key) ? 'selected' : ''}`}
              onClick={() => {
                if (multiple && field.value) {
                  if (field.value.includes(value.key)) {
                    field.onChange(field.value.split(',').filter((v) => v !== value.key).join(','));
                  } else {
                    field.onChange(`${field.value},${value.key}`);
                  }
                } else {
                  field.onChange(value.key);
                }
                if (onChange) {
                  onChange(value.key);
                }
              }}
            >
              {
                value.label
              }
            </button>
          ))
        }
      </div>
      {
        !!errorMessage && <small className="error-message">{errorMessage}</small>
      }
    </div>
  );
}
