import React, { useRef, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Routes } from 'routes/RoutesUtils';
import { CANDIDATURE_DETAILS, ID } from 'routes/Routes';
import CandidatureItem from 'pages/client-app/candidature/candidatureList/CandidatureItem';
import { useCandidatureBackend } from 'network/queries/CandidatureQueries';
import Messages from 'services/i18n/Messages';
import { detectMobile } from 'services/utils';
import { CircularProgress } from '@material-ui/core';
import { VisitDetailed } from 'types/Visit';
import { usePropertiesBackend } from 'network/queries/PropertyQueries';
import archive from 'assets/images/archive.svg';

type Params = {
  id: string,
};

type Props = {
  selectedCandidateId?: string,
  visits: VisitDetailed[],
};

export default function CandidatureList({ selectedCandidateId, visits }: Props) {
  const candidaturesQuery = useCandidatureBackend();
  const { getCandidatures } = candidaturesQuery;
  const history = useHistory();
  const { data: candidaturesData, isLoading } = getCandidatures();

  const propertyQuery = usePropertiesBackend();
  const { getPropertyList } = propertyQuery;

  const swtIds: string[] | undefined = candidaturesData?.filter((candidate) => !candidate.property
    && candidate.swtID).map((candidate) => candidate.swtID) as string[] | undefined;

  const { data: swtProperties } = getPropertyList(swtIds || [], !!swtIds && swtIds.length > 0);

  if (swtProperties && candidaturesData) {
    candidaturesData?.forEach((candidature) => {
      if (!candidature.property && candidature.swtID) {
        const property = swtProperties
          .filter((swtProperty) => swtProperty.id === candidature.swtID)[0];
        if (property) {
          candidature.agency = {
            ...candidature.agency,
            name: property.isAgency
              ? (property.agencyName || Messages.t('agency.unknown'))
              : Messages.t('property.particular'),
          };
          candidature.property = {
            ...property,
            price: property.price || null,
            area: property.area || null,
            rooms: property.rooms || null,
            floor: property.floor || null,
            bedrooms: property.bedrooms || null,
            furnished: property.furnished || null,
            agencyId: '',
            online: true,
            isArchived: false,
            bathrooms: 0,
            agentID: '',
            identifier: '',
            images: property.imageURL?.slice(1, -1).split(',').map((url) => ({ imageURL: url })),
          };
        }
      }
    });
  }

  const candidatureData = candidaturesData?.filter(
    (candidature) => candidature.id === selectedCandidateId,
  );
  const { id } = useParams<Params>();
  const ref = useRef<HTMLAnchorElement | null>(null);

  const setRef = useCallback((node) => {
    if (node) {
      node.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    ref.current = node;
  }, []);

  if (isLoading) {
    return (
      <CircularProgress />
    );
  }

  if (!candidaturesData || candidaturesData.length === 0) {
    return (
      <div className="no-result-page">
        <div className="no-result-content">
          <img alt="logo" src={archive} />
          <div>
            {Messages.t('candidate.empty')}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="properties">
      {
        detectMobile() && candidatureData && candidatureData.length > 0 ? (
          <>
            <div
              className="agency-card"
            >
              <CandidatureItem
                candidature={candidatureData[0]}
                visits={visits.filter((visit) => visit.candidatureID === candidatureData[0].id)}
              />
            </div>
          </>
        ) : (
          candidaturesData.sort((a, b) => (b.createdAt || '').localeCompare(a.createdAt || '')).map((candidature) => (
            <button
              ref={candidature.id === id ? setRef : undefined}
              className="agency-card"
              type="button"
              key={candidature.id}
              onClick={() => history.push(Routes.withPath(CANDIDATURE_DETAILS,
                [{ label: ID, value: candidature.id }]))}
            >
              <CandidatureItem
                candidature={candidature}
                visits={visits.filter((visit) => visit.candidatureID === candidature.id)}
              />
            </button>
          ))
        )
      }
    </div>
  );
}
