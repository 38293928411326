import React, {
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { SearchFormType, SearchTypeDetails } from 'types/SearchType';
import Messages from 'services/i18n/Messages';
import PriceRangeInputWrapper from 'pages/common/search/PriceRangeInputWrapper';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { usePropertiesBackend } from 'network/queries/PropertyQueries';
import { booleanToString, stringToBoolean } from 'lib/form/FormUtils';
import AreaRangeInputWrapper from 'pages/common/search/AreaRangeInputWrapper';
import ListWrapper from 'lib/form/ListWrapper';
import { propertyType } from 'types/Property';
import ZipCodeAutoCompleteWrapper from 'lib/form/ZipCodeAutoCompleteWrapper';
import { zipCodeApi } from 'network/api/zipCodeApi';
import { IconButton } from '@material-ui/core';
import { ChevronLeft, ChevronRight, Close } from '@material-ui/icons';
import CircularProgressBar from 'pages/common/CircularProgressBar';
import confirmationService from 'services/ConfirmationService';
import { useHistory } from 'react-router-dom';
import { SEARCHES } from 'routes/Routes';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

type Props = {
  onSubmit: (data: SearchTypeDetails) => void
  submitting: boolean,
  search?: SearchTypeDetails
};

export default function SearchCreationFunnel({ onSubmit, submitting, search }: Props) {
  const histoy = useHistory();
  const [apiErrors] = useState({});
  const [isPriceMaxRange, setIsPriceMaxRange] = useState(!search?.priceMax);
  const [isPriceMinRange, setIsPriceMinRange] = useState(!search?.priceMin);
  const [isAreaMaxRange, setIsAreaMaxRange] = useState(!search?.areaMax);
  const [isAreaMinRange, setIsAreaMinRange] = useState(!search?.areaMin);
  const [searchForData, setSearchForData] = useState<SearchTypeDetails | undefined>();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const propertyQueries = usePropertiesBackend();
  const { getCountByDay } = propertyQueries;
  const {
    data: count,
  } = getCountByDay(
    searchForData,
    searchForData && !!searchForData.postalCodes && searchForData.postalCodes.length > 4,
  );

  // eslint-disable-next-line no-undef
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const clearTimer = useCallback(() => {
    if (timer) {
      clearTimeout(timer);
    }
  }, [timer]);

  useEffect(() => clearTimer,
    []);

  const {
    control,
    handleSubmit,
    watch,
    trigger,
    setValue,
    formState: { errors },
  } = useForm<SearchFormType>({
    defaultValues: {
      ...search,
      propertyType: search?.propertyType,
      furnished: booleanToString(search?.furnished),
      priceMin: search?.priceMin ? search.priceMin.toString() : undefined,
      priceMax: search?.priceMax ? search.priceMax.toString() : undefined,
      areaMin: search?.areaMin ? search.areaMin.toString() : undefined,
      areaMax: search?.areaMax ? search.areaMax.toString() : undefined,
    },
  });

  const searchFormToSearch = (data: SearchFormType): SearchTypeDetails => ({
    ...data,
    furnished: stringToBoolean(data?.furnished),
    priceMin: (!isPriceMinRange && data.priceMin) ? Number(data.priceMin) : undefined,
    priceMax: (!isPriceMaxRange && data.priceMax) ? Number(data.priceMax) : undefined,
    areaMin: (!isAreaMinRange && data.areaMin) ? Number(data.areaMin) : undefined,
    areaMax: (!isAreaMinRange && data.areaMax) ? Number(data.areaMax) : undefined,
  });

  const formField = watch();
  const stringSearch = JSON.stringify(formField);
  useEffect(() => {
    clearTimer();
    setTimer(setTimeout(() => {
      const searchForm: SearchFormType = JSON.parse(stringSearch);
      setSearchForData(searchFormToSearch(searchForm));
    }, 500));
  }, [stringSearch]);

  const submitForm: SubmitHandler<SearchFormType> = (data: SearchFormType) => {
    onSubmit(searchFormToSearch(data));
  };

  const quitSearchCreation = async () => {
    const shouldQuit = await confirmationService.confirm({
      inverseButton: true,
      actionColor: 'secondary',
      message: Messages.t('search.quit.message'),
      actionMessage: Messages.t('search.quit.action'),
      cancelMessage: Messages.t('search.quit.cancel'),
      columnButton: true,
    });
    if (shouldQuit) {
      histoy.push(SEARCHES);
    }
  };

  const questions: ReactNode[] = [
    <div key="postalCodes">
      <h3>{Messages.t('search.question.postalCodes')}</h3>
      <Controller
        name="postalCodes"
        rules={{ required: true }}
        control={control}
        render={(controller) => (
          <ZipCodeAutoCompleteWrapper
            apiErrors={apiErrors}
            error={errors}
            control={controller}
            label={Messages.t('field.postalCodes')}
            getPropositions={zipCodeApi.searchZipCode}
            getSelectedLabels={zipCodeApi.listZipCodes}
            placeholder={Messages.t('field.postalCodes')}
            allZoneMessage={Messages.t('field.allZone')}
          />
        )}
      />
    </div>,
    <div key="propertyType">
      <h3>{Messages.t('search.question.propertyType')}</h3>
      <Controller
        name="propertyType"
        rules={{ required: true }}
        control={control}
        render={(controller) => (
          <ListWrapper
            apiErrors={apiErrors}
            error={errors}
            control={controller}
            values={Object.values(propertyType).map((type) => ({
              key: type,
              label: Messages.t(`property.type.${type}`),
            }))}
          />
        )}
      />
    </div>,
    <div key="rooms">
      <h3>{Messages.t('search.question.rooms')}</h3>
      <Controller
        name="rooms"
        rules={{ required: true }}
        control={control}
        render={(controller) => (
          <ListWrapper
            apiErrors={apiErrors}
            error={errors}
            multiple
            control={controller}
            values={[...Array(5).keys()].map((n) => ({
              key: (n + 1).toString(),
              label: n === 4 ? Messages.t('property.rooms.more') : Messages.t('property.rooms', { smart_count: (n + 1).toString() }),
            }))}
          />
        )}
      />
    </div>,
    <div key="budget">
      <h3>{Messages.t('search.question.budget')}</h3>
      <PriceRangeInputWrapper
        isMaxRange={isPriceMaxRange}
        setIsMaxRange={setIsPriceMaxRange}
        setIsMinRange={setIsPriceMinRange}
        setValue={setValue}
        formField={formField}
        search={searchForData}
        control={control}
        apiErrors={apiErrors}
        errors={errors}
      />
    </div>,
    <div key="area">
      <h3>{Messages.t('search.question.area')}</h3>
      <AreaRangeInputWrapper
        isMaxRange={isAreaMaxRange}
        setIsMaxRange={setIsAreaMaxRange}
        setIsMinRange={setIsAreaMinRange}
        setValue={setValue}
        formField={formField}
        search={searchForData}
        control={control}
        apiErrors={apiErrors}
        errors={errors}
      />
    </div>,
    <div key="furnished">
      <h3>{Messages.t('search.question.furnished')}</h3>
      <Controller
        name="furnished"
        rules={{ required: true }}
        control={control}
        render={(controller) => (
          <ListWrapper
            apiErrors={apiErrors}
            error={errors}
            control={controller}
            values={[
              { key: 'true', label: Messages.t('search.property.furnished') },
              { key: 'false', label: Messages.t('search.property.unFurnished') },
              { key: 'all', label: Messages.t('generics.ALL') },

            ]}
          />
        )}
      />
    </div>,
  ];
  const currentQuestion = questions[currentQuestionIndex];
  return (
    <>
      <form className="form-search" onSubmit={handleSubmit(submitForm)}>
        <div className="quit-form-container">
          <button onClick={quitSearchCreation} type="button"><Close /></button>
        </div>
        <div className="count-by-day-container">
          {
            currentQuestionIndex > 2 && (
              <div className="count-by-day">
                {
                  !count?.avgPropertiesDay && (
                    Messages.t('property.count.none')
                  )
                }
                {
                  count && count?.avgPropertiesDay && count?.avgPropertiesDay < 5 && (
                    Messages.t('property.count.less')
                  )
                }
                {
                  count && count?.avgPropertiesDay >= 5 && count?.avgPropertiesDay < 15 && (
                    Messages.t('property.count.average', { count: count?.avgPropertiesDay })
                  )
                }
                {
                  count && count?.avgPropertiesDay >= 15 && (
                    Messages.t('property.count.more', { count: count.avgPropertiesDay - (count.avgPropertiesDay % 5) })
                  )
                }
              </div>
            )
          }
        </div>
        <div className="current-question-container">
          {currentQuestion}
        </div>
        <div className="search-footer">
          <div className="arrow-button-container">
            <IconButton
              disabled={currentQuestionIndex === 0}
              onClick={() => setCurrentQuestionIndex((prevState) => prevState - 1)}
            >
              <ChevronLeft />
            </IconButton>
          </div>
          {
            currentQuestionIndex === questions.length - 1 ? (
              <div className="arrow-button-container">
                <button
                  className="submit-search-button"
                  type="submit"
                  color="secondary"
                  disabled={submitting}
                >
                  {Messages.t('search.question.showResult')}<ChevronRight />
                </button>
              </div>
            ) : (
              <>
                <CircularProgressBar
                  size={50}
                  label={`${currentQuestionIndex + 1}/${questions.length}`}
                  percent={(currentQuestionIndex * 100) / (questions.length - 1)}
                />
                <div className="arrow-button-container">
                  <IconButton
                    onClick={async () => {
                      const valid = await trigger();
                      if (valid) {
                        setCurrentQuestionIndex((prevState) => prevState + 1);
                      }
                    }}
                  >
                    <ChevronRight />
                  </IconButton>
                </div>
              </>
            )
          }
        </div>
      </form>
    </>
  );
}
