import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { usePropertiesBackend } from 'network/queries/PropertyQueries';
import { useSearchesBackend } from 'network/queries/SearchQueries';
import Messages from 'services/i18n/Messages';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import {
  AspectRatio,
  BedOutlined,
  ChevronLeft, ElevatorOutlined, Favorite,
  FavoriteBorderOutlined, LuggageOutlined,
  Share, StairsOutlined, ThumbDownAlt,
  ThumbDownAltOutlined,
} from '@material-ui/icons';
import { NotificationService } from 'lib/notification';
import CarrouselModal from 'lib/CarrouselModal';
import DateUtils from 'services/DateUtils';
import StringUtils from 'services/StringUtils';
import { TemplateIcon } from '@heroicons/react/outline';
import Description from 'pages/common/Description';
import Button from 'theme/Button';
import { detectMobile } from 'services/utils';
import ImageGallery from 'pages/common/search/ImageGallery';
import signInService from 'services/SignInService';
import DialogWrapper from 'pages/common/DialogWrapper';
import CandidatureForm from 'pages/properties/CandidatureForm';
import { useCandidatureBackend } from 'network/queries/CandidatureQueries';
import { Routes } from 'routes/RoutesUtils';
import { CANDIDATURE_DETAILS, ID, SEARCH_DETAILS } from 'routes/Routes';
import PropertyMap from 'pages/properties/PropertyMap';
import PageBackButton from 'pages/common/PageBackButton';
import PropertyCandidatureTags from 'pages/common/PropertyCandidatureTags';
import ImageCarrousel from 'lib/ImageCarousel';
import CandidatureFormUtils from 'services/CandidatureFormUtils';
import CopyLinkToClipboard from 'theme/CopyLinkToClipboard';
import { Controller, useForm } from 'react-hook-form';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import { mailRegex } from 'lib/form/FormUtils';

type Param = {
  id?: string,
  propertyId: string,
};

// TODO Manage the case where the id is not is the connected user search
export default function PropertyDetailsPage() {
  const location = useLocation<{ fromSearch?: boolean }>();
  const [submitting, setSubmitting] = useState(false);
  const { id, propertyId } = useParams<Param>();
  const [showSourceModal, setShowSourceModal] = useState(false);
  const [sendCandidateMail, setSendMail] = useState(false);
  const [showStaticMap, setShowStaticMap] = useState(false);
  const [showNoMailModal, setShowNoMailModal] = useState(false);
  const [modalCandidateId, setModalCandidateId] = useState<string | undefined>(undefined);
  const [scrollableRef, setScrollableRef] = useState<HTMLDivElement | undefined>();

  const candidatureQueries = useCandidatureBackend();
  const { getCandidatures } = candidatureQueries;
  const { data: candidatures, isLoading } = getCandidatures(!!id);

  const [
    carrouselModalOpenIndex,
    setCarrouselModalOpenIndex,
  ] = useState<number | undefined>(undefined);
  const [showCreateCandidature, setShowCreateCandidature] = useState(false);

  const searchesQueries = useSearchesBackend();
  const { markPropertyAsSeen, saveClientSearchProperty } = searchesQueries;

  const propertyQueries = usePropertiesBackend();
  const { getProperty } = propertyQueries;
  const { data: property } = getProperty(propertyId, id);

  const {
    control,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<{ mail: string }>();

  const formField = watch();

  useEffect(() => {
    if (property?.id && id && !property?.data?.seen) {
      markPropertyAsSeen.mutateAsync({ propertyId: property?.id, searchId: id });
    }
  }, [property?.id]);

  const handleRef = useCallback((node) => {
    setScrollableRef(node);
  }, []);

  useEffect(() => {
    const onScroll = () => {
      setShowStaticMap(true);
    };
    if (scrollableRef) {
      scrollableRef.addEventListener('scroll', onScroll);
      return () => {
        if (scrollableRef) {
          scrollableRef.removeEventListener('scroll', onScroll);
        }
      };
    }
    return () => {
    };
  }, [scrollableRef]);

  const toShortList = () => {
    if (id) {
      setSubmitting(true);
      saveClientSearchProperty.mutateAsync({
        data: { isTrash: false, isShortList: !property?.searchData?.isShortlist },
        propertyId: property?.id || '',
        searchId: id,
      }).then(() => NotificationService.notifySuccess(Messages.t('notifications.update')))
        .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
        .finally(() => setSubmitting(false));
    } else {
      signInService.setShowSignInModal(true, property?.id || '');
    }
  };

  const discardProperty = () => {
    if (id) {
      setSubmitting(true);
      saveClientSearchProperty.mutateAsync({
        data: { isTrash: !property?.searchData?.isTrash, isShortList: false },
        propertyId: property?.id || '',
        searchId: id,
      }).then(() => NotificationService.notifySuccess(Messages.t('notifications.update')))
        .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
        .finally(() => setSubmitting(false));
    } else {
      signInService.setShowSignInModal(true, property?.id || '');
    }
  };

  const askViewing = (sendMail: boolean) => {
    if (id) {
      if (sendMail && !property?.contactMail) {
        setValue('mail', '');
        setShowNoMailModal(true);
      } else {
        setSendMail(sendMail);
        setShowCreateCandidature(true);
      }
    } else {
      signInService.setShowSignInModal(true, property?.id || '');
    }
  };

  if (!property) {
    return <div>Loading...</div>;
  }
  let images: string[] = [];
  if (property.imageURL && property.imageURL !== '') {
    images = property.imageURL.slice(1, -1).split(',');
  }

  const isMobile = detectMobile();

  const candidature = candidatures
    && candidatures.filter((data) => (
      property.proID && property.proID === data.propertyID
    ) || (
      property.id === data.swtID
    ))[0];

  const address = property.address
    ? property.address
    : `${StringUtils.getPrettyAddress(property.city)}, ${property.postalCode || ''}`;

  return (
    <div ref={handleRef} className="search-property-details page-body">
      <div className="back-button-container">
        <PageBackButton
          to={(id && !location.state?.fromSearch) ? Routes.withPath(SEARCH_DETAILS, [{
            label: ID,
            value: id,
          }]) : undefined}
          backIcon={<ChevronLeft />}
        />
        {
          isMobile && (
            <div className="actions-container">
              <CopyLinkToClipboard link={`${window.location.host}${location.pathname}`}>
                <IconButton className="rounded-icon-button" disabled={submitting}>
                  <Share />
                </IconButton>
              </CopyLinkToClipboard>
              <IconButton className="rounded-icon-button" disabled={submitting} onClick={toShortList}>
                {
                  property.searchData?.isShortlist ? (
                    <Favorite />
                  ) : (
                    <FavoriteBorderOutlined />
                  )
                }

              </IconButton>
              <IconButton className="rounded-icon-button" disabled={submitting} onClick={discardProperty}>
                {
                  property?.searchData?.isTrash ? (
                    <ThumbDownAlt />

                  ) : (
                    <ThumbDownAltOutlined />
                  )
                }
              </IconButton>
            </div>
          )
        }
      </div>
      {
        showNoMailModal && (
          <DialogWrapper
            open
            onClose={() => {
              setShowNoMailModal(false);
              setValue('mail', '');
            }}
          >
            <DialogTitle />
            <DialogContent>
              <div>
                {Messages.t('property.noMailFound')}
              </div>
              <Controller
                name="mail"
                rules={{
                  required: true,
                  pattern: {
                    value: mailRegex,
                    message: Messages.t('form.error.email'),
                  },
                }}
                control={control}
                render={(controller) => (
                  <TextFieldWrapper
                    apiErrors={{}}
                    error={errors}
                    type="text"
                    control={controller}
                    label={Messages.t('field.email')}
                  />
                )}
              />
            </DialogContent>
            <DialogActions>
              <a
                rel="noreferrer"
                target="_blank"
                className="link-as-button open-url-button"
                href={property.sourceURL}
              >
                {Messages.t('property.openUrl')}
              </a>
              <Button
                onClick={async () => {
                  const ok = await trigger();
                  if (ok) {
                    setShowCreateCandidature(true);
                    setShowNoMailModal(false);
                  }
                }}
              >
                {Messages.t('property.askViewing')}
              </Button>
            </DialogActions>
          </DialogWrapper>
        )
      }
      {
        modalCandidateId && (
          <DialogWrapper open onClose={() => setModalCandidateId(undefined)}>
            <DialogTitle />
            <DialogContent>
              {Messages.t('property.candidateCreated')}
            </DialogContent>
            <div className="modal-candidateId-actions">
              <Link
                to={Routes.withPath(CANDIDATURE_DETAILS, [{
                  label: ID,
                  value: modalCandidateId,
                }])}
                className="link-as-button"
              >
                {Messages.t('property.showCandidature')}
              </Link>
              <Button
                color="secondary"
                onClick={() => {
                  CandidatureFormUtils.setCandidatureFormModal(false);
                  setModalCandidateId(undefined);
                }}
              >
                {Messages.t('generics.neverShow')}
              </Button>
            </div>
          </DialogWrapper>
        )
      }
      {
        showCreateCandidature && property && (
          <DialogWrapper open onClose={() => setShowCreateCandidature(false)}>
            <DialogTitle>{Messages.t('candidature.modal.title')}</DialogTitle>
            <DialogContent>
              <CandidatureForm
                contactMail={(sendCandidateMail && property?.contactMail) || (formField.mail !== '' ? formField.mail : undefined)}
                proId={property?.proID}
                swtId={property.id}
                onSubmitted={(candidateId) => {
                  setShowCreateCandidature(false);
                  if (!property?.proID && !CandidatureFormUtils.getCandidatureFormModal()) {
                    setModalCandidateId(candidateId);
                  }
                }}
              />
            </DialogContent>
          </DialogWrapper>
        )
      }
      {
        showSourceModal && (
          <DialogWrapper className="source-url-modal" open onClose={() => setShowSourceModal(false)}>
            <DialogTitle>{Messages.t('candidature.modal.title')}</DialogTitle>
            <iframe title="test" src={property.sourceURL} />
          </DialogWrapper>
        )
      }
      {
        isMobile ? (
          <div className="property-search-card">
            <div className="property-image-container">
              <ImageCarrousel
                imageUrls={images}
                selectIndex={setCarrouselModalOpenIndex}
              />
              <div className="image-overlay">
                <div className="tag-container">
                  <PropertyCandidatureTags candidature={candidature} property={property} />
                </div>
              </div>
              {
                carrouselModalOpenIndex !== undefined && (
                  <CarrouselModal
                    onClose={() => setCarrouselModalOpenIndex(undefined)}
                    isOpen
                    intitialSelectedImage={carrouselModalOpenIndex}
                    imageUrls={images}
                  />
                )
              }
            </div>
          </div>
        ) : (
          <div className="image-gallery-container">
            <ImageGallery
              images={images}
              imageOverlay={(
                <div className="tag-container">
                  <PropertyCandidatureTags candidature={candidature} property={property} />
                </div>
              )}
            />
          </div>
        )
      }
      <div className="property-detail-content">
        <div className="info-row-container">
          <div className="property-search-card-content">
            <div className="property-address-row">
              <div>
                <div className="city">
                  {`${StringUtils.getPrettyAddress(property.city)} (${property.postalCode || ''})`}
                </div>
                {
                  property.address && (
                    <div className="address">
                      {
                        property.address.split(',')[0]
                      }
                    </div>
                  )
                }
              </div>
              <div className="property-price">
                {
                  property.price
                } €
              </div>
            </div>
            {
              property.agencyName && (
                <div className="property-managed-by">
                  {Messages.t('property.managedBy', { agencyName: property.agencyName })}
                </div>
              )
            }
            <div className="property-source-row">
              {
                property.sourceURL && !property.isSwtPro && (
                  <a rel="noreferrer" target="_blank" href={property.sourceURL}>{Messages.t('property.showSource')}</a>
                )
              }
              <div>
                {
                  DateUtils.getPrettyDateDeltaFromNow(property.publishedAt)
                }
              </div>
            </div>
            <div>
              <div className="property-info-container">
                {
                  !!property.rooms && (
                    <div className="property-info">
                      <TemplateIcon />
                      <span> {Messages.t('property.rooms', { smart_count: property.rooms })}</span>
                    </div>
                  )
                }
                {
                  !!property.bedrooms && (
                    <div className="property-info">
                      <BedOutlined />
                      <span> {Messages.t('property.bedroom', { smart_count: property.bedrooms })}</span>
                    </div>
                  )
                }
                {
                  !!property.area && (
                    <div className="property-info">
                      <AspectRatio />
                      <span> {Messages.t('property.area', { smart_count: property.area })}</span>
                    </div>
                  )
                }
                {
                  !!property.floor && (
                    <div className="property-info">
                      <StairsOutlined />
                      <span> {Messages.t('property.floor', { smart_count: property.floor })}</span>
                    </div>
                  )
                }
                {
                  !!property.elevator && (
                    <div className="property-info">
                      <ElevatorOutlined />
                      <span>{Messages.t('property.elevator')}</span>
                    </div>
                  )
                }
                {
                  !!property.furnished && (
                    <div className="property-info">
                      <LuggageOutlined />
                      <span>{Messages.t('property.furnished')}</span>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
          {
            !isMobile && (
              <div className="property-action-container">
                {
                  !isMobile && (
                    candidature ? (
                      <Link
                        to={Routes.withPath(CANDIDATURE_DETAILS, [{
                          label: ID,
                          value: candidature.id,
                        }])}
                        className="link-as-button"
                      >
                        {Messages.t('property.showCandidature')}
                      </Link>
                    ) : (
                      <>
                        <Button onClick={() => askViewing(!property.isSwtPro)}>{Messages.t('property.askViewing')}</Button>
                        {
                          !property.isSwtPro && (
                            <Button
                              color="secondary"
                              onClick={() => askViewing(false)}
                            >
                              {Messages.t('property.alreadyContacted')}
                            </Button>
                          )
                        }
                      </>
                    )
                  )
                }
                <div className="icon-actions">
                  <CopyLinkToClipboard link={`${window.location.host}${location.pathname}`}>
                    <IconButton className="rounded-icon-button" disabled={submitting}>
                      <Share />
                    </IconButton>
                  </CopyLinkToClipboard>
                  <IconButton className="rounded-icon-button" disabled={submitting} onClick={toShortList}>
                    {
                      property.searchData?.isShortlist ? (
                        <Favorite />
                      ) : (
                        <FavoriteBorderOutlined />
                      )
                    }

                  </IconButton>
                  <IconButton className="rounded-icon-button" disabled={submitting} onClick={discardProperty}>
                    {
                      property?.searchData?.isTrash ? (
                        <ThumbDownAlt />

                      ) : (
                        <ThumbDownAltOutlined />
                      )
                    }
                  </IconButton>
                </div>
              </div>
            )
          }
        </div>
        <Description maxCharDisplayed={200} descriptionProps={property.description} />
        {
          property.latitude && property.longitude && address && (
            <>
              <h4>{Messages.t('property.location')}</h4>
              <div className="map-container">
                {
                  showStaticMap ? (
                    <PropertyMap
                      latitude={property.latitude}
                      longitude={property.longitude}
                      address={address}
                      propertyLocationType={property.locationType}
                      id={property.id}
                    />
                  ) : (
                    <div className="map-placeholder" />
                  )
                }
              </div>
            </>
          )
        }
        {
          isMobile && !isLoading && (
            <div className="ask-viewing-button-container">
              <div>
                <div className="property-price">
                  {
                    property.price
                  } €
                </div>
                {
                  property.area && property.price && (
                    <div className="property-price-sqm">
                      {
                        (property.price / property.area).toFixed(0)
                      } €/m2
                    </div>
                  )
                }
              </div>
              {
                candidature ? (
                  <Link
                    to={Routes.withPath(CANDIDATURE_DETAILS, [{
                      label: ID,
                      value: candidature.id,
                    }])}
                    className="link-as-button"
                  >
                    {Messages.t('property.showCandidature')}
                  </Link>
                ) : (
                  <div className="contact-button-container">
                    <button
                      type="button"
                      onClick={() => askViewing(!property.isSwtPro)}
                    >
                      {Messages.t('property.askViewing')}
                    </button>
                    {
                      !property.isSwtPro && (
                        <button
                          type="button"
                          onClick={() => askViewing(false)}
                        >
                          {Messages.t('property.alreadyContacted')}
                        </button>
                      )
                    }
                  </div>
                )
              }
            </div>
          )
        }
      </div>
    </div>
  );
}
