export type ScrollToState = {
  scrollTo: (index: number, skipPage?: boolean, notSmooth?: boolean) => void;
  progressBarScroll: () => number;
};

export type ScrollToProps = {
  container: HTMLElement | null;
  htmlIds: { data: ({ pageId: string })[] };
  padding: number;
};

export default function useScrollerTo(
  {
    container, htmlIds, padding,
  }: ScrollToProps,
): ScrollToState {
  return {
    scrollTo: (index: number, skipPage?: boolean, notSmooth?: boolean) => {
      // temporay fix : https://bugs.webkit.org/show_bug.cgi?id=238497
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      const html: HTMLElement | null = document.querySelector(`#${htmlIds.data[index].pageId.replaceAll('.', '\\.')}`);
      if (html !== null) {
        // the minus 1 his a hack to go to a page that isn't displayed yet
        container?.scrollTo({
          top: html?.offsetTop + (skipPage ? html?.offsetHeight - 1 : 0)
            - (container.offsetTop + padding),
          behavior: notSmooth || isSafari ? 'auto' : 'smooth',
        });
      }
    },
    progressBarScroll: () => {
      if (container !== null) {
        const scroll = container?.scrollTop || 0;
        const offsetParent = container?.offsetTop + padding || 0;
        const htmlElement: HTMLElement | null = document.querySelector(`#${htmlIds.data[0].pageId.replaceAll('.', '\\.')}`);
        if (htmlElement === null) return 0;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < htmlIds.data.length; i++) {
          const html: HTMLElement | null = document.querySelector(`#${htmlIds.data[i].pageId.replaceAll('.', '\\.')}`);
          if (html !== null) {
            const pageTopOffsetInContainer = (html?.offsetTop - scroll - offsetParent) || 0;
            if (pageTopOffsetInContainer <= 0
              && pageTopOffsetInContainer + html?.getBoundingClientRect().height >= 0) {
              return i;
            }
          }
        }
      }
      return htmlIds.data.length;
    },
  };
}
