import React, { useState } from 'react';
import Messages from 'services/i18n/Messages';
import { useDropzone } from 'react-dropzone';
import { PDFDocument } from 'pdf-lib';
import { FileUpload } from 'types/FileUpload';
import DocumentUtils from 'services/DocumentUtils';
import { CloudUpload, Upload } from '@material-ui/icons';
import {
  DocumentCreation,
  DocumentTagPending,
  documentTagPendingEnum,
  documentTypeEnum,
} from 'types/DocumentData';
import { NotificationService } from 'lib/notification';
import { useDocumentBackend } from 'network/queries/DocumentQueries';
import { CircularProgress, IconButton } from '@material-ui/core';

type Props = {
  id: string,
  hideTitle?: boolean,
  doUpload?: (fileNumber) => void,
  asPicto?: boolean,
  isSubmitting?: (submitting: boolean) => void
};

export default function UploadFiles(
  {
    id,
    hideTitle,
    doUpload,
    asPicto,
    isSubmitting,
  }: Props,
) {
  const documentQueries = useDocumentBackend();

  const [submitting, setSubmitting] = useState(false);
  const { createDocuments } = documentQueries;

  const saveDocuments = async (files: FileUpload[], tag: DocumentTagPending) => {
    const filesUpload: DocumentCreation[] = files.map((file) => ({
      file: {
        fileBase64: file.fileBase64,
        filename: file.filename,
      },
      type: documentTypeEnum.DOCUMENT,
      tags: [tag],
    }));
    if (isSubmitting) {
      isSubmitting(true);
    }
    setSubmitting(true);
    if (doUpload) {
      doUpload(filesUpload.length);
    }
    await Promise.all(filesUpload.slice(0, -1).map(async (file) => {
      try {
        await createDocuments.mutateAsync({
          data: [file],
          documentGroupId: id,
          disableCacheUpdate: true,
        });
      } catch (e) {
        NotificationService.notifyError(Messages.t('notifications.error'));
      }
    }));
    return createDocuments.mutateAsync({
      data: filesUpload.slice(-1),
      documentGroupId: id,
    }).then(() => {
      NotificationService.notifySuccess(Messages.t('notifications.update'));
    })
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => {
        setSubmitting(false);
        if (isSubmitting) {
          isSubmitting(false);
        }
      });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*,application/pdf',
    maxFiles: 0,
    onDrop: async (dropedFiles: File[]) => {
      const filesResult = await Promise.all(dropedFiles.map(async (file) => {
        const fileBase64 = await DocumentUtils.getBase64(file);
        if (file.type !== 'application/pdf') {
          return [{
            id: Math.floor(Math.random() * 10000 + 10000).toString(),
            filename: file.name,
            fileBase64,
            fileType: file.type,
          }];
        }
        const pdfDoc = await PDFDocument.load(fileBase64);
        const filesPdf = await Promise.all([...Array(pdfDoc.getPageCount()).keys()]
          .map(async (index) => {
            const newPdfDoc = await PDFDocument.create();
            const newPage = await newPdfDoc.copyPages(pdfDoc, [index]);
            newPdfDoc.addPage(newPage[0]);
            const splitName = file.name.split('.');
            return {
              id: index.toString(),
              filename: `${splitName[0]}-${index}.${splitName[1]}`,
              fileBase64: await newPdfDoc.saveAsBase64({ dataUri: true }),
              fileType: file.type,
            };
          }));
        return filesPdf;
      }));
      saveDocuments(filesResult.flat(), documentTagPendingEnum.PENDING);
    },
  });

  if (asPicto) {
    return (
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <IconButton disabled={submitting}>
          <Upload />
        </IconButton>
      </div>
    );
  }

  return (
    <div className="page-base">
      {
        !hideTitle && (
          <h5>{Messages.t('documents.uploadHere')}</h5>
        )
      }
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <div className="input-placeholder">
          {
            submitting ? (
              <>
                <CircularProgress />
              </>
            ) : (
              <>
                <CloudUpload />
                {Messages.t('dragnDrop.dropHere')}
              </>
            )
          }
        </div>
      </div>
    </div>
  );
}
