/* eslint-disable react/jsx-no-duplicate-props */
import React,
{
  ChangeEvent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { InputAdornment, TextField } from '@material-ui/core';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { getFinalErrorMessage } from 'lib/form/FormUtils';
import { UseFormTrigger } from 'react-hook-form';
import useAutoSave from 'lib/form/autosave';
import { UseFormStateReturn } from 'react-hook-form/dist/types';

type Props = {
  control: {
    field: ControllerRenderProps<any, any>,
    fieldState: ControllerFieldState,
    formState: UseFormStateReturn<any>,
  },
  placeholder?: string,
  label?: ReactNode,
  value?: string,
  type?: React.InputHTMLAttributes<unknown>['type'];
  rows?: number,
  prefix?: ReactNode,
  suffix?: ReactNode,
  disabled?: boolean,
  requierd?: boolean,
  onChange?: () => void,
  error?: FieldErrors,
  apiErrors?: { [key: string]: string[] }
  disableNativeAutocomplete?: boolean,
  delayedAction?: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void,
  autoSaveSubmit?: (value: string) => void,
  trigger?: UseFormTrigger<any>,
  submitting?: boolean,
  fieldRef?: ((
    instance: (HTMLInputElement | null)
  ) => void) | React.RefObject<HTMLInputElement> | null | undefined
};

export default function TextFieldWrapper(
  {
    control,
    placeholder,
    label,
    onChange,
    type,
    prefix,
    suffix,
    rows,
    error,
    apiErrors,
    disabled,
    delayedAction,
    disableNativeAutocomplete,
    autoSaveSubmit,
    requierd,
    trigger,
    value,
    submitting,
    fieldRef,
  }: Props,
) {
  const { field } = control;
  const errorMessage = getFinalErrorMessage(field.name, error, apiErrors);
  const {
    hasSaveResult,
    getSaveResult,
    handleChange,
    onBlur,
  } = useAutoSave<string>(control, autoSaveSubmit, trigger, submitting);
  // eslint-disable-next-line no-undef
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const clearTimer = useCallback(() => {
    if (timer) {
      clearTimeout(timer);
    }
  }, [timer]);

  useEffect(() => clearTimer,
    [clearTimer]);

  const endTimerAction = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    clearTimer();
    if (delayedAction) {
      delayedAction(e);
    }
    onBlur(e.target.value);
  };

  const handleChangeValue = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    handleChange(e.target.value);
    if (onChange) {
      onChange();
    }
    if (delayedAction) {
      clearTimer();
      setTimer(setTimeout(() => {
        delayedAction(e);
      }, 1000));
    }
  };

  return (
    <div className="material-textfield-wrapper">
      <TextField
        {...field}
        onChange={handleChangeValue}
        value={value || field.value || ''}
        error={!!errorMessage}
        helperText={errorMessage}
        fullWidth
        inputRef={fieldRef}
        size="medium"
        disabled={disabled}
        variant="filled"
        type={type || 'text'}
        inputProps={(type === 'number') ? { inputMode: 'numeric', pattern: '[0-9.]*' } : undefined}
        rows={rows}
        maxRows={rows ? undefined : 7}
        multiline={type === 'textarea'}
        placeholder={placeholder || undefined}
        label={label ? `${label}${requierd ? ' *' : ''}` : undefined}
        onBlur={endTimerAction}
        autoComplete={disableNativeAutocomplete ? 'off' : undefined}
        InputProps={{
          tabIndex: -1,
          disableUnderline: true,
          startAdornment: prefix && <InputAdornment position="start">{prefix}</InputAdornment>,
          endAdornment: (hasSaveResult || suffix)
            && <InputAdornment position="end">{hasSaveResult ? getSaveResult() : suffix}</InputAdornment>,
        }}
      />
    </div>
  );
}
