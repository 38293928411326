import React, { Dispatch, SetStateAction } from 'react';
import {
  Route,
  Switch, useHistory, useLocation,
  useParams,
} from 'react-router-dom';
import {
  APPLICATION_DOCUMENT,
  DOCUMENTS_DETAILS_UPLOAD,
  ID,
} from 'routes/Routes';
import UploadDocumentPage
  from 'pages/client-app/client-application/documents/documentDetails/uploadDocs/UploadDocumentPage';
import DisplayDocumentPage
  from 'pages/client-app/client-application/documents/documentDetails/displayDoc/DisplayDocumentPage';
import Messages from 'services/i18n/Messages';
import { detectMobile } from 'services/utils';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/core';
import { Routes } from 'routes/RoutesUtils';

type Param = {
  id?: string,
};

const DOC_BY_DOC = 'docByDoc';
const ALL_IN_ONE_DOC = 'allInOne';

type Props = {
  isFullScreen: boolean,
  setIsFullScreen: Dispatch<SetStateAction<boolean>>,
};

export default function DocumentsRouter({ isFullScreen, setIsFullScreen }: Props) {
  const { id } = useParams<Param>();
  const history = useHistory();
  const { pathname } = useLocation();

  const uploadCaseUrl = Routes.withPath(DOCUMENTS_DETAILS_UPLOAD, [{ label: ID, value: id || '' }]);
  const uploadDocUrl = Routes.withPath(APPLICATION_DOCUMENT, [{ label: ID, value: id || '' }]);

  const changeModeHandler = (
    event: React.MouseEvent<HTMLElement>,
    modeChosen: string,
  ) => {
    if (modeChosen === ALL_IN_ONE_DOC) {
      history.push(uploadCaseUrl);
    } else {
      history.push(uploadDocUrl);
    }
  };

  return (
    <>
      {
        !isFullScreen && (
          <div className="toggle-buttons-add-docs">
            <span>{Messages.t('document.addDocumentMode')} :</span>
            <ToggleButtonGroup
              color="primary"
              value={pathname === uploadCaseUrl ? ALL_IN_ONE_DOC : DOC_BY_DOC}
              exclusive
              onChange={changeModeHandler}
            >
              <ToggleButton value={DOC_BY_DOC}>{Messages.t('document.docByDoc')}</ToggleButton>
              <ToggleButton value={ALL_IN_ONE_DOC}>{Messages.t('document.completeFile')}</ToggleButton>
            </ToggleButtonGroup>
          </div>
        )
      }
      <Switch>
        <Route
          path={DOCUMENTS_DETAILS_UPLOAD}
          render={
            () => (
              <UploadDocumentPage
                isFullScreen={isFullScreen}
                setIsFullScreen={setIsFullScreen}
              />
            )
          }
        />
        <Route
          path={APPLICATION_DOCUMENT}
          render={
            () => <DisplayDocumentPage />
          }
        />
        {
          !detectMobile() && (
            <Route
              render={
                () => <p>{Messages.t('document.selectAContact')}</p>
              }
            />
          )
        }
      </Switch>
    </>
  );
}
