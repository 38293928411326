import React, { useCallback, useEffect, useState } from 'react';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { UseFormStateReturn } from 'react-hook-form/dist/types';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { Autocomplete, Checkbox, TextField } from '@material-ui/core';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import { getFinalErrorMessage } from 'lib/form/FormUtils';
import { ZipCodes } from 'lib/type/zipCode';

type Props = {
  control: {
    field: ControllerRenderProps<any, any>,
    fieldState: ControllerFieldState,
    formState: UseFormStateReturn<any>,
  },
  error?: FieldErrors,
  label: string,
  placeholder: string,
  apiErrors?: { [key: string]: string[] }
  allZoneMessage: string
  getPropositions: (value: string) => Promise<ZipCodes[]>
  getSelectedLabels: (value: string) => Promise<ZipCodes[]>
};

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

export default function ZipCodeAutoCompleteWrapper(
  {
    control,
    error,
    apiErrors,
    label,
    placeholder,
    getPropositions,
    allZoneMessage,
    getSelectedLabels,
  }: Props,
) {
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [zipCodes, setZipCodes] = useState<ZipCodes[]>([]);
  const [selectedZipCodesMap, setSelectedZipCodesMap] = useState<{ [key: string]: ZipCodes[] }>({});

  // eslint-disable-next-line no-undef
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const clearTimer = useCallback(() => {
    if (timer) {
      clearTimeout(timer);
    }
  }, [timer]);

  useEffect(() => clearTimer,
    []);

  const { field } = control;
  const errorMessage = getFinalErrorMessage(field.name, error, apiErrors);

  let value = field.value?.split(',') || [];
  if (value.length === 1 && value[0] === '') {
    value = [];
  }

  useEffect(() => {
    getSelectedLabels(field.value).then((zips) => {
      const zipCodeMap = zips?.reduce((acc, val) => {
        if (!acc[val.zipCode]) {
          acc[val.zipCode] = [];
        }
        acc[val.zipCode].push(val);
        return acc;
      }, {});
      setSelectedZipCodesMap(zipCodeMap);
    });
  }, [field.value]);

  const filterOptions = (options) => options;
  return (
    <div className="material-select-wrapper">
      <Autocomplete
        {...field}
        value={value}
        onChange={(e, val) => {
          const fieldVal = val.map((zip) => {
            if (typeof zip === 'string') {
              return zip;
            }
            return zip.zipCode;
          }).join(',');
          field.onChange(fieldVal);
        }}
        limitTags={3}
        multiple
        isOptionEqualToValue={(option, val) => (
          (val && option.zipCode)
            ? option.zipCode?.startsWith(val)
            : false
        )}
        options={zipCodes}
        getOptionDisabled={(option) => field.value && field.value.split(',').some((selected) => option.zipCode.startsWith(selected))}
        freeSolo
        open
        filterOptions={filterOptions}
        loading={loading}
        inputValue={searchValue}
        onInputChange={(e, v, reason) => {
          if (reason === 'input') {
            clearTimer();
            setTimer(setTimeout(() => {
              setLoading(true);
              getPropositions(v).then((zips) => {
                if (!zips) {
                  setZipCodes([]);
                } else {
                  setZipCodes(zips);
                }
                setLoading(false);
              });
            }, 500));
            setSearchValue(v);
          }
        }}
        onBlur={() => {
          setSearchValue('');
          setZipCodes([]);
        }}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            if (selectedZipCodesMap) {
              const zipCodeList: ZipCodes[] = selectedZipCodesMap[option];
              if (zipCodeList) {
                return `${zipCodeList[0].city} (${zipCodeList[0].zipCode.length > 2 ? zipCodeList[0].zipCode : allZoneMessage})`;
              }
            }
            return option;
          }
          return '';
        }}
        renderOption={(props, zipCode, { selected }) => (
          <li {...props} key={`${zipCode.zipCode}-${zipCode.city}`}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            <div>
              {`${zipCode.city} (${zipCode.zipCode.length > 2 ? zipCode.zipCode : allZoneMessage})`}
            </div>
          </li>
        )}
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            placeholder={placeholder}
            error={!!errorMessage}
            helperText={errorMessage}
            variant="filled"
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
            }}
          />
        )}
      />
    </div>
  );
}
