import React from 'react';
import { useParams } from 'react-router-dom';
import { useAgencyBackend } from 'network/publicQueries/AgencyQueries';
import Image from 'theme/Image';
import Messages from 'services/i18n/Messages';

type Param = {
  id: string,
};

export default function CandidateFormEnding() {
  const { id } = useParams<Param>();
  const agencyQueries = useAgencyBackend();
  const { getAgency } = agencyQueries;
  const { data: agency } = getAgency(id);
  return (
    <div className="form-text-page">
      <div className="form-text-content">
        <div className="image-container">
          <Image src={agency?.imageURL} alt="agency" />
        </div>
        <div className="form-text">
          {Messages.t('form.ending.thanks')}
        </div>
        <div className="form-text">
          {Messages.t('form.ending.message')}
        </div>
        <div className="form-text">
          {agency && Messages.t('form.ending.team', { team: agency.name })}
        </div>
      </div>
    </div>
  );
}
