import React, { useEffect, useRef, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import FormQuestionTextField from 'pages/public-app/candidateForm/formComponents/FormQuestionTextField';
import {
  CandidateFormType,
  FormField,
} from 'types/forms/CandidateForm';
import Button from 'theme/Button';
import { useHistory, useParams } from 'react-router-dom';
import { useAgencyBackend } from 'network/publicQueries/AgencyQueries';
import FormQuestionProperties from 'pages/public-app/candidateForm/formComponents/FormQuestionProperties';
import FormQuestionSelect from 'pages/public-app/candidateForm/formComponents/FormQuestionSelect';
import { IconButton, LinearProgress } from '@material-ui/core';
import { getContactsFields } from 'pages/public-app/candidateForm/questions/contactsQuestions';
import { getGarantFields } from 'pages/public-app/candidateForm/questions/garantQuestion';
import { getFormFields } from 'pages/public-app/candidateForm/questions/formQuestions';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import ContactUtils from 'services/ContactUtils';
import GarantUtils from 'services/GarantUtils';
import { useAdminBackend } from 'network/queries/AdminQueries';
import { stringToBoolean } from 'lib/form/FormUtils';
import Messages from 'services/i18n/Messages';
import FormQuestionPhoneNumber from 'pages/public-app/candidateForm/formComponents/FormQuestionPhoneNumber';
import { Routes } from 'routes/RoutesUtils';
import { CANDIDATE_FORM_ENDING, ID } from 'routes/Routes';
import FormStartPage from 'pages/public-app/candidateForm/formComponents/FormStartPage';
import SpinButton from 'theme/SpinButton';
import useScrollerTo from 'lib/ScrollToHook';
import { foyerCompositionEnum } from 'types/Pinel';

type Param = {
  id: string,
};

export default function CandidateForm() {
  const { id } = useParams<Param>();
  const agencyQueries = useAgencyBackend();
  const adminQueries = useAdminBackend();
  const history = useHistory();
  const { getAgencyProperties, getAgency } = agencyQueries;
  const { saveCandidature } = adminQueries;
  const { data: agency } = getAgency(id);
  const { data: properties } = getAgencyProperties(id);
  const [container, setContainer] = useState<HTMLElement | null>(null);
  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const firstOffset = useRef<number | null>(null);
  const currentIndex = useRef<number>(0);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [currentIndexState, _setCurrentIndex] = useState(0);

  const maxIndex = useRef<number>(0);
  const currentDelta = useRef<number | null>(null);
  const scrollDoIdle = useRef<boolean>(false);
  const [pageContainer] = useState<{ data: FormField[] }>({ data: [] });
  const [contactNumber, setContactNumber] = useState(1);
  const [garantNumber, setGarantNumber] = useState(0);
  const [submitting, setSubmitting] = useState(false);

  const scrollerTo = useScrollerTo({
    container,
    htmlIds: pageContainer,
    padding: 0,
  });

  const setCurrentIndex = (value: number) => {
    currentIndex.current = value;
    _setCurrentIndex(value);
  };

  const scrollTo = (index: number, skipPage?: boolean) => {
    scrollDoIdle.current = true;
    setTimeout(() => {
      scrollDoIdle.current = false;
    }, 700);
    setCurrentIndex(index + (skipPage ? 1 : 0));
    if (maxIndex.current < currentIndex.current) {
      maxIndex.current = currentIndex.current;
    }
    scrollerTo.scrollTo(index, skipPage);
  };

  const {
    control,
    trigger,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<CandidateFormType>(
    {
      defaultValues: {
        contacts: [{}],
      },
    },
  );

  const formField = watch();

  const selectedProperty = properties?.find((property) => property.id === formField.propertyId);
  const pinelEnabledOnProperty = selectedProperty?.pinelEnabled;

  const { append: appendContact } = useFieldArray({
    control,
    name: 'contacts',
  });

  const saveForm = () => {
    setSubmitting(true);
    saveCandidature.mutateAsync({
      data: {
        ...formField,
        fromForm: true,
        moveInWanted: formField.moveInWanted ? Messages.t(`moveIn.${formField.moveInWanted}`) : null,
        contacts: formField.contacts
          .filter((contact) => contact.firstname)
          .map((contact) => ({
            ...contact,
            professionalMoveOut: stringToBoolean(contact.professionalMoveOut),
            moreThan10Employees: stringToBoolean(contact.moreThan10Employees),
            longDistance: stringToBoolean(contact.longDistance),
            trialPeriod: stringToBoolean(contact.trialPeriod),
            monthlyIncome: contact.monthlyIncome ? parseInt(contact.monthlyIncome, 10) : null,
          })),
        garants: formField.garants?.map((garant) => ({
          ...garant,
          trialPeriod: stringToBoolean(garant.trialPeriod),
          monthlyIncome: garant.monthlyIncome ? parseInt(garant.monthlyIncome, 10) : null,
          amount: garant.amount ? parseInt(garant.amount, 10) : null,
        })),
        fiscalIncomesN2: formField?.fiscalIncomesN2 ? parseFloat(formField.fiscalIncomesN2) : 0,
        foyerCompositionN2: formField.foyerCompositionN2 === foyerCompositionEnum.NCHILD ? (
          formField.foyerCompositionN2MoreThan3
        ) : formField.foyerCompositionN2,
      },
      agencyId: id,
    })
      .then(() => {
        reset({ contacts: [{}] });
        history.push(Routes.withPath(CANDIDATE_FORM_ENDING, [{ label: ID, value: id }]));
      })
      .finally(() => setSubmitting(false));
  };

  const base: FormField[] = getFormFields(
    appendContact,
    scrollTo,
    setContactNumber,
    setGarantNumber,
    saveForm,
    pinelEnabledOnProperty,
  );

  const computePages = (): FormField[] => base
    .map((page) => {
      if (page.type === 'generator') {
        if (page.pageId === 'contacts') {
          return [...Array(contactNumber)].map((_, index) => getContactsFields(
            index,
            setContactNumber,
            contactNumber,
          )).flat();
        }
        if (page.pageId === 'garants') {
          return [...Array(garantNumber)].map((_, index) => getGarantFields(
            index,
            formField,
          )).flat();
        }
      }
      return page;
    }).flat()
    .filter((page) => !page.condition || page.condition(formField));

  const pages = computePages();
  pageContainer.data = pages;

  const handleScrollChange = (clientY: number) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => {
      firstOffset.current = null;
      currentDelta.current = null;
    }, 500);
    if (firstOffset.current === null) {
      firstOffset.current = clientY;
    } else {
      const delta = firstOffset.current - clientY;
      if (delta > 250 && currentIndex.current < pages.length - 1 && currentIndex.current !== 1) {
        clearTimeout(timeout.current);
        firstOffset.current = null;
        currentDelta.current = null;
        if (maxIndex.current > currentIndex.current) {
          scrollTo(currentIndex.current + 1);
        }
      }
      if (delta < -250 && currentIndex.current > 1 && currentIndex.current !== 1) {
        clearTimeout(timeout.current);
        firstOffset.current = null;
        currentDelta.current = null;
        scrollTo(currentIndex.current - 1);
      }
    }
  };

  const wheelEvent = (e: WheelEvent): void => {
    if (scrollDoIdle.current) {
      return;
    }
    if (currentDelta.current === null) {
      currentDelta.current = 0;
    }
    currentDelta.current -= (e.deltaY / 2);
    // the division is mean to get the same feel as the touch
    handleScrollChange(currentDelta.current);
  };

  const touchEvent = (e: TouchEvent): void => {
    if (scrollDoIdle.current) {
      return;
    }
    handleScrollChange(e.touches[0].clientY * 1.4);
  };

  useEffect(() => {
    const resizePage = () => {
      scrollerTo.scrollTo(currentIndex.current, false, true);
    };
    container?.addEventListener('wheel', wheelEvent);
    container?.addEventListener('touchmove', touchEvent);
    window.visualViewport?.addEventListener('resize', resizePage);
    return () => {
      container?.removeEventListener('wheel', wheelEvent);
      container?.removeEventListener('touchmove', touchEvent);
      window.visualViewport?.removeEventListener('resize', resizePage);
    };
  }, [container]);

  let displayName: string | undefined;
  if (pages[currentIndexState]?.pageId.startsWith('contacts.')) {
    const contactIndex = pages[currentIndexState].pageId.split('.')[1];
    const currentContact = formField.contacts[parseInt(contactIndex, 10)];
    displayName = ContactUtils.getDisplayedName(currentContact, 0);
    if (displayName === 'No Data') {
      displayName = (parseInt(contactIndex, 10) + 1).toString();
    }
    displayName = `Locataire: ${displayName}`;
  }
  if (pages[currentIndexState]?.pageId.startsWith('garants.')) {
    const garantIndex = pages[currentIndexState].pageId.split('.')[1];
    const currentGarant = formField.garants[parseInt(garantIndex, 10)];
    displayName = GarantUtils.getDisplayedName(currentGarant);
    if (displayName === 'No Data') {
      displayName = (parseInt(garantIndex, 10) + 1).toString();
    }
    displayName = `Garant: ${displayName}`;
  }
  let progression = (currentIndex.current / (pages.length - 1)) * 100;
  if (progression > 100) {
    progression = 100;
  }
  return (
    <div>
      <div className="form-container" ref={(ref) => setContainer(ref)}>
        <div className="progess-bar-container">
          <LinearProgress variant="determinate" value={progression} />
        </div>
        <div className="navigation-button">
          <IconButton
            className={currentIndexState === 0 ? 'disabled' : ''}
            onClick={() => {
              // the default props disabled has a weird behavior here.
              // When switching from enable to disable it block the current scroll update
              if (currentIndexState !== 0) {
                scrollTo(currentIndexState - 1);
              }
            }}
          >
            <ExpandLess />
          </IconButton>
          <IconButton
            className={maxIndex.current <= currentIndex.current
            || currentIndexState === pages.length - 1 ? 'disabled' : ''}
            onClick={() => {
              // the default props disabled has a weird behavior here.
              // When switching from enable to disable it block the current scroll update
              if (maxIndex.current > currentIndex.current
                && currentIndexState !== pages.length - 1) {
                scrollTo(currentIndexState + 1);
              }
            }}
          >
            <ExpandMore />
          </IconButton>
          {
            displayName && (
              <div className="display-name">
                {displayName}
              </div>
            )
          }
        </div>
        {
          pages.map((page, index) => (
            <div className="form-page" role="presentation" id={page.pageId} key={page.pageId}>
              <div className="form-page-content">
                {
                  page.type === 'action' && (
                    <div className="question-container">
                      <h5>
                        {page.questionlabel}
                      </h5>
                      <div className="form-button-container">
                        <div className="validate-button">
                          <SpinButton
                            type="button"
                            onClick={() => {
                              if (page.action) {
                                page.action(index);
                              }
                            }}
                            title={page.actionButton ? page.actionButton : Messages.t('formButton.ok')}
                            editing={false}
                            spin={submitting}
                          />
                        </div>
                        {
                          page.skipButton && (
                            <Button onClick={() => scrollTo(index + 1)}>
                              {page.skipButton}
                            </Button>
                          )
                        }
                      </div>
                    </div>
                  )
                }
                {
                  page.type === 'textField' && (
                    <FormQuestionTextField
                      page={page}
                      control={control}
                      apiErrors={{}}
                      errors={errors}
                      trigger={trigger}
                      goToNextPage={() => scrollTo(index + 1)}
                    />
                  )
                }
                {
                  page.type === 'select' && (
                    <FormQuestionSelect
                      page={page}
                      control={control}
                      errors={errors}
                      trigger={trigger}
                      goToNextPage={() => scrollTo(index + 1)}
                      formField={formField}
                    />
                  )
                }
                {
                  page.type === 'properties' && (
                    <FormQuestionProperties
                      page={page}
                      control={control}
                      setValue={setValue}
                      apiErrors={{}}
                      errors={errors}
                      trigger={trigger}
                      goToNextPage={() => scrollTo(index + 1)}
                      properties={properties || []}
                    />
                  )
                }
                {
                  page.type === 'phoneNumber' && (
                    <FormQuestionPhoneNumber
                      page={page}
                      control={control}
                      apiErrors={{}}
                      errors={errors}
                      trigger={trigger}
                      goToNextPage={() => scrollTo(index + 1)}
                    />
                  )
                }
                {
                  page.type === 'start' && (
                    <FormStartPage
                      agency={agency}
                      goToNextPage={() => scrollTo(index + 1)}
                    />
                  )
                }
                {
                  page.type === 'end' && page.action && (
                    <FormQuestionTextField
                      page={page}
                      control={control}
                      apiErrors={{}}
                      errors={errors}
                      trigger={trigger}
                      goToNextPage={() => page.action?.(index)}
                      spin={submitting}
                    />
                  )
                }
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
}
