import React, {
  MouseEvent,
  MouseEventHandler,
  PointerEventHandler,
  ReactNode,
  TouchEventHandler,
} from 'react';
import { Button as MUIButton, IconButton } from '@material-ui/core';

type Props = {
  variant?: 'text' | 'outlined' | 'contained' | 'icon'
  roundedIcon?: boolean,
  type?: 'button' | 'submit' | 'reset'
  children: ReactNode
  color?: 'inherit' | 'primary' | 'secondary' | 'fifth' | 'success' | 'error' | 'info' | 'warning'
  className?: string
  preventDefault?: boolean
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
  disabled?: boolean,
  onPointerUp?: PointerEventHandler,
  onMouseUp?: MouseEventHandler,
  startIcon?: ReactNode,
  endIcon?: ReactNode,
  onTouchEnd?: TouchEventHandler,
  buttonRef?: ((
    instance: (HTMLButtonElement | null)
  ) => void) | React.RefObject<HTMLButtonElement> | null | undefined
  id?: string,
};

export default function Button(
  {
    color,
    variant,
    children,
    roundedIcon,
    onMouseUp,
    onTouchEnd,
    onClick,
    className,
    startIcon,
    endIcon,
    preventDefault,
    disabled,
    buttonRef,
    onPointerUp,
    id,
    type,
  }: Props,
) {
  if (variant === 'icon') {
    return (
      <IconButton
        ref={buttonRef}
        onTouchEnd={onTouchEnd}
        onMouseUp={onMouseUp}
        onPointerUp={onPointerUp}
        className={`mui-button ${className}`}
        id={id}
        onClick={(e) => {
          if (preventDefault) {
            e.stopPropagation();
            e.preventDefault();
          }
          e.stopPropagation();
          e.preventDefault();
          if (onClick) {
            onClick(e);
          }
        }}
      >
        {children}
      </IconButton>
    );
  }
  return (
    <MUIButton
      ref={buttonRef}
      id={id}
      onTouchEnd={onTouchEnd}
      onMouseUp={onMouseUp}
      startIcon={startIcon}
      endIcon={endIcon}
      onPointerUp={onPointerUp}
      disabled={disabled}
      className={`mui-button ${className || ''} ${color || ''} ${roundedIcon ? 'rounded-button' : ''}`}
      color={(color === 'fifth') ? 'secondary' : color || 'primary'}
      variant={variant || 'contained'}
      type={type}
      onClick={(e) => {
        if (preventDefault) {
          e.preventDefault();
        }
        if (onClick) {
          onClick(e);
        }
      }}
    >
      {children}
    </MUIButton>
  );
}
