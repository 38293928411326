import { observable, WritableObservable } from 'micro-observables';
import { SearchTypeDetails } from 'types/SearchType';

const SEARCH_KEY = 'SEARCH_KEY';
const LAST_VISITED_SEARCH = 'LAST_VISITED_SEARCH';

export class SearchService {
  // eslint-disable-next-line class-methods-use-this
  private getLocalSearch(): SearchTypeDetails | null {
    const searchStr = localStorage.getItem(SEARCH_KEY);
    if (searchStr && searchStr !== '') {
      return JSON.parse(searchStr);
    }
    return null;
  }

  private search: WritableObservable<SearchTypeDetails | null> = observable(this.getLocalSearch());

  setSearch(searchProp: SearchTypeDetails | null) {
    localStorage.setItem(SEARCH_KEY, JSON.stringify(searchProp));
    this.search.set(searchProp);
  }

  getSearchObservable() {
    return this.search.readOnly();
  }

  getSearch() {
    return this.search.get();
  }

  // eslint-disable-next-line class-methods-use-this
  setLastVisitedSearch(searchId: string) {
    localStorage.setItem(LAST_VISITED_SEARCH, searchId);
  }

  // eslint-disable-next-line class-methods-use-this
  getLastVisitedSearch() {
    return localStorage.getItem(LAST_VISITED_SEARCH);
  }
}

const searchService = new SearchService();
export default searchService;
