import { Enum } from 'lib/type/enum';

export interface DocumentData {
  id: string,
  createdAt: string,
  updatedAt: string,
  tags: string,
  link?: string,
  type: DocumentType,
  comment?: string,
  order?: number,
}

export interface DocumentCreation {
  tags: (DocumentTag | DocumentTagPending)[],
  file?: FileUploadNoId
  type: DocumentType,
  comment?: string,
}

export interface FileUploadNoId {
  fileBase64: string,
  filename: string,
  fileType?: string,
}

export type DocumentType = 'DOCUMENT' | 'COMMENT';

export type DocumentTagPending = 'PENDING';

export const documentTagPendingEnum: Enum<DocumentTagPending> = {
  PENDING: 'PENDING',
};

export const documentTypeEnum: Enum<DocumentType> = {
  DOCUMENT: 'DOCUMENT',
  COMMENT: 'COMMENT',
};

export type DocumentTag =
  'ID'
  | 'OTHER'
  | 'PAYSLIP_N1'
  | 'PAYSLIP_N2'
  | 'EMPLOYEMENT_CONTRACT'
  | 'PAYSLIP_N3'
  | 'PROOF_OF_ACTIVTY'
  | 'SCHOOL_CERTIFICATE'
  | 'NOTICE_OF_SITUATION'
  | 'TAX_NOTICE'
  | 'ADDRESS_PROOF'
  | 'ACCOUNTING'
  | 'RIGHT_NOTICE'
  | 'OTHER_RESSOURCES_PROOF'
  | 'RETIREMENT_SLIPS'
  | 'RESSOURCES_PROOF'
  | 'GUARANTEE_CERTIFICATE'
  | 'EMPLOYER_PROOF'
  | 'GUARANTEE_COMMITMENT';

export const documentTagEnum: Enum<DocumentTag> = {
  OTHER: 'OTHER',
  ID: 'ID',
  EMPLOYEMENT_CONTRACT: 'EMPLOYEMENT_CONTRACT',
  PAYSLIP_N1: 'PAYSLIP_N1',
  PAYSLIP_N2: 'PAYSLIP_N2',
  PAYSLIP_N3: 'PAYSLIP_N3',
  PROOF_OF_ACTIVTY: 'PROOF_OF_ACTIVTY',
  SCHOOL_CERTIFICATE: 'SCHOOL_CERTIFICATE',
  NOTICE_OF_SITUATION: 'NOTICE_OF_SITUATION',
  TAX_NOTICE: 'TAX_NOTICE',
  ADDRESS_PROOF: 'ADDRESS_PROOF',
  ACCOUNTING: 'ACCOUNTING',
  RIGHT_NOTICE: 'RIGHT_NOTICE',
  OTHER_RESSOURCES_PROOF: 'OTHER_RESSOURCES_PROOF',
  RETIREMENT_SLIPS: 'RETIREMENT_SLIPS',
  RESSOURCES_PROOF: 'RESSOURCES_PROOF',
  GUARANTEE_CERTIFICATE: 'GUARANTEE_CERTIFICATE',
  EMPLOYER_PROOF: 'EMPLOYER_PROOF',
  GUARANTEE_COMMITMENT: 'GUARANTEE_COMMITMENT',
};

export type DocumentSection =
  'ID'
  | 'TAX_NOTICE'
  | 'SITUATION'
  | 'HOME'
  | 'RESSOURCES'
  | 'GUARANTEE_CERTIFICATE'
  | 'EMPLOYER_PROOF'
  | 'GUARANTEE_COMMITMENT'
  | 'OTHER';

export const documentSectionEnum: Enum<DocumentSection> = {
  OTHER: 'OTHER',
  ID: 'ID',
  TAX_NOTICE: 'TAX_NOTICE',
  SITUATION: 'SITUATION',
  HOME: 'HOME',
  RESSOURCES: 'RESSOURCES',
  GUARANTEE_CERTIFICATE: 'GUARANTEE_CERTIFICATE',
  EMPLOYER_PROOF: 'EMPLOYER_PROOF',
  GUARANTEE_COMMITMENT: 'GUARANTEE_COMMITMENT',
};

export const optionalTags: DocumentTag[] = [
  documentTagEnum.OTHER,
  documentTagEnum.OTHER_RESSOURCES_PROOF,
  documentTagEnum.RESSOURCES_PROOF,
];

export const documentSection: { [key in DocumentSection]: DocumentTag[] } = {
  ID: [documentTagEnum.ID],
  SITUATION: [
    documentTagEnum.EMPLOYEMENT_CONTRACT,
    documentTagEnum.PROOF_OF_ACTIVTY,
    documentTagEnum.SCHOOL_CERTIFICATE,
    documentTagEnum.NOTICE_OF_SITUATION,
  ],
  TAX_NOTICE: [
    documentTagEnum.TAX_NOTICE,
  ],
  HOME: [
    documentTagEnum.ADDRESS_PROOF,
  ],
  RESSOURCES: [
    documentTagEnum.PAYSLIP_N1,
    documentTagEnum.PAYSLIP_N2,
    documentTagEnum.PAYSLIP_N3,
    documentTagEnum.ACCOUNTING,
    documentTagEnum.RIGHT_NOTICE,
    documentTagEnum.OTHER_RESSOURCES_PROOF,
    documentTagEnum.RETIREMENT_SLIPS,
    documentTagEnum.RESSOURCES_PROOF,
  ],
  GUARANTEE_CERTIFICATE: [
    documentTagEnum.GUARANTEE_CERTIFICATE,
  ],
  EMPLOYER_PROOF: [
    documentTagEnum.EMPLOYER_PROOF,
  ],
  GUARANTEE_COMMITMENT: [
    documentTagEnum.GUARANTEE_COMMITMENT,
  ],
  OTHER: [documentTagEnum.OTHER],
};
