import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ReactSortable, Sortable } from 'react-sortablejs';
import FilePreview from 'pages/common/FilePreview';
import {
  DocumentData, documentSection,
  DocumentTag, documentTagPendingEnum, optionalTags,
} from 'types/DocumentData';
import { useDocumentBackend } from 'network/queries/DocumentQueries';
import { NotificationService } from 'lib/notification';
import Messages from 'services/i18n/Messages';
import {
  ArrowDownward,
  ArrowUpward,
  Delete, DeleteSweep, Done, Fullscreen, FullscreenExit,
  PictureAsPdf, Undo,
} from '@material-ui/icons';
import {
  CircularProgress,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import DocumentTagViewer from 'pages/client-app/client-application/documents/documentDetails/displayDoc/DocumentTagViewer';
import { DocumentGroup } from 'types/DocumentGroup';
import { useHistory } from 'react-router-dom';
import DocumentUtils from 'services/DocumentUtils';
import Button from 'theme/Button';
import { MultiDrag } from 'sortablejs';
import UploadFiles from 'pages/client-app/client-application/documents/documentDetails/uploadDocs/UploadFiles';
import confirmationService from 'services/ConfirmationService';
import { detectMobile } from 'services/utils';
import DialogWrapper from 'pages/common/DialogWrapper';

Sortable.mount(new MultiDrag());

type Props = {
  files: DocumentData[]
  documentGroup: DocumentGroup,
  documentsByTag: { [tag: string]: DocumentData[] | undefined }
  isFullScreen: boolean,
  setIsFullScreen: Dispatch<SetStateAction<boolean>>,
};

type Action = {
  file: DocumentData
  previousTag: DocumentTag,
  actionId: number,
};

// TODO Split component
export default function FileCarrousel(
  {
    files,
    documentsByTag,
    documentGroup,
    isFullScreen,
    setIsFullScreen,
  }: Props,
) {
  const documentQueries = useDocumentBackend();
  const history = useHistory();
  const isMobile = detectMobile();

  const [actionList, setActionList] = useState<Action[]>([]);
  const [totalSize, setTotalSize] = useState(files.length);
  const [selectedTag, setSelectedTag] = useState<DocumentTag | null>(null);
  const [fileList, setFileList] = useState(files);
  const [submitting, setSubmitting] = useState(false);
  const goBackRef = useRef<HTMLButtonElement>(null);
  const deleteRef = useRef<HTMLButtonElement>(null);
  const tagListRef = useRef<HTMLDivElement>(null);
  const [showTopIndicator, setShowTopIndicator] = useState(false);
  const [showBottomIndicator, setShowBottomIndicator] = useState(false);

  useEffect(() => {
    if (totalSize === 0) {
      setTotalSize(files.length);
    }
  }, [files.length, totalSize]);

  useEffect(() => {
    function updateScrollPosition() {
      if (tagListRef.current
        && tagListRef.current.offsetHeight !== tagListRef.current.scrollHeight
        && tagListRef.current.scrollTop !== 0) {
        setShowTopIndicator(true);
      } else {
        setShowTopIndicator(false);
      }
      if (tagListRef.current
        && tagListRef.current.offsetHeight !== tagListRef.current.scrollHeight
        && tagListRef.current.scrollTop + tagListRef.current.offsetHeight
        < (tagListRef.current.scrollHeight - 2)) {
        setShowBottomIndicator(true);
      } else {
        setShowBottomIndicator(false);
      }
    }

    if (tagListRef && tagListRef.current) {
      tagListRef.current.addEventListener('scroll', updateScrollPosition, false);
      return () => {
        tagListRef.current?.removeEventListener('scroll', updateScrollPosition, false);
      };
    }
    return () => {
    };
  }, []);

  useEffect(() => {
    if (tagListRef.current
      && tagListRef.current.offsetHeight !== tagListRef.current.scrollHeight
      && tagListRef.current.scrollTop + tagListRef.current.offsetHeight
      < (tagListRef.current.scrollHeight - 2)) {
      setShowBottomIndicator(true);
    } else {
      setShowBottomIndicator(false);
    }
  }, [tagListRef.current]);

  const [defaultTagNumber] = useState(() => Object.keys(documentsByTag)
    .reduce((acc: { [tag: string]: number }, key) => {
      acc[key] = documentsByTag[key]?.filter((doc) => doc.order)
        .sort((a, b) => (b.order || 0) - (a.order || 0))[0]?.order || 0;
      return acc;
    }, {}));

  const [selectedsDocumentId, setSelectedsDocumentId] = useState<string[]>([]);
  const { updateDocument, deleteDocument, deleteDocumentWithTag } = documentQueries;

  useEffect(() => {
    setFileList(files);
  }, [files.length]);

  const deleteDoc = async (documentId: string, doGoback: boolean) => {
    setSubmitting(true);
    deleteDocument.mutateAsync({
      documentId,
      documentGroupId: documentGroup.id,
    }).then(() => {
      if (fileList.length <= 1 && doGoback) {
        history.goBack();
      }
      setFileList((prevList) => prevList
        .filter((doc) => doc.id !== documentId));
    })
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => setSubmitting(false));
  };

  const saveDocument = (file: DocumentData, tag: DocumentTag, fromGoBack?: boolean) => {
    setSubmitting(true);
    const fileOrder = DocumentUtils.getFileNumber(file.link);
    return updateDocument.mutateAsync({
      data: {
        order: (defaultTagNumber[tag] || 0) + (totalSize - fileOrder),
        tags: tag,
      },
      documentGroupId: documentGroup.id,
      documentId: file.id,
    }).then(() => {
      if (!fromGoBack) {
        setActionList((prevState) => prevState.concat({
          file,
          previousTag: file.tags as DocumentTag,
          actionId: Date.now(),
        }));
        if (fileList.length <= 1) {
          history.goBack();
        }
      }
      NotificationService.notifySuccess(Messages.t('notifications.update'));
      setFileList((prevList) => prevList
        .filter((doc) => doc.id !== file.id));
    })
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => setSubmitting(false));
  };

  const goBack = () => {
    const action = actionList.slice(-1)[0];
    if (!action) {
      return;
    }
    saveDocument(action.file, action.previousTag, true).then(() => {
      setActionList((prevState) => prevState
        .filter((actionElt) => actionElt.actionId !== action.actionId));
    });
  };

  useEffect(() => {
    const handleKeyBoardEvent = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === 'z') {
        if (goBackRef.current) {
          goBackRef.current.click();
        }
      }
      if (event.key === 'Backspace') {
        if (deleteRef.current) {
          deleteRef.current.click();
        }
      }
    };
    document.addEventListener('keydown', handleKeyBoardEvent);
    return () => {
      document.removeEventListener('keydown', handleKeyBoardEvent);
    };
  }, []);

  const deleteSelectedDoc = async () => {
    if (selectedsDocumentId.length > 1) {
      const isConfirmed = await confirmationService.confirm({
        title: Messages.t('formButton.delete'),
        message: Messages.t('document.deleteN', { nbDocument: selectedsDocumentId.length }),
        actionMessage: Messages.t('formButton.delete'),
        actionColor: 'error',
      });
      if (!isConfirmed) {
        return;
      }
    }
    selectedsDocumentId.forEach((documentId) => {
      deleteDoc(documentId, false);
    });
    setSelectedsDocumentId([]);
  };

  const deleteAllDoc = async () => {
    const isConfirmed = await confirmationService.confirm({
      title: Messages.t('formButton.delete'),
      message: Messages.t('document.replace', { pageNumber: fileList.length }),
      actionMessage: Messages.t('formButton.delete'),
      actionColor: 'error',
    });
    if (!isConfirmed) {
      return;
    }

    deleteDocumentWithTag.mutateAsync({
      tag: documentTagPendingEnum.PENDING,
      documentGroupId: documentGroup.id,
    }).then(() => {
      setFileList([]);
    })
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => setSubmitting(false));
    fileList.forEach((file) => {
      deleteDoc(file.id, false);
    });
    setSelectedsDocumentId([]);
  };

  const possibleTag = documentGroup && Object.keys(documentSection)
    .map((section) => documentSection[section]
      .filter((tag) => documentGroup.possibleTags?.split(',')?.includes(tag)))
    .flat();

  return (
    <>
      <div className="progression-info">
        <div className="progression-header">
          <div>
            {Messages.t('document.drop', { number: totalSize - fileList.length, total: totalSize })}
          </div>
        </div>
        <div className="progression-bar-container">
          {
            [...Array(totalSize)].map((_, index) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={`${index}-progress`}
                className={`progression-step ${index < totalSize - fileList.length ? 'completed' : ''}`}
              />
            ))
          }
        </div>
      </div>
      <div
        role="presentation"
        onPointerUp={(e) => {
          e.stopPropagation();
        }}
        onMouseUp={(e) => {
          e.stopPropagation();
        }}
        onTouchEnd={(e) => {
          e.stopPropagation();
        }}
        className="action-bar"
      >
        <div>
          {
            submitting && (
              <CircularProgress size={25} />
            )
          }
        </div>
        <div className="side-actions-container">
          {
            isMobile && (
              <IconButton
                className="finished-button"
                onClick={() => setIsFullScreen((prevState) => !prevState)}
              >
                {
                  isFullScreen ? <FullscreenExit /> : <Fullscreen />
                }
              </IconButton>
            )
          }
          <Tooltip title={Messages.t('tooltip.upload')}>
            <div>
              <UploadFiles
                asPicto
                isSubmitting={setSubmitting}
                id={documentGroup.id}
                doUpload={(fileNumber) => {
                  setActionList([]);
                  setTotalSize((prevState) => prevState + fileNumber);
                }}
              />
            </div>
          </Tooltip>
          {
            selectedsDocumentId.length > 0 && (
              <Tooltip title={Messages.t('document.deleteN.tooltip', { nbDocument: selectedsDocumentId.length })}>
                <div className="delete-button-container">
                  <IconButton
                    ref={deleteRef}
                    onClick={deleteSelectedDoc}
                  >
                    <Delete />
                  </IconButton>
                  {
                    selectedsDocumentId.length > 1 && (
                      <div className="to-delete-number">
                        <span>{selectedsDocumentId.length}</span>
                      </div>
                    )
                  }
                </div>
              </Tooltip>
            )
          }
          <Tooltip title={Messages.t('document.replace', { pageNumber: fileList.length })}>
            <div>
              <IconButton
                className="replace-button"
                onClick={deleteAllDoc}
              >
                <DeleteSweep />
              </IconButton>
            </div>
          </Tooltip>

          {
            isMobile ? (
              <IconButton
                className="cancel-button"
                disabled={actionList.length === 0}
                onClick={goBack}
              >
                <Undo />
              </IconButton>
            ) : (
              <Button
                startIcon={<Undo />}
                className="cancel-button"
                buttonRef={goBackRef}
                disabled={actionList.length === 0}
                onClick={goBack}
              >
                {Messages.t('formButton.cancel')}
              </Button>
            )
          }
          {
            isMobile ? (
              <IconButton
                className="finished-button"
                onClick={() => history.goBack()}
              >
                <Done />
              </IconButton>
            ) : (
              <Button
                className="finished-button"
                onClick={() => history.goBack()}
              >
                {Messages.t(('document.finished'))}
              </Button>
            )
          }
        </div>
      </div>
      <div className="documents-upload-page-preview">
        <div className="document-preview-list-container">
          {
            fileList.length === 0 && (
              <>
                {Messages.t('document.quit')}
                <UploadFiles
                  doUpload={() => {
                    setActionList([]);
                    setTotalSize(0);
                  }}
                  hideTitle
                  id={documentGroup.id}
                />
              </>
            )
          }
          <ReactSortable
            multiDrag
            onSelect={(e) => {
              // The timeout is a hack to avoid clicking
              // on the button that are not displayed when selectingy an element
              setTimeout(() => {
                setSelectedsDocumentId((prev) => prev.concat(e.item.dataset.id || ''));
              }, 75);
            }}
            onDeselect={(e) => {
              setSelectedsDocumentId((prev) => prev.filter((id) => id !== (e.item.dataset.id || '')));
            }}
            className="document-preview-list"
            group="shared"
            list={
              fileList
                .filter((file) => file.link)
                .sort((a, b) => (DocumentUtils.getFileNumber(a.link)
                  - DocumentUtils.getFileNumber(b.link)))
            }
            delay={75}
            fallbackTolerance={isMobile ? 3 : 60}
            forceFallback
            delayOnTouchOnly
            filter=".preview-actions"
            animation={200}
            setList={() => {
            }}
            scrollSensitivity={1000}
          >
            {
              fileList
                .filter((file) => file.link)
                .sort((a, b) => (DocumentUtils.getFileNumber(a.link)
                  - DocumentUtils.getFileNumber(b.link)))
                .map((file) => (
                  <div
                    role="presentation"
                    key={file.id}
                    className={`file-preview-container tag-section ${selectedsDocumentId.includes(file.id) ? 'document-selected' : ''}`}
                  >
                    <div className="pdf-preview-mobile">
                      <PictureAsPdf />
                    </div>
                    <FilePreview
                      disabled={submitting}
                      onDelete={() => deleteDoc(file.id, false)}
                      displayAction={selectedsDocumentId.includes(file.id)}
                      src={file.link || ''}
                    />
                  </div>
                ))
            }
          </ReactSortable>
          <ReactSortable
            className="document-preview-list-filler"
            group="shared"
            list={[]}
            animation={200}
            scrollSensitivity={1000}
            setList={() => {
            }}
          />
        </div>
        <div className="document-tag-list-container">
          {
            showTopIndicator && (
              <div className="scrollable-indicator">
                <ArrowUpward />
              </div>
            )
          }
          {
            showBottomIndicator && (
              <div className="bottom scrollable-indicator">
                <ArrowDownward />
              </div>
            )
          }
          <div className="document-tag-list" ref={tagListRef}>

            {
              possibleTag
                .map((tag) => (
                  <ReactSortable
                    key={tag}
                    draggable=".item"
                    group="shared"
                    list={[{ id: tag }]}
                    animation={200}
                    setList={(vals) => {
                      setSelectedsDocumentId([]);
                      const values = vals.filter((val) => val.id !== tag && val.id);
                      values.forEach((value) => {
                        saveDocument(value as DocumentData, tag as DocumentTag);
                      });
                    }}
                    scrollSensitivity={1000}
                  >
                    <button
                      disabled={submitting}
                      type="button"
                      onClick={() => {
                        if ((documentsByTag[tag]?.length || 0) > 0) {
                          setSelectedTag(tag as DocumentTag);
                        }
                      }}
                      className="tag-section"
                    >
                      <div className="tag-name">
                        <div className="tag-count">
                          {documentsByTag[tag]?.length || 0}
                        </div>
                        {Messages.t(`document.tag.${tag as DocumentTag}`)}
                      </div>
                      <div
                        className={`completion-indicator ${DocumentUtils.getCompletionIndicator(documentsByTag[tag])} ${optionalTags.includes(tag)
                          ? 'optional'
                          : ''}`}
                      />
                    </button>
                  </ReactSortable>
                ))
            }
          </div>
        </div>
      </div>
      {
        selectedTag && documentsByTag && (
          <DialogWrapper open onClose={() => setSelectedTag(null)}>
            <DocumentTagViewer
              onClose={() => setSelectedTag(null)}
              tag={selectedTag}
              hideUpload
              documentsByTags={documentsByTag}
              documentGroupId={documentGroup.id}
            />
          </DialogWrapper>
        )
      }
    </>
  );
}
