import React, { useEffect, useState } from 'react';
import PublicRoot from 'pages/public-app/PublicRoot';
import { AuthProvider, useFirebaseApp } from 'reactfire';
import { getAuth } from 'firebase/auth';
import { useObservable } from 'micro-observables';
import sessionManager from 'services/sessionManager';
import sessionService from 'services/sessionService';
import localService from 'services/i18n/LocalService';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import confirmationService from 'services/ConfirmationService';
import ConfirmationModalBase from 'pages/common/ConfirmationModalBase';
import ClientRoot from 'pages/client-app/ClientRoot';
import {
  CANDIDATE_FORM,
  CANDIDATE_FORM_ENDING,
  EXPIRED,
  LOG_IN,
} from 'routes/Routes';
import CandidateFormEnding from 'pages/public-app/candidateFormEnding/CandidateFormEnding';
import CandidateForm from 'pages/public-app/candidateForm/CandidateForm';
import { isBefore } from 'date-fns';
import { Routes } from 'routes/RoutesUtils';
import settings from 'services/settings';

function App() {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    // @ts-ignore
    window.Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: settings.intercomKey,
      created_at: Date.now(),
    });
  }, []);

  const confirmationModal = useObservable(confirmationService.getModal());

  const app = useFirebaseApp(); // a parent component contains a `FirebaseAppProvider`
  const authToken = useObservable(sessionManager.getCurrentTokenObservable());
  const [isLoading, setIsLoading] = useState(true);
  useObservable(localService.getLocal());
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const refresh = queryParams.get('refresh');
    if (token) {
      sessionManager.setCurrentToken(token);
    }
    if (refresh) {
      const session = sessionManager.getRefreshSession(refresh);
      if (session?.exp) {
        const exp = new Date(session?.exp * 1000);
        if (isBefore(exp, new Date())) {
          setIsLoading(false);
          history.replace(Routes.updateUrlWithQuery(
            LOG_IN,
            [{ label: EXPIRED, value: 'true' }],
          ));
          return;
        }
      }
      sessionManager.setRefreshToken(refresh);
    }
    if (token || refresh) {
      history.push(location.pathname);
    }
    const refreshToken = sessionManager.getRefreshToken();
    if (refreshToken) {
      sessionService.refreshSession(refreshToken).then(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }, []);

  const auth = getAuth(app);
  if (isLoading) {
    return null;
  }
  return (
    <div className="app">
      {
        confirmationModal && (
          <ConfirmationModalBase
            title={confirmationModal.title}
            message={confirmationModal.message}
            actionMessage={confirmationModal.actionMessage}
            actionColor={confirmationModal.actionColor}
            inverseButton={confirmationModal.inverseButton}
            cancelMessage={confirmationModal.cancelMessage}
            columnButton={confirmationModal.columnButton}
          />
        )
      }
      <AuthProvider sdk={auth}>
        <Switch>
          <Route path={CANDIDATE_FORM_ENDING} component={CandidateFormEnding} />
          <Route path={CANDIDATE_FORM} component={CandidateForm} />
          <Route render={() => (
            !authToken
              ? <PublicRoot />
              : (
                <ClientRoot isLoading={isLoading} setIsLoading={setIsLoading} />
              )
          )}
          />
        </Switch>
      </AuthProvider>
    </div>
  );
}

export default App;
