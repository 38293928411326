import React, { useState } from 'react';
import {
  format,
} from 'date-fns';
import localService from 'services/i18n/LocalService';

import Button from 'theme/Button';
import { PossibleVisitSlot } from 'types/VisitSlot';
import Messages from 'services/i18n/Messages';

type Props = {
  visits: PossibleVisitSlot[]
  showMore: () => void,
  selectVisit: (visit: PossibleVisitSlot) => void
  submitting?: boolean,
};

const MAX_DISPLAYED_VISIT = 3;
export default function BookingSelection(
  {
    visits,
    showMore,
    selectVisit,
    submitting,
  }: Props,
) {
  const [selectedVisit, setSelectedVisit] = useState<PossibleVisitSlot | undefined>();

  return (
    <>
      <h3>{Messages.t('booking.visit')}</h3>

      <div className="booking-selection">
        {
          visits.slice(0, MAX_DISPLAYED_VISIT).map((visit) => (
            <div key={visit.dateHash} className="selection-button-container">
              <Button disabled={submitting} onClick={() => setSelectedVisit(visit)} color="fifth">
                {format(new Date(visit.startDate), Messages.t('booking.visit.dateFormat'), { locale: localService.getDateLocal() })}
              </Button>
              {
                selectedVisit && selectedVisit.dateHash === visit.dateHash && (
                  <Button disabled={submitting} onClick={() => selectVisit(visit)}>
                    {Messages.t('formButton.validate')}
                  </Button>
                )
              }
            </div>
          ))
        }
        {
          visits.length > MAX_DISPLAYED_VISIT && (
            <Button disabled={submitting} color="secondary" onClick={showMore}>
              {Messages.t('booking.visit.showMore')}
            </Button>
          )
        }
      </div>
    </>
  );
}
