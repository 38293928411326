import React, { useEffect, useState } from 'react';
import { ClientSearch } from 'types/SearchType';
import { Routes } from 'routes/RoutesUtils';
import { ID, SEARCH_CREATE, SEARCH_DETAILS } from 'routes/Routes';
import { useHistory } from 'react-router-dom';
import Messages from 'services/i18n/Messages';
import {
  AddCircleOutline,
  DeleteOutlined,
  NotificationsOffOutlined,
  NotificationsOutlined,
} from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { ZipCodes } from 'lib/type/zipCode';
import { zipCodeApi } from 'network/api/zipCodeApi';
import { useSearchesBackend } from 'network/queries/SearchQueries';
import { NotificationService } from 'lib/notification';
import confirmationService from 'services/ConfirmationService';

type Props = {
  searchList: ClientSearch[]
};

export default function SearchList({ searchList }: Props) {
  const history = useHistory();

  const [zipCodes, setZipCodes] = useState<{ [key: string]: ZipCodes[] }>({});

  const [submitting, setSubmitting] = useState(false);

  const zipStr = searchList.map((search) => search.alert.postalCodes).join(',');
  const searchesQueries = useSearchesBackend();
  const { updateSearch, deleteSearch } = searchesQueries;

  const toggleNotification = (searchId: string, enableNotification: boolean) => {
    setSubmitting(true);
    updateSearch.mutateAsync({ searchId, data: { active: enableNotification } })
      .then(() => NotificationService.notifySuccess(Messages.t('notifications.update')))
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => setSubmitting(false));
  };

  const removeSearch = async (searchId: string) => {
    const shouldDelete = await confirmationService.confirm({
      message: Messages.t('search.delete'),
      actionMessage: Messages.t('formButton.delete'),
      actionColor: 'error',
    });
    if (shouldDelete) {
      setSubmitting(true);
      deleteSearch.mutateAsync(searchId)
        .then(() => NotificationService.notifySuccess(Messages.t('notifications.delete')))
        .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
        .finally(() => setSubmitting(false));
    }
  };

  useEffect(() => {
    zipCodeApi.listZipCodes(zipStr).then((zips) => {
      const zipCodeMap = zips?.reduce((acc, val) => {
        if (!acc[val.zipCode]) {
          acc[val.zipCode] = [];
        }
        acc[val.zipCode].push(val);
        return acc;
      }, {});
      setZipCodes(zipCodeMap);
    });
  }, [zipStr]);

  return (
    <div className="search-list">
      {
        searchList.sort((a, b) => b.createdAt.localeCompare(a.createdAt)).map((search) => {
          const labelList: string[] = [];
          if (search.alert.postalCodes) {
            labelList.push(search.alert.postalCodes.split(',').map((zip) => (zipCodes[zip] ? zipCodes[zip][0].city : undefined)).join(', '));
          }
          if (search.alert.rooms) {
            labelList.push(search.alert.rooms.split(',').map((roomNumber) => Messages.t('property.rooms', { smart_count: roomNumber })).join(', '));
          }
          if (search.alert.areaMin || search.alert.areaMax) {
            labelList.push(`${search.alert.areaMin ? `${search.alert.areaMin}m2` : ''}-${search.alert.areaMax ? `${search.alert.areaMax}m2` : ''}`);
          }
          if (search.alert.priceMin || search.alert.priceMax) {
            labelList.push(`${search.alert.priceMin ? `${search.alert.priceMin}€` : ''}-${search.alert.priceMax ? `${search.alert.priceMax}€` : ''}`);
          }
          return (
            <div
              role="presentation"
              className="search-item"
              key={search.id}
              onClick={() => history.push(
                Routes.withPath(SEARCH_DETAILS, [{ label: ID, value: search.id }]),
              )}
            >
              <div className="search-item-header">
                <div className="search-item-name">
                  {search.name}
                </div>
                <div className="search-item-actions">
                  <IconButton
                    disabled={submitting}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleNotification(search.id, search.alert.active === false);
                    }}
                  >
                    {
                      search.alert.active === false
                        ? <NotificationsOffOutlined />
                        : <NotificationsOutlined />

                    }
                  </IconButton>
                  <IconButton
                    disabled={submitting}
                    onClick={(e) => {
                      e.stopPropagation();
                      removeSearch(search.id);
                    }}
                  >
                    <DeleteOutlined />
                  </IconButton>
                </div>
              </div>
              <div className="search-item-body">
                {labelList.join(', ')}
              </div>
            </div>
          );
        })
      }
      <button
        type="button"
        onClick={() => history.push(SEARCH_CREATE)}
      >
        <AddCircleOutline />{Messages.t('search.create')}
      </button>
    </div>
  );
}
