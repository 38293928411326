import React, { ReactNode } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ClipboardCopyButton from 'theme/ClipboardCopyButton';

type Props = {
  link: string,
  children: ReactNode,
};

export default function CopyLinkToClipboard({ link, children }: Props) {
  return (
    <CopyToClipboard text={link}>
      <div>
        <ClipboardCopyButton>
          {children}
        </ClipboardCopyButton>
      </div>
    </CopyToClipboard>
  );
}
