import React from 'react';
import { PropertyDetailled } from 'types/Property';
import { getFinalErrorMessage } from 'lib/form/FormUtils';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { FieldPath, UseFormStateReturn } from 'react-hook-form/dist/types';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import PropertyCard from 'pages/common/PropertyCard';
import Button from 'theme/Button';
import { UseFormTrigger } from 'react-hook-form';
import { CandidateFormType, FormField } from 'types/forms/CandidateForm';
import Messages from 'services/i18n/Messages';

type Props = {
  properties: PropertyDetailled[],
  trigger: UseFormTrigger<CandidateFormType>,
  page: FormField,
  goToNextPage: () => void
  control: {
    field: ControllerRenderProps<CandidateFormType, FieldPath<CandidateFormType>>;
    fieldState: ControllerFieldState;
    formState: UseFormStateReturn<CandidateFormType>
  }
  error: FieldErrors,
  apiErrors?: { [key: string]: string[] }
  onChange?: (value: string) => void
};

export default function PropertyPageSelector(
  {
    properties,
    control,
    trigger,
    page,
    goToNextPage,
    error,
    apiErrors,
  }: Props,
) {
  const { field } = control;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const errorMessage = getFinalErrorMessage(field.name, error, apiErrors);
  return (
    <div role="presentation" onClick={() => field.onChange(undefined)} className="property-page-selector">
      <div className="properties-container">
        {
          properties
            .sort((a, b) => (b.lastPublishAt || '').localeCompare(a.lastPublishAt || ''))
            .map((property) => (
              <div
                className="property-card-container"
                key={property.id}
              >
                <Button
                  color="secondary"
                  onClick={async (e) => {
                    e.stopPropagation();
                    field.onChange(property.id);
                    const isValid = await trigger(page.pageId);
                    if (isValid) {
                      goToNextPage();
                    }
                  }}
                  className="property-select-button"
                >
                  {Messages.t('property.select')}
                </Button>
                <PropertyCard
                  property={property}
                  checked={property.id === control.field.value}
                  selectProperty={async () => {
                    field.onChange(property.id);
                    const isValid = await trigger(page.pageId);
                    if (isValid) {
                      goToNextPage();
                    }
                  }}
                />
              </div>
            ))
        }
      </div>
    </div>
  );
}
