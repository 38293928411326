/* eslint-disable no-script-url */

import React, { useRef } from 'react';
import { Control, Controller, UseFormTrigger } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import Button from 'theme/Button';
import { CandidateFormType, FormField } from 'types/forms/CandidateForm';
import Messages from 'services/i18n/Messages';
import PhoneNumberInputWrapper from 'lib/form/PhoneNumberInputWrapper';

type Props = {
  control?: Control<CandidateFormType>,
  errors: FieldErrors
  page: FormField,
  trigger: UseFormTrigger<CandidateFormType>,
  goToNextPage: () => void,
  apiErrors: { [key: string]: string[] }
};

export default function FormQuestionPhoneNumber(
  {
    control,
    errors,
    page,
    trigger,
    apiErrors,
    goToNextPage,
  }: Props,
) {
  const fieldRef = useRef<HTMLInputElement>(null);

  return (
    <form
      className="question-container"
      action="javascript:void(0);"
      onSubmit={async () => {
        const isValid = await trigger(page.pageId);
        if (isValid) {
          if (fieldRef.current) {
            fieldRef.current.blur();
          }
          goToNextPage();
        }
      }}
    >
      <h5>
        {page.questionlabel}
      </h5>
      <Controller
        name={page.pageId}
        control={control}
        rules={page.rules}
        render={(controller) => (
          <PhoneNumberInputWrapper
            apiErrors={apiErrors}
            error={errors}
            control={controller}
            label={page.fieldLabel}
          />
        )}
      />
      <div className="form-button-container">
        <div className="validate-button">
          <Button
            onClick={async () => {
              const isValid = await trigger(page.pageId);
              if (isValid) {
                goToNextPage();
              }
            }}
          >
            {Messages.t('formButton.ok')}
          </Button>
        </div>
        {
          page.skipButton && (
            <Button onClick={() => goToNextPage()}>
              {page.skipButton}
            </Button>
          )
        }
      </div>
    </form>
  );
}
