import React from 'react';
import {
  Control,
  Controller,
  useForm,
  UseFormTrigger,
} from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { CandidateFormType, FormField } from 'types/forms/CandidateForm';
import { Property } from 'types/Property';
import PropertyPageSelector from 'pages/public-app/candidateForm/formComponents/PropertyPageSelector';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import PropertyUtils from 'services/PropertyUtils';
import Messages from 'services/i18n/Messages';
import { Search } from '@material-ui/icons';
import Button from 'theme/Button';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';

type Props = {
  control?: Control<CandidateFormType>,
  errors: FieldErrors
  trigger: UseFormTrigger<CandidateFormType>,
  page: FormField,
  setValue: UseFormSetValue<CandidateFormType>,
  goToNextPage: () => void,
  properties: Property[],
  apiErrors: { [key: string]: string[] }
};

export default function FormQuestionProperties(
  {
    control,
    errors,
    page,
    setValue,
    trigger,
    apiErrors,
    goToNextPage,
    properties,
  }: Props,
) {
  // useEffect(() => {
  //   const pressEnter = (event: KeyboardEvent) => {
  //     if (event.keyCode === 13) {
  //       event.preventDefault();
  //     }
  //   };
  //   window.addEventListener('keyup', pressEnter);
  //   return () => {
  //     window.removeEventListener('keyup', pressEnter);
  //   };
  // }, []);
  const {
    control: controlFilter,
    watch,
  } = useForm<{ filter: string }>({ defaultValues: { filter: '' } });
  const formField = watch();
  return (
    <div className="property-selector-question">
      <h5>
        {Messages.t('form.propertyId.question')}
      </h5>
      <Controller
        name="filter"
        control={controlFilter}
        render={(controller) => (
          <TextFieldWrapper
            apiErrors={apiErrors}
            error={errors}
            control={controller}
            prefix={<Search />}
            type="search"
            label={Messages.t('field.search')}
          />
        )}
      />
      <div className="no-property-container">
        <Button onClick={() => {
          goToNextPage();
          setValue(page.pageId, undefined);
        }}
        >
          {Messages.t('form.propertyId.notFound')}
        </Button>
      </div>
      <Controller
        name={page.pageId}
        control={control}
        rules={page.rules}
        render={(controller) => (
          <PropertyPageSelector
            apiErrors={apiErrors}
            trigger={trigger}
            page={page}
            goToNextPage={goToNextPage}
            error={errors}
            control={controller}
            properties={PropertyUtils.filterProperty(properties, formField.filter)}
          />
        )}
      />
    </div>
  );
}
