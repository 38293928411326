/* eslint-disable no-script-url */
import React, { useRef } from 'react';
import { Control, Controller, UseFormTrigger } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import Button from 'theme/Button';
import { CandidateFormType, FormField } from 'types/forms/CandidateForm';
import Messages from 'services/i18n/Messages';
import SpinButton from 'theme/SpinButton';

type Props = {
  control?: Control<CandidateFormType>,
  errors: FieldErrors
  page: FormField,
  trigger: UseFormTrigger<CandidateFormType>,
  goToNextPage: () => void,
  apiErrors: { [key: string]: string[] }
  spin?: boolean
};

export default function FormQuestionTextField(
  {
    control,
    errors,
    page,
    trigger,
    apiErrors,
    goToNextPage,
    spin,
  }: Props,
) {
  const fieldRef = useRef<HTMLInputElement>(null);

  return (
    <form
      className="question-container"
      action="javascript:void(0);"
      onSubmit={async () => {
        const isValid = await trigger(page.pageId);
        if (isValid) {
          if (fieldRef.current) {
            fieldRef.current.blur();
          }
          goToNextPage();
        }
      }}
    >
      <h5>
        {page.questionlabel}
      </h5>
      <Controller
        name={page.pageId}
        control={control}
        rules={page.rules}
        render={(controller) => (
          <TextFieldWrapper
            apiErrors={apiErrors}
            fieldRef={fieldRef}
            error={errors}
            type={page.fieldType || 'text'}
            control={controller}
            label={page.fieldLabel}
          />
        )}
      />
      <div className="form-button-container">
        <div className="validate-button">
          <SpinButton
            spin={!!spin}
            type="submit"
            editing={false}
            title={page.actionButton || Messages.t('formButton.ok')}
          />
        </div>
        {
          page.skipButton && (
            <Button onClick={() => goToNextPage()}>
              {page.skipButton}
            </Button>
          )
        }
      </div>
    </form>
  );
}
