import {
  CandidateFormType,
  FormField, garantProSituationTypeEnum,
  garantTypeEnum,
} from 'types/forms/CandidateForm';
import Messages from 'services/i18n/Messages';

export const getGarantFields = (garantIndex: number, formField: CandidateFormType): FormField[] => (
  [
    {
      pageId: `garants.${garantIndex}.type`,
      questionlabel: Messages.t('form.type.question'),
      fieldLabel: Messages.t('form.type.label'),
      rules: { required: true },
      type: 'select',
      selectOption: Object.values(garantTypeEnum).map((key) => ({
        key,
        label: Messages.t(`garantType.${key}`),
      })),
    },
    {
      pageId: `garants.${garantIndex}.firstname`,
      questionlabel: Messages.t('form.firstname.question'),
      fieldLabel: Messages.t('form.firstname.label'),
      type: 'textField',
      rules: { required: true },
      condition: (formfield) => !!formfield.garants?.[garantIndex]?.type
        && formfield.garants[garantIndex].type === garantTypeEnum.PERSON,
    },
    {
      pageId: `garants.${garantIndex}.lastname`,
      questionlabel: Messages.t('form.lastname.question'),
      fieldLabel: Messages.t('form.lastname.label'),
      type: 'textField',
      rules: {
        required: true,
        validate: () => {
          const garanName = `${formField.garants[garantIndex].firstname} ${formField.garants[garantIndex].lastname}`;
          if (!formField.garants
            .every((garant, idx) => garantIndex === idx || garanName !== `${garant.firstname} ${garant.lastname}`)
          ) {
            return Messages.t('form.error.GarantDuplicateName');
          }
          return undefined;
        },
      },
      condition: (formfield) => !!formfield.garants?.[garantIndex]?.type
        && formfield.garants[garantIndex].type === garantTypeEnum.PERSON,
    },
    // {
    //   pageId: `garants.${garantIndex}.email`,
    //   questionlabel: 'Email',
    //   fieldLabel: 'Email',
    //   type: 'textField',
    //   condition: (formfield) => !!formfield.garants?.[garantIndex]?.type
    //     && formfield.garants[garantIndex].type === garantTypeEnum.PERSON,
    // },
    // {
    //   pageId: `garants.${garantIndex}.phoneNumber`,
    //   questionlabel: 'Numéro de téléphone',
    //   fieldLabel: 'Numéro de téléphone',
    //   type: 'textField',
    //   condition: (formfield) => !!formfield.garants?.[garantIndex]?.type
    //     && formfield.garants[garantIndex].type === garantTypeEnum.PERSON,
    // },
    {
      pageId: `garants.${garantIndex}.situation`,
      questionlabel: Messages.t('form.situation.question'),
      fieldLabel: Messages.t('form.situation.label'),
      type: 'select',
      condition: (formfield) => !!formfield.garants?.[garantIndex]?.type
        && formfield.garants[garantIndex].type === garantTypeEnum.PERSON,
      multiSelect: true,
      rules: { required: true },
      selectOption: Object.values(garantProSituationTypeEnum).map((key) => ({
        key,
        label: Messages.t(`professionalSituation.${key}`),
      })),
    },
    // {
    //   pageId: `garants.${garantIndex}.leaseType`,
    //   questionlabel: 'Type de contrat',
    //   fieldLabel: 'Type de contrat',
    //   condition: (formfield) => !!formfield.garants?.[garantIndex]?.type
    //     && formfield.garants[garantIndex].type === garantTypeEnum.PERSON
    //     && formfield.garants?.[garantIndex]?.situation
    //     && formfield.garants[garantIndex].situation?.includes(proSituationTypeEnum.EMPLOYED),
    //   type: 'select',
    //   selectOption: Object.values(contractTypeTypeEnum).map((key) => ({
    //     key,
    //     label: Messages.t(`contractType.${key}`),
    //   })),
    // },
    // {
    //   pageId: `garants.${garantIndex}.trialPeriode`,
    //   questionlabel: 'Période d\'essai validée ?',
    //   fieldLabel: 'Réponse',
    //   condition: (formfield) => !!formfield.garants?.[garantIndex]?.type
    //     && formfield.garants[garantIndex].type === garantTypeEnum.PERSON
    //     && formfield.garants?.[garantIndex]?.situation
    //     && formfield.garants[garantIndex].situation?.includes(proSituationTypeEnum.EMPLOYED),
    //   type: 'select',
    //   selectOption: [
    //     { key: 'true', label: Messages.t('generics.YES') },
    //     { key: 'false', label: Messages.t('generics.NO') }],
    // },
    // {
    //   pageId: `garants.${garantIndex}.profession`,
    //   questionlabel: 'Profession',
    //   fieldLabel: 'Profession',
    //   condition: (formfield) => !!formfield.garants?.[garantIndex]?.type
    //     && formfield.garants[garantIndex].type === garantTypeEnum.PERSON
    //     && formfield.garants?.[garantIndex]?.situation
    //     && (
    //       formfield.garants[garantIndex].situation?.includes(proSituationTypeEnum.EMPLOYED)
    //       || formfield.garants[garantIndex].situation?.includes(proSituationTypeEnum.FREELANCER)
    //       || formfield.garants[garantIndex].situation?.includes(proSituationTypeEnum.OFFICIAL)
    //     ),
    //   type: 'textField',
    // },
    {
      pageId: `garants.${garantIndex}.monthlyIncome`,
      questionlabel: Messages.t('form.monthlyIncome.question'),
      fieldLabel: Messages.t('form.monthlyIncome.label'),
      fieldType: 'number',
      rules: { required: true },
      condition: (formfield) => !!formfield.garants?.[garantIndex]?.type
        && formfield.garants[garantIndex].type === garantTypeEnum.PERSON,
      type: 'textField',
    },
    {
      pageId: `garants.${garantIndex}.name`,
      questionlabel: Messages.t('form.name.question'),
      fieldLabel: Messages.t('form.name.label'),
      rules: {
        required: true,

        validate: (name) => {
          if (name && !formField.garants
            .every((garant, idx) => garantIndex === idx || name !== garant.name)
          ) {
            return Messages.t('form.error.GarantDuplicate');
          }
          return undefined;
        },
      },
      condition: (formfield) => !!formfield.garants?.[garantIndex]?.type
        && formfield.garants[garantIndex].type !== garantTypeEnum.PERSON
        && formfield.garants[garantIndex].type !== garantTypeEnum.ENTERPRISE,
      type: 'textField',
    },
    {
      pageId: `garants.${garantIndex}.name`,
      questionlabel: Messages.t('form.nameEnterprise.question'),
      fieldLabel: Messages.t('form.name.label'),
      rules: {
        required: true,

        validate: (name) => {
          if (name && !formField.garants
            .every((garant, idx) => garantIndex === idx || name !== garant.name)
          ) {
            return Messages.t('form.error.GarantDuplicate');
          }
          return undefined;
        },
      },
      condition: (formfield) => !!formfield.garants?.[garantIndex]?.type
        && formfield.garants[garantIndex].type === garantTypeEnum.ENTERPRISE,
      type: 'textField',
    },
    {
      pageId: `garants.${garantIndex}.amount`,
      questionlabel: Messages.t('form.amount.question'),
      fieldLabel: Messages.t('form.amount.label'),
      fieldType: 'number',
      rules: { required: true },
      condition: (formfield) => !!formfield.garants?.[garantIndex]?.type
        && (
          formfield.garants[garantIndex].type === garantTypeEnum.BANK
          || formfield.garants[garantIndex].type === garantTypeEnum.INSURANCE
        ),
      type: 'textField',
      skipButton: Messages.t('formButton.notSure'),
    },
  ]
);
