import React, { useState } from 'react';
import imagePlaceHolder from 'assets/images/imagePlaceholder.svg';
import { useFilesBackend } from 'network/queries/FileQueries';

type Props = {
  src?: string
  alt: string
  onError?: () => void
};

export default function Image({ src, alt, onError }: Props) {
  const { getFile } = useFilesBackend();
  const { data: fileUrl } = getFile(src || '', !!src);
  const [hasError, setHasError] = useState(false);
  if (hasError || !src) {
    return (<img src={imagePlaceHolder} alt={alt} />);
  }
  return (
    <img
      onError={() => {
        setHasError(true);
        if (onError) {
          onError();
        }
      }}
      src={fileUrl}
      alt={alt}
    />
  );
}
