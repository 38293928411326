import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Routes } from 'routes/RoutesUtils';
import { APPLICATION_DOCUMENT, ID } from 'routes/Routes';
import {
  DialogActions,
  DialogContent,
  DialogTitle, IconButton, Menu, MenuItem,
} from '@material-ui/core';
import {
  Edit,
  LinkOff,
  MoreVert,
  Warning,
} from '@material-ui/icons';
import Messages from 'services/i18n/Messages';
import Button from 'theme/Button';
import { DocumentGroup } from 'types/DocumentGroup';
import levenshteinDistance from 'lib/LevenshteinDistance';
import { AssignToDocumentGroup } from 'types/forms/AssignToDocumentGroup';
import { NotificationService } from 'lib/notification';
import { useCandidatureBackend } from 'network/queries/CandidatureQueries';
import { CandidatureShort } from 'types/Candidature';
import CandidatureUtils from 'services/CandidatureUtils';
import { detectMobile } from 'services/utils';
import DocumentUtils from 'services/DocumentUtils';
import CircularProgressBar from 'pages/common/CircularProgressBar';
import DialogWrapper from 'pages/common/DialogWrapper';

type Props = {
  candidatureId: string,
  potentialDocumentGroups?: DocumentGroup[]
  onAssign: (name: string, documentGroupId: string | undefined, manuallyAssigned: boolean) => void
  name: string,
  documentGroup?: DocumentGroup,
  disabled?: boolean,
  contactId?: string,
  garantId?: string,
  disableDelete?: boolean,
  manuallyAssigned?: boolean,
  isShared?: boolean,
  candidatureWithPontentialId: CandidatureShort,
};

export default function ContactItem(
  {
    candidatureId,
    name,
    documentGroup,
    onAssign,
    disabled,
    potentialDocumentGroups,
    contactId,
    garantId,
    manuallyAssigned,
    candidatureWithPontentialId,
    isShared,
    disableDelete,
  }: Props,
) {
  const candidaturesQuery = useCandidatureBackend();
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const isMobile = detectMobile();

  const { unlinkDocumentGroup, deleteContact, deleteGarant } = candidaturesQuery;
  const unlinkGroup = (data: AssignToDocumentGroup) => {
    setSubmitting(true);
    unlinkDocumentGroup.mutateAsync({ data, candidatureId })
      .then(() => {
        NotificationService.notifySuccess(Messages.t('notifications.update'));
      })
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => setSubmitting(false));
  };

  const deleteItem = () => {
    if (contactId) {
      setSubmitting(true);
      deleteContact.mutateAsync({ contactId, candidatureId })
        .then(() => {
          NotificationService.notifySuccess(Messages.t('notifications.update'));
        })
        .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
        .finally(() => setSubmitting(false));
    } else if (garantId) {
      setSubmitting(true);
      deleteGarant.mutateAsync({ garantId, candidatureId })
        .then(() => {
          NotificationService.notifySuccess(Messages.t('notifications.update'));
        })
        .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
        .finally(() => setSubmitting(false));
    }
  };

  const getContactItem = () => {
    if (documentGroup) {
      const conmpletionPercent = DocumentUtils.getCompletionPercentFromTagGroup(documentGroup);
      return (
        <Link
          className="contact-item"
          to={{
            pathname: Routes.withPath(
              APPLICATION_DOCUMENT,
              [{ label: ID, value: documentGroup?.id || '' }],
            ),
            state: {
              fromCandidatureId: candidatureId,
              otherDocumentAreFull: CandidatureUtils
                .otherDocumentAreFull(candidatureWithPontentialId, documentGroup?.id || ''),
            },
          }}
        >
          <div className="progress-container">
            <div className="progress-and-name">
              <CircularProgressBar percent={conmpletionPercent} />
              <div className="contact-name">
                {name}
              </div>
            </div>
            {
              !isMobile ? (
                <Button
                  className="contact-button"
                  color="secondary"
                  disabled={disabled}
                >
                  {
                    conmpletionPercent >= 100 ? (
                      Messages.t('document.update')
                    ) : (
                      Messages.t('document.fill')
                    )
                  }

                </Button>
              ) : (
                <IconButton className="contact-button" disabled={disabled || submitting}>
                  <Edit />
                </IconButton>
              )
            }
            {
              documentGroup && manuallyAssigned && (
                <IconButton
                  disabled={submitting}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    unlinkGroup({
                      garantId,
                      contactId,
                      documentGroupId: documentGroup?.id,
                    });
                  }}
                >
                  <LinkOff />
                </IconButton>
              )
            }
          </div>
        </Link>
      );
    }
    if (!potentialDocumentGroups
      || potentialDocumentGroups.length === 0) {
      return (
        <div
          role="presentation"
          className="contact-item"
          onClick={() => onAssign(
            name,
            undefined,
            false,
          )}
        >
          <div className="progress-container">
            <div className="progress-and-name">
              <CircularProgressBar percent={0} />
              <div className="contact-name">
                {name}
              </div>
            </div>
            {
              !isMobile ? (
                <Button
                  className="contact-button"
                  color="secondary"
                  disabled={disabled || submitting}
                >
                  {Messages.t('document.fill')}
                </Button>
              ) : (
                <IconButton className="contact-button" disabled={disabled || submitting}>
                  <Edit />
                </IconButton>
              )
            }
          </div>
        </div>
      );
    }
    return (
      <>
        {
          potentialDocumentGroups && showAssignModal && (
            <DialogWrapper open onClose={() => setShowAssignModal(false)}>
              <DialogTitle>{Messages.t('case.select')}</DialogTitle>
              <DialogContent>
                {
                  potentialDocumentGroups.sort(
                    (a, b) => (
                      levenshteinDistance(a.name.split('@')[0].toLowerCase(), name.split('@')[0].toLowerCase())
                      - levenshteinDistance(b.name.split('@')[0].toLowerCase(), name.split('@')[0].toLowerCase())),
                  ).map((document) => (
                    <div key={document.id}>
                      <button
                        className="case-selector"
                        type="button"
                        onClick={() => {
                          setShowAssignModal(false);
                          onAssign(
                            name,
                            document.id,
                            true,
                          );
                        }}
                      >
                        {document.name}
                      </button>
                    </div>
                  ))
                }
                <Button
                  onClick={() => {
                    setShowAssignModal(false);
                    onAssign(
                      name,
                      undefined,
                      false,
                    );
                  }}
                >
                  {Messages.t('case.create')}
                </Button>
              </DialogContent>
              <DialogActions>
                <Button
                  color="secondary"
                  onClick={() => setShowAssignModal(false)}
                >
                  {Messages.t('formButton.cancel')}
                </Button>
              </DialogActions>
            </DialogWrapper>
          )
        }
        <div role="presentation" onClick={() => setShowAssignModal(!submitting)} className="contact-item">
          <div className="progress-and-name">
            <div className="icon-warning">
              <Warning />
            </div>
            <div className="contact-name">
              {name}
            </div>
          </div>
          {
            !isMobile ? (
              <Button
                className="contact-button"
                color="secondary"
                disabled={disabled || submitting}
                onClick={() => setShowAssignModal(!submitting)}
              >
                {Messages.t('document.select')}
              </Button>
            ) : (
              <IconButton
                className="contact-button"
                disabled={disabled || submitting}
                onClick={() => setShowAssignModal(!submitting)}
              >
                <Edit />
              </IconButton>
            )
          }
        </div>
      </>
    );
  };

  return (
    <div className="contact-item-container">
      <div className="contact-item-content">
        {
          getContactItem()
        }
        <IconButton onClick={handleClick}>
          <MoreVert />
        </IconButton>
      </div>
      {
        !isShared && (
          <div className="candidature-tag-container">
            <div className="candidature-tag">
              <div className="icon-warning">
                <Warning />
              </div>
              {
                contactId ? (
                  <p>
                    {Messages.t('document.notShared.contact')}
                  </p>
                ) : (
                  <p>
                    {Messages.t('document.notShared.garant')}
                  </p>
                )
              }
            </div>
          </div>
        )
      }
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={deleteItem}
          className="danger-item"
          disabled={disableDelete || submitting}
        >
          {Messages.t('contact.remove')}
        </MenuItem>
      </Menu>
    </div>
  );
}
