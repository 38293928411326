import React, { useEffect, useState } from 'react';
import { Coordinates } from 'lib/googleMap/GoogleMap';
import withMap from 'lib/googleMap/Withmap';
/* global google */

type Props = {
  radius: number
  coordinates: Coordinates,
  map: google.maps.Map,
  option?: {
    strokeColor?: string,
    strokeOpacity?: number,
    strokeWeight?: number,
    fillColor?: string,
    fillOpacity?: number,
  }
  onClick?: () => void,
};

function GoogleCircle(
  {
    radius,
    map,
    coordinates,
    onClick,
    option,
  }: Props,
) {
  const [circle, setCircle] = useState<google.maps.Circle | null>(null);

  const addCircle = () => {
    if (circle) {
      return null;
    }
    const newCircle = new google.maps.Circle({
      ...option,
      map,
      center: coordinates,
      radius,
    });
    setCircle(newCircle);
    newCircle.addListener('click', () => {
      if (onClick) {
        onClick();
      }
    });
    return newCircle;
  };
  useEffect(() => {
    const newCircle = addCircle();
    return () => {
      if (newCircle) {
        newCircle.setMap(null);
      }
    };
  }, []);

  useEffect(() => {
    circle?.setRadius(radius);
    circle?.setCenter(coordinates);
  }, [radius, coordinates]);

  return (
    <></>
  );
}

export default withMap(GoogleCircle);
