import React, { useEffect, useState } from 'react';
import { useDocumentBackend } from 'network/queries/DocumentQueries';
import InformationsForm from 'pages/common/InformationsForm';
import GarantInformationsForm from 'pages/common/GarantInformationsForm';
import Messages from 'services/i18n/Messages';
import Button from 'theme/Button';

type Props = {
  id: string
  contactList: string[]
};

export default function Informations({ id, contactList }: Props) {
  const documentQueries = useDocumentBackend();
  const { getDocumentGroupContact } = documentQueries;
  const { data: documentGroupContact } = getDocumentGroupContact(id);
  const [createGarant, setCreateGarant] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    setCreateGarant(undefined);
  }, []);

  if (!documentGroupContact) {
    return <div>Loading...</div>;
  }
  if (documentGroupContact.contact) {
    return (
      <InformationsForm
        contactList={contactList}
        contact={documentGroupContact.contact}
        id={id}
      />
    );
  }
  if (documentGroupContact.garant) {
    return (
      <GarantInformationsForm
        contactList={contactList}
        garant={documentGroupContact.garant}
        id={id}
      />
    );
  }
  if (createGarant) {
    return (
      <GarantInformationsForm
        contactList={contactList}
        id={id}
      />
    );
  }
  if (createGarant === false) {
    return (
      <InformationsForm
        contactList={contactList}
        id={id}
      />
    );
  }
  return (
    <div className="page-body select-type-page">
      <h3>
        {Messages.t('application.type')}
      </h3>
      <div className="application-create-buttons">
        <Button color="secondary" onClick={() => setCreateGarant(false)}>{Messages.t('occupants.field')}</Button>
        <Button color="secondary" onClick={() => setCreateGarant(true)}>{Messages.t('garants.field')}</Button>
      </div>
    </div>
  );
}
