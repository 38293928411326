import React, { useEffect } from 'react';
import { SearchSortType, sortTypeEnum } from 'types/SearchType';
import { Controller, useForm } from 'react-hook-form';
import RadioWrapper from 'lib/form/RadioWrapper';
import Messages from 'services/i18n/Messages';

type Props = {
  searchSort: SearchSortType
  setSearchSort: (sort: SearchSortType) => void
};

export default function SearchSort({ searchSort, setSearchSort }: Props) {
  const {
    control,
    watch,
    formState: { errors },
  } = useForm<SearchSortType>({
    defaultValues: searchSort,
  });

  const formField = watch();

  useEffect(() => {
    setSearchSort(formField);
  }, [formField.field]);

  return (
    <div className="search-sort">
      <h3>{Messages.t('search.sort.results')}</h3>
      <Controller
        name="field"
        control={control}
        render={(controller) => (
          <RadioWrapper
            control={controller}
            error={errors}
            row
            hideButton
            className="radio-list"
            values={
              Object.values(sortTypeEnum).map((key) => (
                { key, label: Messages.t(`search.sort.${key}`) }
              ))
            }
          />
        )}
      />
    </div>
  );
}
