import React, { useMemo, useState } from 'react';
import { useCandidatureBackend } from 'network/queries/CandidatureQueries';
import { useParams } from 'react-router-dom';
import CandidatureUtils from 'services/CandidatureUtils';
import { CandidatureShort } from 'types/Candidature';
import CaseDetails from 'pages/client-app/candidature/candidatureDetails/CaseDetails';
import { DialogContent, DialogTitle } from '@material-ui/core';
import Messages from 'services/i18n/Messages';
import MotivationModalForm from 'pages/client-app/candidature/candidatureDetails/MotivationModalForm';
import { documentStatusTypeEnum } from 'types/CandidatureBase';
import Button from 'theme/Button';
import confirmationService from 'services/ConfirmationService';
import { AssignToDocumentGroup } from 'types/forms/AssignToDocumentGroup';
import ContactUtils from 'services/ContactUtils';
import GarantUtils from 'services/GarantUtils';
import { useDocumentBackend } from 'network/queries/DocumentQueries';
import { NotificationService } from 'lib/notification';
import { Warning } from '@material-ui/icons';
import DateUtils from 'services/DateUtils';
import DialogWrapper from 'pages/common/DialogWrapper';
import { VisitDetailed } from 'types/Visit';
import { getContactsDocGroupId } from 'services/utils';
import CandidatureDetailsActions from './CandidatureDetailsActions';

type Param = {
  id: string,
};

type Props = {
  visits: VisitDetailed[],
};

export default function CandidatureDetails({ visits }: Props) {
  const [motivationModal, setMotivationModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { id } = useParams<Param>();
  const candidaturesQuery = useCandidatureBackend();
  const documentsQueries = useDocumentBackend();
  const { getCandidatures, shareDocumentGroup } = candidaturesQuery;
  const { getDocumentGroups } = documentsQueries;
  const { data: candidaturesData } = getCandidatures();
  const { data: documentGroups } = getDocumentGroups();

  const openVisits = visits.filter((visit) => !visit.isCancel);

  const candidatureData: CandidatureShort | undefined = candidaturesData?.filter(
    (candidature) => candidature.id === id,
  )[0];

  const candidatureWithPontentialId = useMemo(() => (candidatureData ? ({
    ...candidatureData,
    contacts: candidatureData.contacts.map((contact) => ({
      ...contact,
      potentialDocumentGroup: CandidatureUtils.getMatchingContactDocumentGroup(
        contact,
        candidaturesData,
      ),
    })),
    garants: candidatureData.garants?.map((garant) => ({
      ...garant,
      potentialDocumentGroup: CandidatureUtils.getMatchingGarantDocumentGroup(
        garant,
        candidaturesData,
      ),
    })),
  }) : undefined), [candidatureData, candidaturesData?.length, candidatureData?.updatedAt]);

  const allContactsDocGroupId = getContactsDocGroupId(candidaturesData);

  const potentialDocumentGroups = documentGroups?.filter((doc) => ((
    ((
      candidatureWithPontentialId?.contacts
        .filter((contact) => contact.documentGroupID === doc.id
          || contact.potentialDocumentGroup?.documentGroupID === doc.id)
        .length || 0) === 0)
    && ((allContactsDocGroupId?.includes(doc.id)
      || (doc.originalContactID || doc.originalGarantID)))
    && ((
      candidatureWithPontentialId?.garants?.filter((garant) => garant.documentGroupID === doc.id
        || garant.potentialDocumentGroup?.documentGroupID === doc.id)
        .length || 0
    ) === 0))));

  const shareDocumentGroups = (assignList: AssignToDocumentGroup[]) => {
    setSubmitting(true);
    return shareDocumentGroup.mutateAsync({ data: assignList, candidatureId: candidatureData?.id || '' })
      .then(() => {
        NotificationService.notifySuccess(Messages.t('notifications.update'));
      })
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => setSubmitting(false));
  };

  const submitAll = async () => {
    if (!candidatureWithPontentialId) {
      throw Error();
    }
    if (CandidatureUtils.computeCompletion(candidatureWithPontentialId) < 100) {
      const isConfirmed = await confirmationService.confirm({
        title: Messages.t('case.incomplete'),
        message: Messages.t('case.incomplete.message'),
        actionMessage: Messages.t('case.incomplete.send'),
        actionColor: 'secondary',
        cancelMessage: Messages.t('case.incomplete.fill'),
        inverseButton: true,
      });
      if (!isConfirmed) {
        throw Error();
      }
    }
    let resList: AssignToDocumentGroup[] = [];
    resList = resList.concat(candidatureWithPontentialId
      .contacts
      .filter((contact) => contact.documentGroupID
        || contact.potentialDocumentGroup?.documentGroupID
        || potentialDocumentGroups?.length === 0)
      .map((contact) => ({
        contactId: contact.id,
        name: ContactUtils.getDisplayedName(contact, 0),
        documentGroupId: contact.documentGroupID || contact.potentialDocumentGroup?.documentGroupID
        ,
      })));
    resList = resList.concat(candidatureWithPontentialId
      .garants?.filter((garant) => garant.documentGroupID
        || garant.potentialDocumentGroup?.documentGroupID
        || potentialDocumentGroups?.length === 0)
      .map((garant) => ({
        garantId: garant.id,
        name: GarantUtils.getDisplayedName(garant),
        documentGroupId: garant.documentGroupID || garant.potentialDocumentGroup?.documentGroupID,
      })) || []);
    return shareDocumentGroups(resList);
  };

  return (
    <>
      {
        candidatureWithPontentialId && (
          <div className="selector-and-viewer contact-selector">
            <CaseDetails
              candidatureWithPontentialId={candidatureWithPontentialId}
              candidatureData={candidatureData}
              id={id}
              submitting={submitting}
              setSubmitting={setSubmitting}
              potentialDocumentGroups={potentialDocumentGroups}
            />
          </div>
        )
      }
      {
        candidatureData && candidatureData.propertyID && ((
          !CandidatureUtils.isShared(candidatureData)
          || !candidatureData.documentsStatus
          || candidatureData.documentsStatus === documentStatusTypeEnum.NOT_ASKED
          || candidatureData.documentsStatus === documentStatusTypeEnum.ASKED
          || candidatureData.documentsStatus === documentStatusTypeEnum.REMINDED
        )
          ? (
            <Button
              onClick={() => submitAll()?.then(() => {
                setMotivationModal(true);
              }).catch(() => {
              })}
            >
              {Messages.t('case.share')}
            </Button>
          ) : (
            <div className="candidature-info-tag">
              {Messages.t('case.automaticShared')}
            </div>
          ))
      }
      {
        candidatureData && candidatureData.propertyID && (
          CandidatureUtils.isShared(candidatureData)
          && candidatureData.documentsStatus === documentStatusTypeEnum.MISSING
        ) && (
          <div className="content incomplete">
            <div className="info-msg">
              <div className="msg-warning">
                <Warning color="warning" />
                <p>{Messages.t('case.documentsRequested')}</p>
              </div>
              <div className="agency-comment">
                <div className="comment-header">
                  <b>{candidatureData.agency.name} :</b>
                  <small>
                    {DateUtils.getPrettyDateDeltaFromNow(
                      candidatureData?.documentsMessageLastUpdatedAt,
                    )}
                  </small>
                </div>
                <p><em>&quot;{candidatureData?.documentsMessage}&quot;</em></p>
              </div>
              <Button
                onClick={() => submitAll().catch(() => {
                })}
              >
                {Messages.t('case.complete')}
              </Button>
            </div>
          </div>
        )
      }
      {
        candidatureData && candidatureData.propertyID && candidaturesData && motivationModal && (
          <DialogWrapper open onClose={() => setMotivationModal(false)}>
            <DialogTitle>{Messages.t('document.congrat')}</DialogTitle>
            <DialogContent>
              <MotivationModalForm
                onClose={() => setMotivationModal(false)}
                candidature={candidatureData}
                candidatureList={candidaturesData}
              />
            </DialogContent>
          </DialogWrapper>
        )
      }
      {
        candidatureData?.propertyID && (
          <CandidatureDetailsActions
            candidatureData={candidatureData}
            openVisits={openVisits}
            setSubmitting={setSubmitting}
            submitting={submitting}
          />
        )
      }
    </>
  );
}
