import React, { useState } from 'react';
import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Messages from 'services/i18n/Messages';
import Button from 'theme/Button';
import { CandidatureShort } from 'types/Candidature';

import CandidatureItem from 'pages/client-app/candidature/candidatureList/CandidatureItem';
import InformationsForm from 'pages/common/InformationsForm';
import GarantInformationsForm from 'pages/common/GarantInformationsForm';
import DialogWrapper from 'pages/common/DialogWrapper';

type Props = {
  onClose: () => void
  candidaturesData: CandidatureShort[]
};

export default function ApplicationCreation({ onClose, candidaturesData }: Props) {
  const [candidatureId, setCandidatureId] = useState<string | undefined>(undefined);
  const [isGarant, setIsGarant] = useState<boolean | undefined>(undefined);

  return (
    <>
      {
        isGarant === undefined && (
          <DialogWrapper open onClose={onClose}>
            <DialogTitle>{Messages.t('application.type')}</DialogTitle>
            <DialogContent>
              <div className="application-create-buttons">
                <Button color="secondary" onClick={() => setIsGarant(false)}>{Messages.t('occupants.field')}</Button>
                <Button color="secondary" onClick={() => setIsGarant(true)}>{Messages.t('garants.field')}</Button>
              </div>
            </DialogContent>
          </DialogWrapper>
        )
      }
      {
        isGarant !== undefined && !candidatureId && (
          <DialogWrapper open onClose={onClose}>
            <DialogTitle>{Messages.t('candidate.choose')}</DialogTitle>
            <DialogContent>
              <div className="application-create-buttons">
                {
                  candidaturesData
                    .sort((a, b) => (b.createdAt || '').localeCompare(a.createdAt || ''))
                    .map((candidature) => (
                      <button
                        type="button"
                        className="agency-card"
                        key={candidature.id}
                        onClick={() => setCandidatureId(candidature.id)}
                      >
                        <CandidatureItem visits={[]} candidature={candidature} />
                      </button>
                    ))
                }
              </div>
            </DialogContent>
            <DialogActions>
              <Button>{Messages.t('formButton.confirm')}</Button>
            </DialogActions>
          </DialogWrapper>
        )
      }
      {
        isGarant === false && candidatureId && (
          <DialogWrapper
            open
            onClose={onClose}
          >
            <DialogTitle>{Messages.t('contacts.create')}</DialogTitle>
            <DialogContent>
              <InformationsForm candidatureId={candidatureId} />
            </DialogContent>
          </DialogWrapper>
        )
      }
      {
        isGarant && candidatureId && (
          <DialogWrapper
            open
            onClose={onClose}
          >
            <DialogTitle>{Messages.t('garants.create')}</DialogTitle>
            <DialogContent>
              <GarantInformationsForm candidatureId={candidatureId} />
            </DialogContent>
          </DialogWrapper>
        )
      }
    </>
  );
}
