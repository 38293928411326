import React, { useState } from 'react';
import Messages from 'services/i18n/Messages';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { NotificationService } from 'lib/notification';
import { userApi } from 'network/api/UserApi';
import { passwordRegex } from 'lib/form/FormUtils';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import SpinButton from 'theme/SpinButton';

export default function PasswordSettings() {
  const [apiErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<{ password: string, passwordConfirm: string }>({});

  const formField = watch();

  const onSubmit: SubmitHandler<{ password: string }> = (formData: { password: string }) => {
    setSubmitting(true);
    userApi.updatePassword(formData)
      .then(() => {
        NotificationService.notifySuccess(Messages.t('notifications.update'));
      })
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className="page-body page-base settings-page">
      <h3>{Messages.t('navbar.parameters')}</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="password"
          control={control}
          rules={{
            required: true,
            pattern: {
              value: passwordRegex,
              message: Messages.t('form.error.password'),
            },
          }}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              error={errors}
              type="password"
              control={controller}
              label={Messages.t('field.passwordChange')}
            />
          )}
        />
        <Controller
          name="passwordConfirm"
          control={control}
          rules={{
            required: true,
            validate: (v) => v === formField.password || Messages.t('field.notMatchingPassword'),
          }}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              error={errors}
              type="password"
              control={controller}
              label={Messages.t('field.passwordConfirm')}
            />
          )}
        />
        <SpinButton
          editing
          spin={submitting}
          title={Messages.t('formButton.modify')}
        />
      </form>
    </div>
  );
}
