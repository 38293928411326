import React, { ReactNode, useCallback, useEffect } from 'react';
import withMap from 'lib/googleMap/Withmap';
import createHtmlMapOverlay from 'lib/googleMap/HtmlMapOverlay';
import { Coordinates } from 'lib/googleMap/GoogleMap';
/* global google */

type Props = {
  center: Coordinates,
  map: google.maps.Map,
  children: ReactNode,
  close?: () => void
  isOpen?: boolean,
};

function GoogleDrawOverlay(
  {
    map,
    center,
    children,
    close,
    isOpen,
  }: Props,
) {
  const overlayRef = React.useRef<any | null>(null);

  const setOverlay = (overlay: any | null) => {
    overlayRef.current = overlay;
  };

  const divRef = React.useRef<HTMLDivElement | null>(null);

  const ref = useCallback((node: HTMLDivElement | null) => {
    divRef.current = node;
    createOverlay();
    // @ts-ignore
  }, []);

  useEffect(() => {
    createOverlay();
  }, [isOpen]);

  useEffect(() => {
    if (close) {
      map.addListener('click', () => {
        if (overlayRef.current) {
          overlayRef.current.remove();
        }
        close();
      });
    }
  }, []);

  const createOverlay = () => {
    if (isOpen && divRef.current) {
      setOverlay(createHtmlMapOverlay({
        map,
        latlng: new google.maps.LatLng(center),
        div: divRef.current,
      }));
    }
  };

  return (
    <div ref={ref}>
      {children}
    </div>
  );
}

export default withMap(GoogleDrawOverlay);
