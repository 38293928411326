import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  SEARCH_DETAILS, SEARCH_DETAILS_SHORTLIST, SEARCH_PROPERTY,
  SEARCHES,
} from 'routes/Routes';
import Search from 'pages/client-app/search/Search';
import SearchListPage from 'pages/client-app/search/SearchListPage';
import SearchShortlist from 'pages/client-app/search/SearchShortlist';
import PropertyDetailsPage from 'pages/properties/PropertyDetailsPage';

export default function SearchRouter() {
  return (
    <Switch>
      <Route path={SEARCH_DETAILS_SHORTLIST} component={SearchShortlist} />
      <Route path={SEARCH_PROPERTY} component={PropertyDetailsPage} />
      <Route path={SEARCH_DETAILS} component={Search} />
      <Route path={SEARCHES} component={SearchListPage} />
      <Route render={() => <Redirect to={SEARCHES} />} />
    </Switch>
  );
}
