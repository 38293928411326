import { DocumentSection, documentSection, DocumentTag } from 'types/DocumentData';
import DocumentSectionRow
  from 'pages/client-app/client-application/documents/documentDetails/displayDoc/DocumentSectionRow';
import DocumentTagViewer
  from 'pages/client-app/client-application/documents/documentDetails/displayDoc/DocumentTagViewer';
import DialogWrapper from 'pages/common/DialogWrapper';
import React, { useEffect, useState } from 'react';
import { DocumentGroup } from 'types/DocumentGroup';
import { detectMobile } from 'services/utils';
import { useDocumentBackend } from 'network/queries/DocumentQueries';

type Props = {
  documentGroup: DocumentGroup
  id: string,
};

export default function DisplayDocPageContent(
  {
    documentGroup,
    id,
  }: Props,
) {
  const documentQueries = useDocumentBackend();
  const { getDocuments } = documentQueries;
  const mobileMode = detectMobile();
  const { data: documents } = getDocuments(id);

  const firstSection = Object.keys(documentSection)
    .filter(
      (section) => documentSection[section]
        .some((tag) => documentGroup?.possibleTags?.includes(tag)),
    )[0];

  const [selectedTag, setSelectedTag] = useState<DocumentTag | null>(
    (documentGroup && !mobileMode) ? (
      documentSection[firstSection].filter((tag) => documentGroup?.possibleTags?.includes(tag))[0]
    ) : null,
  );

  useEffect(() => {
    if (!documentGroup || mobileMode) {
      return;
    }
    setSelectedTag(documentSection[firstSection]
      .filter((tag) => documentGroup?.possibleTags?.includes(tag))[0]);
  }, [documentGroup?.id]);

  const documentsByTags = documents?.reduce(
    (acc, value) => {
      const tags = value.tags.split(',');
      for (let i = 0; i < tags.length; i++) {
        const tag = tags[i];
        if (!acc[tag]) {
          acc[tag] = [];
        }
        acc[tag].push(value);
      }
      return acc;
    },
    {},
  ) || {};

  return (
    <>
      <div className="document-row-container">
        <div className="selector-and-viewer document-selector">
          {
            documentGroup && Object.keys(documentSection)
              .filter((section) => documentSection[section]
                .some((tag) => documentGroup.possibleTags?.split(',').includes(tag)))
              .map((section) => (
                <DocumentSectionRow
                  key={section}
                  section={section as DocumentSection}
                  documentsByTags={documentsByTags}
                  documentGroup={documentGroup}
                  selectedTag={selectedTag}
                  setSelectedTag={setSelectedTag}
                />
              ))
          }
        </div>
        {documentGroup && selectedTag && !mobileMode && (
          <DocumentTagViewer
            tag={selectedTag}
            documentsByTags={documentsByTags}
            documentGroupId={documentGroup.id}
            onClose={() => {
            }}
          />
        )}
      </div>
      {
        mobileMode && selectedTag && documentsByTags && documentGroup && (
          <DialogWrapper open onClose={() => setSelectedTag(null)}>
            <DocumentTagViewer
              tag={selectedTag}
              documentsByTags={documentsByTags}
              documentGroupId={documentGroup.id}
              onClose={() => setSelectedTag(null)}
            />
          </DialogWrapper>
        )
      }
    </>
  );
}
