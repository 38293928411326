// eslint-disable-next-line import/no-extraneous-dependencies
import * as H from 'history';

export type Route = string;
export type PathParamString = string;
export type QueryParamString = string;

interface PathParam {
  label: PathParamString,
  value: string | number,
}

interface QueryParam {
  label: QueryParamString,
  value: string,
}

export class Routes {
  public static withPath(url: Route, pathParams: PathParam[]): Route {
    let urlFinal = url;
    pathParams.forEach((pathParam) => {
      const tmpUrlFinal = urlFinal.replace(pathParam.label, pathParam.value.toString());
      if (tmpUrlFinal === urlFinal) {
        // eslint-disable-next-line no-console
        console.warn("Warning: Trying to add a path param that doesn't exist on this url, ", url, pathParam.label);
      }
      urlFinal = tmpUrlFinal;
    });
    return urlFinal;
  }

  public static updatePathWithQuery(location: H.Location, queryParams: QueryParam[]): string {
    const url = `${location.pathname}`;
    const params = {};
    for (const entry of new URLSearchParams(location.search).entries()) {
      // eslint-disable-next-line prefer-destructuring
      params[entry[0]] = entry[1];
    }
    for (const queryParam of queryParams) {
      params[queryParam.label] = queryParam.value;
    }
    return Routes.updateUrlWithObject(url, params);
  }

  private static updateUrlWithObject(url: string, params: { [key: string]: string }) {
    const queryParamsString = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&');

    return `${url}${queryParamsString ? '?' : ''}${queryParamsString}`;
  }

  public static updateUrlWithQuery(url: string, queryParams: QueryParam[]): string {
    const params = {};
    for (const queryParam of queryParams) {
      params[queryParam.label] = queryParam.value;
    }
    return Routes.updateUrlWithObject(url, params);
  }

  public static retrieveInPath(url: string, pathParam: string, partialPath: string): string {
    const arr = partialPath.split(pathParam);
    const urlPartial = url.replace(arr[0], '');
    return urlPartial.split('/')[0];
  }

  public static locationMatchWithPath(location: H.Location, path: string, exact = true): boolean {
    const pathArr = path.split('/');
    const locationArr = location.pathname.split('/');
    if (pathArr.length !== locationArr.length && exact) {
      return false;
    }
    return pathArr.every((str, index) => {
      if (str.startsWith(':')) {
        return true;
      }
      return str === locationArr[index];
    });
  }
}
