import React, { useState } from 'react';
import {
  XIcon,
} from '@heroicons/react/solid';
import Modal from 'lib/Modal';
import ImageCarrousel from 'lib/ImageCarousel';

type Props = {
  imageUrls: string[]
  isOpen: boolean,
  onClose: () => void,
  intitialSelectedImage?: number,
};

export default function CarrouselModal(
  {
    imageUrls,
    onClose,
    isOpen,
    intitialSelectedImage,
  }: Props,
) {
  const [selectedIndex, setSelectedIndex] = useState<number>(intitialSelectedImage || 0);

  return (
    <Modal className="carrousel-modal" isOpen={isOpen} onClose={onClose}>
      <button
        type="button"
        onClick={(e) => {
          e.preventDefault();
          onClose();
        }}
        className="close-button"
      >
        <XIcon />
      </button>
      <ImageCarrousel
        imageUrls={imageUrls}
        showArrows
        intitialSelectedImage={selectedIndex}
        selectIndex={setSelectedIndex}
        enableKeyBoard
      />
    </Modal>
  );
}
