import React, { useEffect, useRef } from 'react';
import {
  CANDIDATURE_DETAILS,
  CANDIDATURES,
  DASHBOARD,
} from 'routes/Routes';
import {
  Redirect,
  Route,
  Switch,
  useParams,
} from 'react-router-dom';
import Candidatures from 'pages/client-app/candidature/candidatureList/Candidatures';
import CandidatureDetails from 'pages/client-app/candidature/candidatureDetails/CandidatureDetails';
import CandidatureList from 'pages/client-app/candidature/candidatureList/CandidatureList';
import { detectMobile } from 'services/utils';
import PageBackButton from 'pages/common/PageBackButton';
import { useVisitBackend } from 'network/queries/VisitQueries';

type Param = {
  id?: string,
};

export default function CandidatureRouter() {
  const { id } = useParams<Param>();
  const visitQueries = useVisitBackend();
  const { getVisits } = visitQueries;
  const { data: visits } = getVisits();
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (detectMobile() && ref && ref.current) {
      ref.current?.scrollTo({ top: 0 });
    }
  }, [id]);

  return (
    <>
      {
        detectMobile() && id && (
          <PageBackButton to={CANDIDATURES} />
        )
      }
      <div ref={ref} className="page-body">
        <div className="candidature-page">
          <div className="candidature-selector">
            <CandidatureList selectedCandidateId={id} visits={visits || []} />
          </div>
          <div className="candidature-details">
            <Switch>
              <Route exact path={CANDIDATURES} component={Candidatures} />
              <Route
                path={CANDIDATURE_DETAILS}
                render={() => (
                  <CandidatureDetails
                    visits={visits?.filter((visit) => visit.candidatureID === id) || []}
                  />
                )}
              />
              <Route render={() => <Redirect to={DASHBOARD} />} />
            </Switch>
          </div>
        </div>
      </div>
    </>
  );
}
