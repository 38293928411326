import React from 'react';
import {
  format,
} from 'date-fns';
import localService from 'services/i18n/LocalService';

import Button from 'theme/Button';
import { PossibleVisitSlot } from 'types/VisitSlot';
import Messages from 'services/i18n/Messages';

type Props = {
  visit: PossibleVisitSlot
  selectVisit: (visit: PossibleVisitSlot) => void
  showMore: () => void
  submitting?: boolean,
};

export default function VisitBookingConfirm(
  {
    visit,
    selectVisit,
    showMore,
    submitting,
  }: Props,
) {
  return (
    <>
      <h3>
        {format(new Date(visit.startDate), Messages.t('booking.visit.dateFormat'), { locale: localService.getDateLocal() })}
      </h3>
      <div className="booking-confirm">
        <Button disabled={submitting} color="secondary" onClick={showMore}>
          {Messages.t('booking.visit.change')}
        </Button>
        <Button disabled={submitting} onClick={() => selectVisit(visit)}>
          {Messages.t('formButton.validate')}
        </Button>
      </div>
    </>
  );
}
