import React, { useCallback, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { SearchFormType, SearchTypeDetails } from 'types/SearchType';
import Messages from 'services/i18n/Messages';
import SpinButton from 'theme/SpinButton';
import PriceRangeInputWrapper from 'pages/common/search/PriceRangeInputWrapper';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { booleanToString, stringToBoolean } from 'lib/form/FormUtils';
import AreaRangeInputWrapper from 'pages/common/search/AreaRangeInputWrapper';
import ListWrapper from 'lib/form/ListWrapper';
import { propertyType } from 'types/Property';
import ZipCodeAutoCompleteWrapper from 'lib/form/ZipCodeAutoCompleteWrapper';
import { zipCodeApi } from 'network/api/zipCodeApi';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import SwitchWrapper from 'lib/form/SwitchWrapper';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

type Props = {
  onSubmit: (data: SearchTypeDetails) => void
  submitting: boolean,
  search?: SearchTypeDetails
  showNotifications?: boolean,
};

export default function SearchForm(
  {
    onSubmit,
    submitting,
    search,
    showNotifications,
  }: Props,
) {
  const [apiErrors] = useState({});
  const [isPriceMaxRange, setIsPriceMaxRange] = useState(!search?.priceMax);
  const [isPriceMinRange, setIsPriceMinRange] = useState(!search?.priceMin);
  const [isAreaMaxRange, setIsAreaMaxRange] = useState(!search?.areaMax);
  const [isAreaMinRange, setIsAreaMinRange] = useState(!search?.areaMin);
  const [searchForData, setSearchForData] = useState<SearchTypeDetails | undefined>();

  // eslint-disable-next-line no-undef
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const clearTimer = useCallback(() => {
    if (timer) {
      clearTimeout(timer);
    }
  }, [timer]);

  useEffect(() => clearTimer,
    []);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<SearchFormType>({
    defaultValues: {
      ...search,
      active: search?.active === undefined ? true : search.active,
      propertyType: search?.propertyType,
      furnished: booleanToString(search?.furnished),
      priceMin: search?.priceMin ? search.priceMin.toString() : undefined,
      priceMax: search?.priceMax ? search.priceMax.toString() : undefined,
      areaMin: search?.areaMin ? search.areaMin.toString() : undefined,
      areaMax: search?.areaMax ? search.areaMax.toString() : undefined,
    },
  });

  const searchFormToSearch = (data: SearchFormType): SearchTypeDetails => ({
    ...data,
    furnished: stringToBoolean(data?.furnished),
    priceMin: (!isPriceMinRange && data.priceMin) ? Number(data.priceMin) : undefined,
    priceMax: (!isPriceMaxRange && data.priceMax) ? Number(data.priceMax) : undefined,
    areaMin: (!isAreaMinRange && data.areaMin) ? Number(data.areaMin) : undefined,
    areaMax: (!isAreaMinRange && data.areaMax) ? Number(data.areaMax) : undefined,
  });

  const formField = watch();
  const stringSearch = JSON.stringify(formField);
  useEffect(() => {
    clearTimer();
    setTimer(setTimeout(() => {
      const searchForm: SearchFormType = JSON.parse(stringSearch);
      setSearchForData(searchFormToSearch(searchForm));
    }, 500));
  }, [stringSearch]);

  const submitForm: SubmitHandler<SearchFormType> = (data: SearchFormType) => {
    onSubmit(searchFormToSearch(data));
  };

  return (
    <div>
      <form className="search-form" onSubmit={handleSubmit(submitForm)}>
        <div className="form-block">
          <h3>{Messages.t('field.searchName')}</h3>
          <Controller
            name="name"
            rules={{ required: true }}
            control={control}
            render={(controller) => (
              <TextFieldWrapper
                apiErrors={apiErrors}
                error={errors}
                control={controller}
                label={Messages.t('field.name')}
              />
            )}
          />
        </div>
        <div className="form-block">
          <h3>{Messages.t('property.location')}</h3>
          <Controller
            name="postalCodes"
            rules={{ required: true }}
            control={control}
            render={(controller) => (
              <ZipCodeAutoCompleteWrapper
                apiErrors={apiErrors}
                error={errors}
                control={controller}
                label={Messages.t('field.postalCodes')}
                getPropositions={zipCodeApi.searchZipCode}
                getSelectedLabels={zipCodeApi.listZipCodes}
                placeholder={Messages.t('field.postalCodes')}
                allZoneMessage={Messages.t('field.allZone')}
              />
            )}
          />
        </div>
        <div className="form-block">
          <h3>{Messages.t('field.propertyType')}</h3>
          <Controller
            name="propertyType"
            rules={{ required: true }}
            control={control}
            render={(controller) => (
              <ListWrapper
                apiErrors={apiErrors}
                error={errors}
                control={controller}
                values={Object.values(propertyType).map((type) => ({
                  key: type,
                  label: Messages.t(`property.type.${type}`),
                }))}
              />
            )}
          />
        </div>
        <div className="form-block">
          <h3>{Messages.t('field.furnished')}</h3>
          <Controller
            name="furnished"
            rules={{ required: true }}
            control={control}
            render={(controller) => (
              <ListWrapper
                apiErrors={apiErrors}
                error={errors}
                control={controller}
                values={[
                  { key: 'true', label: Messages.t('generics.YES') },
                  { key: 'false', label: Messages.t('generics.NO') },
                  { key: 'all', label: Messages.t('generics.ALL') },

                ]}
              />
            )}
          />
        </div>
        <div className="form-block">
          <h3>{Messages.t('field.propertyDetails')}</h3>
          <h4>{Messages.t('field.roomsNumber')}</h4>
          <Controller
            name="rooms"
            control={control}
            render={(controller) => (
              <ListWrapper
                apiErrors={apiErrors}
                error={errors}
                multiple
                control={controller}
                values={[...Array(5).keys()].map((n) => ({
                  key: (n + 1).toString(),
                  label: n === 4 ? '5+' : (n + 1).toString(),
                }))}
              />
            )}
          />
          <h4>{Messages.t('field.bedroomsNumber')}</h4>
          <Controller
            name="bedrooms"
            control={control}
            render={(controller) => (
              <ListWrapper
                apiErrors={apiErrors}
                error={errors}
                multiple
                control={controller}
                values={[...Array(5).keys()].map((n) => ({
                  key: (n + 1).toString(),
                  label: n === 4 ? '5+' : (n + 1).toString(),
                }))}
              />
            )}
          />
        </div>
        <div className="form-block">
          <h3>{Messages.t('field.budget')}</h3>
          <PriceRangeInputWrapper
            isMaxRange={isPriceMaxRange}
            setIsMaxRange={setIsPriceMaxRange}
            setIsMinRange={setIsPriceMinRange}
            setValue={setValue}
            formField={formField}
            search={searchForData}
            control={control}
            apiErrors={apiErrors}
            errors={errors}
          />
        </div>
        <div className="form-block">
          <h3>{Messages.t('field.area')}</h3>
          <AreaRangeInputWrapper
            isMaxRange={isAreaMaxRange}
            setIsMaxRange={setIsAreaMaxRange}
            setIsMinRange={setIsAreaMinRange}
            setValue={setValue}
            formField={formField}
            search={searchForData}
            control={control}
            apiErrors={apiErrors}
            errors={errors}
          />
        </div>
        {
          showNotifications && (
            <div className="form-block">
              <h3>{Messages.t('field.notification')}</h3>
              <Controller
                name="active"
                control={control}
                render={(controller) => (
                  <SwitchWrapper
                    label={Messages.t('field.notificationSearch')}
                    apiErrors={apiErrors}
                    error={errors}
                    control={controller}
                  />
                )}
              />
            </div>
          )
        }

        <div className="form-block">
          <SpinButton
            editing={false}
            spin={submitting}
            title={Messages.t('formButton.confirm')}
          />
        </div>
      </form>
    </div>
  );
}
