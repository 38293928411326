import { toast } from 'react-toastify';
import { ToastOptions } from 'react-toastify/dist/types';

export class NotificationService {
  private static option: ToastOptions = {
    position: 'bottom-left',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
  };

  public static notifySuccess(message: string) {
    toast.success(message, NotificationService.option);
  }

  public static notifyWarning(message: string) {
    toast.success(message, NotificationService.option);
  }

  public static notifyError(message: string) {
    toast.error(message, NotificationService.option);
  }
}
