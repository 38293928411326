import React from 'react';
import { PropertySearch } from 'types/SearchType';
import Messages from 'services/i18n/Messages';
import { CandidatureShort } from 'types/Candidature';
import { VisitDetailed } from 'types/Visit';
import {
  Bolt,
} from '@material-ui/icons';
import { isAfter, isBefore } from 'date-fns';
import { documentStatusTypeEnum, visitStatusTypeEnum } from 'types/CandidatureBase';

type Props = {
  property?: PropertySearch
  candidature?: CandidatureShort
  visits?: VisitDetailed[],
  hideSwtProTag?: boolean
};

export default function PropertyCandidatureTags(
  {
    property,
    candidature,
    visits,
    hideSwtProTag,
  }: Props,
) {
  if (candidature?.isRefused) {
    return (
      <div className="candidature-tag tag rejected">
        {Messages.t('candidature.tag.rejected')}
      </div>
    );
  }
  const showVisits = candidature?.visitStatus === visitStatusTypeEnum.PROPOSED
    || candidature?.visitStatus === visitStatusTypeEnum.BOOKED
    || candidature?.visitStatus === visitStatusTypeEnum.VIEWED
    || (visits?.length || 0) >= 1;
  const showDocuments = candidature?.documentsStatus && (
    candidature.documentsStatus === documentStatusTypeEnum.ASKED
    || candidature.documentsStatus === documentStatusTypeEnum.REMINDED
    || candidature.documentsStatus === documentStatusTypeEnum.MISSING
    || candidature.documentsStatus === documentStatusTypeEnum.SENT
    || candidature.documentsStatus === documentStatusTypeEnum.VALIDATED
    || candidature.documentsStatus === documentStatusTypeEnum.ACCEPTED

  );
  return (
    <>
      {
        (property?.isSwtPro || candidature?.propertyID) && !hideSwtProTag && (
          <div className="tag tag-warning">
            <Bolt /> {Messages.t('property.isSwtPro')}
          </div>
        )
      }
      {
        candidature && !showVisits && !showDocuments
        && (
          <div className="tag tag-info">
            {Messages.t('property.visitAsked')}
          </div>
        )
      }
      {
        candidature && (
          <VisitTags
            candidature={candidature}
            visits={visits || []}
            hidePastVisit={showDocuments}
          />
        )
      }
      {
        candidature && !candidature.isRefused && (
          <>
            {
              (
                candidature.documentsStatus && (
                  candidature.documentsStatus === documentStatusTypeEnum.ASKED
                  || candidature.documentsStatus === documentStatusTypeEnum.REMINDED
                )
              ) && (
                <div className="tag candidature-tag asked">
                  {Messages.t('document.status.asked')}
                </div>
              )
            }
            {
              (
                candidature.documentsStatus && (
                  candidature.documentsStatus === documentStatusTypeEnum.MISSING
                )
              ) && (
                <div className="tag candidature-tag missing">
                  {Messages.t('document.status.missing')}
                </div>
              )
            }
            {
              (
                candidature.documentsStatus && (
                  candidature.documentsStatus === documentStatusTypeEnum.SENT
                  || candidature.documentsStatus === documentStatusTypeEnum.VALIDATED
                )
              ) && (
                <div className="tag candidature-tag sent">
                  {Messages.t('document.status.sent')}
                </div>
              )
            }
            {
              (
                candidature.documentsStatus && (
                  candidature.documentsStatus === documentStatusTypeEnum.ACCEPTED
                )
              ) && (
                <div className="tag candidature-tag sent">
                  {Messages.t('document.status.accepted')}
                </div>
              )
            }
          </>
        )
      }
    </>
  );
}

type TagProps = {
  candidature: CandidatureShort
  visits: VisitDetailed[],
  hidePastVisit?: boolean
};

const VisitTags = ({ candidature, visits, hidePastVisit }: TagProps) => {
  const now = new Date();
  if (visits.length >= 1) {
    if (visits.some((visit) => !visit.isCancel && isAfter((new Date(visit.startDate)), now))) {
      return (
        <div className="tag candidature-tag futur-visit">
          {Messages.t('pageSelector.futurVisit')}
        </div>
      );
    }
    if (candidature.visitStatus === visitStatusTypeEnum.PROPOSED) {
      return (
        <div className="tag candidature-tag proposed-visit">
          {Messages.t('pageSelector.proposed')}
        </div>
      );
    }
    if (
      !hidePastVisit
      && visits.some((visit) => !visit.isCancel && isBefore((new Date(visit.startDate)), now))
    ) {
      return (
        <div className="tag candidature-tag past-visit">
          {Messages.t('pageSelector.pastVisit')}
        </div>
      );
    }
    return (
      <div className="tag candidature-tag canceled-visit">
        {Messages.t('pageSelector.isCanceled')}
      </div>
    );
  }
  if (candidature.visitStatus === visitStatusTypeEnum.PROPOSED) {
    return (
      <div className="tag candidature-tag proposed-visit">
        {Messages.t('pageSelector.proposed')}
      </div>
    );
  }
  if (candidature.visitStatus === visitStatusTypeEnum.BOOKED) {
    return (
      <div className="tag candidature-tag futur-visit">
        {Messages.t('pageSelector.futurVisit')}
      </div>
    );
  }
  if (candidature.visitStatus === visitStatusTypeEnum.VIEWED) {
    return (
      <div className="tag candidature-tag past-visit">
        {Messages.t('pageSelector.pastVisit')}
      </div>
    );
  }
  return null;
};
