import React from 'react';
import Messages from 'services/i18n/Messages';
import { CandidatureShort, DocumentGroupContact } from 'types/Candidature';
import { VisitDetailed } from 'types/Visit';
import CandidatureAction from 'pages/client-app/client-application/candidatures/CandidatureAction';
import { getContactsDocGroupId } from 'services/utils';

type Props = {
  visits: VisitDetailed[],
  documentGroupContact: DocumentGroupContact,
  documentGroupId: string,
  candidaturesData: CandidatureShort[],
};

export default function CandidatureActions(
  {
    visits,
    documentGroupContact,
    documentGroupId,
    candidaturesData,
  }: Props,
) {
  const allContactsDocGroupId = getContactsDocGroupId(candidaturesData);

  return (
    <>
      <div className="properties">
        {candidaturesData?.sort((a, b) => (b.createdAt || '').localeCompare(a.createdAt || '')).map(
          (candidature) => (
            <CandidatureAction
              key={candidature.id}
              candidature={candidature}
              visits={visits}
              documentGroupContact={documentGroupContact}
              documentGroupId={documentGroupId}
              allContactsDocGroupId={allContactsDocGroupId || []}
            />
          ),
        )}
      </div>
      {
        !documentGroupContact?.garant?.id
        && !documentGroupContact?.contact?.id && (
          <div className="missing-contact-info">
            <p>
              {Messages.t('candidature.contactInfoMissing')}
            </p>
          </div>
        )
      }
    </>
  );
}
