import React, { useEffect, useState } from 'react';

import Messages from 'services/i18n/Messages';
import { IosShare } from '@material-ui/icons';
import { Checkbox, Drawer, FormControlLabel } from '@material-ui/core';
import Button from 'theme/Button';
import { detectMobile } from 'services/utils';
import sessionManager from 'services/sessionManager';

type Props = {
  isLoading: boolean,
  setIsLoading: (isLoading: boolean) => void
};

export default function PwaInstallation({ isLoading, setIsLoading }: Props) {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [hidePopUp, setHidePopUp] = useState(false);

  const isMobile = detectMobile();

  useEffect(() => {
    const beforeInstall = (e) => {
      // Prevent Chrome 76 and earlier from automatically showing a prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      setDeferredPrompt(e);
      // Show the install button
      const installButton = document.getElementById('install_button') as HTMLButtonElement;
      if (installButton) {
        installButton.hidden = false;
        // installButton.addEventListener("click", installApp);
      }
    };
    window.addEventListener('beforeinstallprompt', beforeInstall);
    return () => {
      window.removeEventListener('beforeinstallprompt', beforeInstall);
    };
  }, []);

  const installApp = () => {
    if (!deferredPrompt) {
      return;
    }
    // @ts-ignore
    deferredPrompt?.prompt();
    setIsLoading(true);

    // @ts-ignore
    deferredPrompt.userChoice.then(() => {
      setDeferredPrompt(null);
    });
  };

  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  };

  // @ts-ignore
  const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

  return (
    <>
      {
        !sessionManager.getHideInstall() && isIos() && !isInStandaloneMode() && !hidePopUp && (
          <div className="ios-install-container">
            <div className="ios-install">
              <div>
                {Messages.t('install.install')} <IosShare /> {Messages.t('install.andAdd')}
              </div>
              <FormControlLabel
                control={
                  (
                    <Checkbox
                      onChange={() => {
                        sessionManager.setHideInstall();
                        setHidePopUp(true);
                      }}
                      checked={sessionManager.getHideInstall()}
                    />
                  )
                }
                label={Messages.t('install.never')}
              />
            </div>
            <div className="arrow-bottom" />
          </div>
        )
      }
      {
        isMobile && !sessionManager.getHideInstall() && (
          <Drawer
            anchor="bottom"
            open={!!deferredPrompt}
            onClose={() => setDeferredPrompt(null)}
          >
            <div className="install-drawer">
              <div className="install-header">
                {Messages.t('install.app')}
              </div>
              <div>
                <Button disabled={isLoading} color="primary" onClick={installApp}>
                  {Messages.t('formButton.install')}
                </Button>
                <Button color="primary" onClick={() => setDeferredPrompt(null)}>
                  {Messages.t('formButton.cancel')}
                </Button>
              </div>
              <FormControlLabel
                control={
                  (
                    <Checkbox
                      onChange={() => {
                        sessionManager.setHideInstall();
                        setDeferredPrompt(null);
                      }}
                      checked={sessionManager.getHideInstall()}
                    />
                  )
                }
                label={Messages.t('install.never')}
              />
            </div>
          </Drawer>
        )
      }
    </>
  );
}
