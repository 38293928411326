import { useMutation } from 'react-query';
import backend from 'network/BackendFetchAdapter';
import { AssignVisit } from 'types/VisitSlot';
import { Visit } from 'types/Visit';

export function useVisitSlotBackend() {
  const AssignVisitSlot = useMutation<Visit, any, {
    visitSlotId: string,
    assignVisit: AssignVisit,
  }>(
    async (data) => {
      const response = await backend.post(`/api/client/visit-slots/${data.visitSlotId}/visits`, data.assignVisit);
      return response;
    },
  );

  const UpdateVisitSlot = useMutation<Visit, any, {
    visitSlotId: string,
    visitId: string,
    assignVisit: AssignVisit,
  }>(
    async (data) => {
      const response = await backend.post(`/api/client/visit-slots/${data.visitSlotId}/visits/${data.visitId}`, data.assignVisit);
      return response;
    },
  );

  return {
    assignVisitSlot: AssignVisitSlot,
    updateVisitSlot: UpdateVisitSlot,
  };
}
