import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDocumentBackend } from 'network/queries/DocumentQueries';
import { Routes } from 'routes/RoutesUtils';
import { CANDIDATURE_DETAILS, ID } from 'routes/Routes';
import Messages from 'services/i18n/Messages';
import { CheckCircle } from '@material-ui/icons';
import DocumentUtils from 'services/DocumentUtils';
import Button from 'theme/Button';
import DisplayDocPageContent
  from 'pages/client-app/client-application/documents/documentDetails/displayDoc/DisplayDocPageContent';

type Param = {
  id: string,
};

export default function DisplayDocumentPage() {
  const { id } = useParams<Param>();
  const documentQueries = useDocumentBackend();
  const location = useLocation<{
    fromCandidatureId: string | undefined,
    otherDocumentAreFull: boolean | undefined,
  }>();
  const history = useHistory();
  const { getDocumentGroup } = documentQueries;
  const { data: documentGroup } = getDocumentGroup(id);

  const conmpletionPercent = DocumentUtils.getCompletionPercentFromTagGroup(documentGroup);

  return (
    <div className="page-body">
      {
        documentGroup && (
          <DisplayDocPageContent documentGroup={documentGroup} id={id} />
        )
      }
      {
        conmpletionPercent >= 100
        && location.state?.fromCandidatureId
        && location.state?.otherDocumentAreFull && (
          <div>
            <div className="candidature-message sent">
              <CheckCircle /> {Messages.t('candidate.case.congrat')}
            </div>
          </div>
        )
      }
      {
        conmpletionPercent >= 100 && location.state?.fromCandidatureId && (
          <div className="upload-list-action-container">
            <Button
              onClick={() => history.push(Routes.withPath(CANDIDATURE_DETAILS, [{
                label: ID,
                value: location.state.fromCandidatureId || '',
              }]))}
            >
              {
                location.state?.otherDocumentAreFull
                  ? Messages.t('candidate.finish')
                  : Messages.t('candidate.continue')
              }
            </Button>
          </div>
        )
      }
    </div>
  );
}
