import React, { useState } from 'react';
import {
  addDays,
  format, isAfter,
  isBefore, isEqual,
  isSameDay, startOfDay,
} from 'date-fns';
import localService from 'services/i18n/LocalService';
import { IconButton } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import Button from 'theme/Button';
import Messages from 'services/i18n/Messages';
import { detectMobile } from 'services/utils';

type Props<T extends { startDate: string }> = {
  events: T[]
  onClick: (event: T) => void,
  getIsDisable: (event: T) => boolean
  submitting?: boolean,
};

const DEFAULT_THRESHOLD = 5;
const DEFAULT_MOBILE_THRESHOLD = 3;

const DEFAULT_MAX_EVENT_DISPLAYED = 4;

export default function BookingCalendar<T extends { startDate: string }>(
  {
    events,
    onClick,
    getIsDisable,
    submitting,
  }: Props<T>,
) {
  const threshold = detectMobile() ? DEFAULT_MOBILE_THRESHOLD : DEFAULT_THRESHOLD;
  const [startDate, setStartDate] = useState(startOfDay(new Date()));
  const [
    currentMaxEventDisplayed,
    setCurrentMaxEventDisplayed,
  ] = useState(DEFAULT_MAX_EVENT_DISPLAYED);
  const endDate = addDays(startDate, threshold);
  const eventInRange = events
    .filter((event) => (isAfter(new Date(event.startDate), startDate)
        || isEqual(new Date(event.startDate), startDate))
      && (isBefore(new Date(event.startDate), endDate)
        || isEqual(new Date(event.startDate), endDate)));
  const eventByDayDelta: T[][] = [...Array(threshold).keys()].map((dayDelta) => {
    const currentDate = addDays(startDate, dayDelta);
    return eventInRange.filter((event) => isSameDay(currentDate, new Date(event.startDate)));
  });
  let maxEventNumber = 0;
  eventByDayDelta.forEach((eventByDay) => {
    if (maxEventNumber < eventByDay.length) {
      maxEventNumber = eventByDay.length;
    }
  });
  let firstEvent: string | undefined;

  const sortedEvent = events.sort((a, b) => a.startDate.localeCompare(b.startDate));
  const lastEvent = sortedEvent.slice(-1)[0].startDate;
  if (eventInRange.length === 0) {
    firstEvent = sortedEvent[0].startDate;
  }
  return (
    <>
      <h3>{Messages.t('booking.visit')}</h3>
      <div className="booking-calendar">
        <div className="day-header">
          <IconButton
            size="small"
            className="filled-icon-button"
            disabled={
              isBefore(startDate, new Date())
              || isSameDay(startDate, new Date())
              || submitting
            }
            onClick={() => {
              setStartDate((prevState) => {
                const newStart = addDays(prevState, -threshold);
                if (isBefore(newStart, new Date())) {
                  return new Date();
                }
                return startOfDay(newStart);
              });
              setCurrentMaxEventDisplayed(DEFAULT_MAX_EVENT_DISPLAYED);
            }}
          >
            <ChevronLeft />
          </IconButton>
        </div>
        {
          eventByDayDelta.map((eventByDay, index) => {
            const currentDate = addDays(startDate, index);
            return (
              <div key={currentDate.getDate()} className="calendar-column">
                <div className="day-header">
                  <div>
                    {format(currentDate, 'EEEE', { locale: localService.getDateLocal() })}
                  </div>
                  <div>
                    {format(currentDate, 'dd MMM', { locale: localService.getDateLocal() })}
                  </div>
                </div>
                {
                  eventInRange.length > 0 && Array.from({
                    ...eventByDay
                      .sort((a, b) => a.startDate.localeCompare(b.startDate))
                      .slice(0, currentMaxEventDisplayed),
                    length: currentMaxEventDisplayed,
                  }, (v) => v ?? undefined)
                    .map((event, i) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <div key={i} className="event-container">
                        {
                          event ? (
                            <Button color="secondary" disabled={getIsDisable(event) || submitting} onClick={() => onClick(event)}>
                              {format(new Date(event.startDate), 'HH:mm', { locale: localService.getDateLocal() })}
                            </Button>
                          ) : (
                            <div>
                              -
                            </div>
                          )
                        }
                      </div>
                    ))
                }
              </div>
            );
          })
        }
        <div className="day-header">
          <IconButton
            size="small"
            disabled={submitting || isAfter(addDays(startDate, threshold), new Date(lastEvent))}
            className="filled-icon-button"
            onClick={() => {
              setStartDate((prevState) => startOfDay(addDays(prevState, threshold)));
              setCurrentMaxEventDisplayed(DEFAULT_MAX_EVENT_DISPLAYED);
            }}
          >
            <ChevronRight />
          </IconButton>
        </div>
      </div>
      {
        eventInRange.length === 0 && firstEvent && (
          <div className="next-event-button-container">
            <Button
              disabled={submitting}
              color="secondary"
              onClick={() => {
                setStartDate(startOfDay(new Date(firstEvent || '')));
                setCurrentMaxEventDisplayed(DEFAULT_MAX_EVENT_DISPLAYED);
              }}
            >
              {Messages.t('booking.visit.nextAvailable', { date: format(new Date(firstEvent || ''), 'dd MMMM', { locale: localService.getDateLocal() }) })}
            </Button>
          </div>
        )
      }
      {
        currentMaxEventDisplayed < maxEventNumber && (
          <Button disabled={submitting} color="secondary" onClick={() => setCurrentMaxEventDisplayed(maxEventNumber)}>
            {Messages.t('booking.visit.showMore')}
          </Button>
        )
      }
    </>
  );
}
