import React, { useEffect, useState } from 'react';
import {
  Link,
  Route,
  Switch,
  useLocation, useParams,
} from 'react-router-dom';
import {
  APPLICATION_CANDIDATURE,
  APPLICATION_DETAILS_INFO,
  APPLICATION_DOCUMENT,
  ID,
} from 'routes/Routes';
import ApplicationsList from 'pages/client-app/client-application/ApplicationsList';
import Messages from 'services/i18n/Messages';
import { detectMobile } from 'services/utils';
import { Tab, Tabs } from '@material-ui/core';
import { Routes } from 'routes/RoutesUtils';
import DocumentsRouter from 'pages/client-app/client-application/documents/DocumentsRouter';
import Informations from 'pages/client-app/client-application/informations/Informations';
import { DocumentGroupCreation } from 'types/DocumentGroupCreation';
import { CandidatureShort } from 'types/Candidature';
import CandidatureUtils from 'services/CandidatureUtils';
import ContactUtils from 'services/ContactUtils';
import GarantUtils from 'services/GarantUtils';
import { useCandidatureBackend } from 'network/queries/CandidatureQueries';
import CandidaturesTab from 'pages/client-app/client-application/candidatures/CandidaturesTab';

type Param = {
  id?: string,
};

export default function ApplicationRouter() {
  const { id } = useParams<Param>();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const location = useLocation();
  const candidaturesQuery = useCandidatureBackend();
  const { getCandidatures } = candidaturesQuery;
  const { data: candidaturesData } = getCandidatures();
  useEffect(() => {
    setIsFullScreen(false);
  }, [location.pathname]);

  function useRouteMatch(patterns: readonly string[]) {
    for (let i = 0; i < patterns.length; i += 1) {
      const pattern = patterns[i];
      const possibleMatch = Routes.locationMatchWithPath(location, pattern, false);
      if (possibleMatch) {
        return pattern;
      }
    }
    return null;
  }

  const contactByDocumentGroup: { [documentGroupId: string]: string[] } = {};

  const toCreateList: DocumentGroupCreation[] = [];
  if (candidaturesData) {
    candidaturesData.forEach((candidature: CandidatureShort) => {
      candidature.contacts.forEach((contact) => {
        if (contact.documentGroupID) {
          if (!contactByDocumentGroup[contact.documentGroupID]) {
            contactByDocumentGroup[contact.documentGroupID] = [];
          }
          contactByDocumentGroup[contact.documentGroupID].push(contact.id);
          return;
        }
        const matchedContact = CandidatureUtils.getMatchingContactDocumentGroup(
          contact,
          candidaturesData,
        );

        if (matchedContact && matchedContact.documentGroupID) {
          if (!contactByDocumentGroup[matchedContact.documentGroupID]) {
            contactByDocumentGroup[matchedContact.documentGroupID] = [];
          }
          contactByDocumentGroup[matchedContact.documentGroupID].push(contact.id);
        } else {
          const displayName = ContactUtils.getDisplayedName(contact, 0);
          if (toCreateList.filter((docCreation) => docCreation.name === displayName).length === 0) {
            toCreateList.push({
              name: ContactUtils.getDisplayedName(contact, 0),
              contactId: contact.id,
            });
          }
        }
      });
      if (candidature.garants) {
        candidature.garants.forEach((garant) => {
          if (garant.documentGroupID) {
            if (!contactByDocumentGroup[garant.documentGroupID]) {
              contactByDocumentGroup[garant.documentGroupID] = [];
            }
            contactByDocumentGroup[garant.documentGroupID].push(garant.id);
            return;
          }
          const matchedGarant = CandidatureUtils.getMatchingGarantDocumentGroup(
            garant,
            candidaturesData,
          );
          if (matchedGarant && matchedGarant.documentGroupID) {
            if (!contactByDocumentGroup[matchedGarant.documentGroupID]) {
              contactByDocumentGroup[matchedGarant.documentGroupID] = [];
            }
            contactByDocumentGroup[matchedGarant.documentGroupID].push(garant.id);
          } else {
            const displayName = GarantUtils.getDisplayedName(garant);
            if (toCreateList
              .filter((docCreation) => docCreation.name === displayName).length === 0) {
              toCreateList.push({
                name: GarantUtils.getDisplayedName(garant),
                garantId: garant.id,
              });
            }
          }
        });
      }
    });
  }
  const routeMatch = useRouteMatch(
    [APPLICATION_DETAILS_INFO, APPLICATION_DOCUMENT, APPLICATION_CANDIDATURE],
  );
  return (
    <>
      {
        candidaturesData && (
          <ApplicationsList
            id={id}
            candidaturesData={candidaturesData}
            isFullScreen={isFullScreen}
            toCreateList={toCreateList}
          />
        )
      }
      {
        id && !isFullScreen && (
          <Tabs className="application-tab-container" value={routeMatch}>
            <Tab
              label={Messages.t('field.informations')}
              value={APPLICATION_DETAILS_INFO}
              to={Routes.withPath(APPLICATION_DETAILS_INFO, [{ label: ID, value: id }])}
              component={Link}
            />
            <Tab
              label={Messages.t('field.documents')}
              value={APPLICATION_DOCUMENT}
              to={Routes.withPath(APPLICATION_DOCUMENT, [{ label: ID, value: id }])}
              component={Link}
            />
            <Tab
              label={Messages.t('field.candidatures')}
              value={APPLICATION_CANDIDATURE}
              to={Routes.withPath(APPLICATION_CANDIDATURE, [{ label: ID, value: id }])}
              component={Link}
            />
          </Tabs>
        )
      }
      <Switch>
        <Route path={APPLICATION_CANDIDATURE} component={CandidaturesTab} />
        {
          id && (
            <Route
              path={APPLICATION_DETAILS_INFO}
              render={
                () => (
                  <Informations id={id} contactList={contactByDocumentGroup[id] || []} />
                )
              }
            />
          )
        }
        <Route
          path={APPLICATION_DOCUMENT}
          render={
            () => <DocumentsRouter isFullScreen={isFullScreen} setIsFullScreen={setIsFullScreen} />
          }
        />
        {
          !detectMobile() && (
            <Route
              render={
                () => <p>{Messages.t('document.selectAContact')}</p>
              }
            />
          )
        }
      </Switch>
    </>
  );
}
