import React, { useState } from 'react';
import { DocumentData } from 'types/DocumentData';
import { useDocumentBackend } from 'network/queries/DocumentQueries';
import { NotificationService } from 'lib/notification';
import Messages from 'services/i18n/Messages';
import {
  Delete,
  Download,
  Visibility,
} from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import confirmationService from 'services/ConfirmationService';
import FileDisplay from 'pages/common/FileDisplay';
import DialogWrapper from 'pages/common/DialogWrapper';
import { useFilesBackend } from 'network/queries/FileQueries';

type Props = {
  doc: DocumentData,
  documentGroupId: string,
};

export default function DocumentDataLine(
  {
    doc,
    documentGroupId,
  }: Props,
) {
  const { getFile } = useFilesBackend();
  const { data: fileUrl } = getFile(doc.link || '', !!doc.link);
  const [submitting, setSubmitting] = useState(false);
  const documentQueries = useDocumentBackend();
  const {
    deleteDocument,
  } = documentQueries;

  const [filePreview, setFilePreview] = useState<string | undefined>();

  const deleteDoc = async (documentId: string, isComment?: boolean) => {
    if (!isComment) {
      const isConfirmed = await confirmationService.confirm({
        title: Messages.t('confirmation.documentDelete.title'),
        message: Messages.t('confirmation.documentDelete.message'),
      });
      if (!isConfirmed) {
        return;
      }
    }
    setSubmitting(true);
    deleteDocument.mutateAsync({
      documentId,
      documentGroupId,
    }).then(() => NotificationService.notifySuccess(Messages.t('notifications.deleteDocument')))
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => setSubmitting(false));
  };

  return (
    <>
      {
        filePreview && (
          <DialogWrapper open onClose={() => setFilePreview(undefined)}>
            <FileDisplay src={filePreview} isPdf={doc.link?.endsWith('.pdf')} />
          </DialogWrapper>
        )
      }
      <div className="document-data">
        {decodeURI(doc.link?.split('/').slice(-1)[0] || '')}
        <div className="document-actions">
          <IconButton onClick={() => setFilePreview(fileUrl)}>
            <Visibility />
          </IconButton>
          <a href={fileUrl} download={doc.link}>
            <IconButton>
              <Download />
            </IconButton>
          </a>
          <IconButton disabled={submitting} onClick={() => deleteDoc(doc.id)}>
            <Delete />
          </IconButton>
        </div>
      </div>
    </>
  );
}
