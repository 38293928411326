import React from 'react';
import Messages from 'services/i18n/Messages';
import sessionService from 'services/sessionService';
import {
  PowerSettingsNewOutlined,
  Send,
  VpnKeyOutlined,
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { PARAMETERS_PASSWORD } from 'routes/Routes';

export default function Settings() {
  return (
    <div className="page-body page-base settings-page">
      <h3>{Messages.t('navbar.parameters')}</h3>
      <div className="login-button-container">
        <Link
          className="link-as-button secondary"
          to={PARAMETERS_PASSWORD}
        >
          <VpnKeyOutlined /> {Messages.t('parameters.updatePassword')}
        </Link>
      </div>
      <div>
        <button
          type="button"
          className="link-as-button secondary settings-button"
          color="secondary"
          id="intercom_help_link"
        >
          <Send />
          {Messages.t('parameters.help')}
        </button>
      </div>
      <div>
        <button
          type="button"
          className="link-as-button secondary settings-button"
          onClick={() => sessionService.logOut()}
        >
          <PowerSettingsNewOutlined />
          {Messages.t('button.logout')}
        </button>
      </div>
    </div>
  );
}
