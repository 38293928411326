import React, { ReactNode } from 'react';
import Messages from 'services/i18n/Messages';
import { LoadingButton } from '@material-ui/lab';
import { Save } from '@material-ui/icons';

type Props = {
  disabled?: boolean,
  title?: ReactNode,
  waitTitle?: string,
  spin: boolean,
  type?: 'button' | 'submit'
  onClick?: () => void,
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'
  variant?: 'text' | 'outlined' | 'contained'
  className?: string,
  editing: boolean
  isShort?: boolean,
};

export default function SpinButton(
  {
    color,
    disabled,
    title,
    waitTitle,
    spin,
    onClick,
    variant,
    className,
    type,
    editing,
    isShort,
  }: Props,
) {
  return (
    <LoadingButton
      type={type || 'submit'}
      color={color || 'primary'}
      variant={variant || 'contained'}
      disabled={disabled || spin}
      onClick={onClick}
      className={`mui-button ${className}`}
      loading={spin}
    >
      {spin ? (
        <>
          {waitTitle || (!isShort ? Messages.t('formButton.submitting') : <Save />)}
        </>
      ) : (
        title || (editing ? Messages.t('formButton.edit') : Messages.t('formButton.confirm'))
      )}
    </LoadingButton>
  );
}
