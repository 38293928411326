import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { VISITS, VISITS_DETAILS, VISITS_DETAILS_CANCEL } from 'routes/Routes';
import Visits from 'pages/client-app/visits/visitList/Visits';
import VisitDetails from 'pages/client-app/visits/visitDetails/VisitDetails';
import VisitCancel from 'pages/client-app/visits/visitCancel/VisitCancel';

export default function VisitRouter() {
  return (
    <Switch>
      <Route
        path={VISITS_DETAILS_CANCEL}
        component={VisitCancel}
      />
      <Route
        path={VISITS_DETAILS}
        component={VisitDetails}
      />
      <Route
        path={VISITS}
        component={Visits}
      />
    </Switch>
  );
}
