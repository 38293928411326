import React, { useState } from 'react';
import CandidatureItem from 'pages/client-app/candidature/candidatureList/CandidatureItem';
import Messages from 'services/i18n/Messages';
import Button from 'theme/Button';
import sessionManager from 'services/sessionManager';
import { AddCircleOutlined, RemoveCircleOutlineOutlined } from '@material-ui/icons';
import {
  FormControlLabel, FormGroup,
  Menu, MenuItem, Switch,
} from '@material-ui/core';
import { CandidatureShort, DocumentGroupContact } from 'types/Candidature';
import { VisitDetailed } from 'types/Visit';
import { detectMobile } from 'services/utils';
import { useCandidatureBackend } from 'network/queries/CandidatureQueries';
import { NotificationService } from 'lib/notification';
import confirmationService from 'services/ConfirmationService';

type Props = {
  candidature: CandidatureShort;
  visits: VisitDetailed[],
  documentGroupContact: DocumentGroupContact,
  documentGroupId: string,
  allContactsDocGroupId: (string | undefined)[],
};

export default function CandidatureAction(
  {
    candidature, visits,
    documentGroupContact, documentGroupId,
    allContactsDocGroupId,
  }: Props,
) {
  const isMobile = detectMobile();
  const [anchorEl, setAnchorEl] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const candidaturesQuery = useCandidatureBackend();

  const {
    deleteContact, deleteGarant, createContact: createContactWs,
    createGarant: createGarantWs,
  } = candidaturesQuery;

  const deleteItem = (
    data: { contactId?: string, garantId?: string },
    candidatureId: string,
  ) => {
    if (data.contactId) {
      setSubmitting(true);
      deleteContact.mutateAsync({ contactId: data.contactId, candidatureId, documentGroupId })
        .then(() => {
          NotificationService.notifySuccess(Messages.t('notifications.update'));
        })
        .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
        .finally(() => setSubmitting(false));
    } else if (data.garantId) {
      setSubmitting(true);
      deleteGarant.mutateAsync({ garantId: data.garantId, candidatureId, documentGroupId })
        .then(() => {
          NotificationService.notifySuccess(Messages.t('notifications.update'));
        })
        .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
        .finally(() => setSubmitting(false));
    }
  };

  const confirmRemoveApplication = async () => {
    const isConfirmed = await confirmationService.confirm({
      actionColor: 'error',
      message: Messages.t('applications.candidatureTab.warningLastCandidature'),
    });
    if (isConfirmed) {
      handleDelete();
    }
  };

  const handleClick = (e) => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const createItem = (candidatureId: string, isGarant?: boolean) => {
    setSubmitting(true);
    (isGarant ? createGarantWs : createContactWs).mutateAsync({
      data: {
        documentGroupId,
        candidatureId,
      },
    }).then(() => {
      NotificationService.notifySuccess(Messages.t('notifications.update'));
    })
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => {
        setSubmitting(false);
        handleClose();
      });
  };

  const getContactsOfCandidature = (curCandidature: CandidatureShort): DocumentGroupContact[] => {
    if (curCandidature.garants && curCandidature.garants.length) {
      return (
        [...curCandidature.contacts.map((contact): DocumentGroupContact => ({ contact })),
          ...curCandidature.garants?.map((garant): DocumentGroupContact => ({ garant }))]
      );
    }
    return curCandidature.contacts.map((contact): DocumentGroupContact => ({ contact }));
  };

  const allContactsOfCandidature = getContactsOfCandidature(candidature);

  const getDocGroupContact = (contactList: DocumentGroupContact[]): DocumentGroupContact | null => (
    contactList.find((contact0rGarant) => {
      if (contact0rGarant.garant) {
        return contact0rGarant.garant?.documentGroupID === documentGroupId;
      }
      return contact0rGarant.contact?.documentGroupID === documentGroupId;
    }) || null);

  const isLast = allContactsDocGroupId
    .filter((docGrpId) => docGrpId === documentGroupId).length === 1;

  const foundItem = getDocGroupContact(allContactsOfCandidature);
  const isIncluded = Boolean(foundItem);

  const handleDelete = () => {
    if (foundItem?.contact) {
      deleteItem({ contactId: foundItem.contact?.id }, candidature.id);
    } else if (foundItem?.garant) {
      deleteItem({ garantId: foundItem.garant?.id }, candidature.id);
    }
  };

  const onClick = (e) => {
    if (foundItem) {
      if (isLast
        && !foundItem?.contact?.documentGroup?.originalContactID
        && !foundItem?.garant?.documentGroup?.originalContactID
        && !foundItem?.contact?.documentGroup?.originalGarantID
        && !foundItem?.garant?.documentGroup?.originalGarantID
      ) {
        confirmRemoveApplication();
      } else {
        handleDelete();
      }
    } else {
      handleClick(e);
    }
  };

  const open = Boolean(anchorEl);
  return (
    <div
      className="agency-card candidatures-tab-page"
    >
      <CandidatureItem
        candidature={candidature}
        visits={visits.filter((visit) => visit.candidatureID === candidature.id)}
      />
      {
        (documentGroupContact?.garant?.id || documentGroupContact?.contact?.id) && (
          <div className="actions">
            {
              !isMobile ? (
                <div>
                  {isIncluded
                    ? (
                      <p>{Messages.t('candidature.caseAlreadyLinked')}</p>
                    ) : (
                      <p>{Messages.t('candidature.caseNotLinked')}</p>
                    )}
                  <Button
                    color={isIncluded ? 'secondary' : 'primary'}
                    disabled={documentGroupContact?.contact?.userAccountID
                      === sessionManager.getSession()?.user_id
                      || submitting}
                    onClick={onClick}
                    className={`link-button ${isIncluded ? 'remove' : 'add'}`}
                    type="button"
                  >
                    {
                      isIncluded
                        ? (
                          <><RemoveCircleOutlineOutlined /> {Messages.t('candidature.unlinkCase')}</>
                        ) : (
                          <><AddCircleOutlined /> {Messages.t('candidature.linkCase')}</>
                        )
                    }
                  </Button>
                </div>
              ) : (
                <div>
                  <FormGroup>
                    <FormControlLabel
                      label={Messages.t('applications.candidatureTab.includeInCandidature')}
                      labelPlacement="start"
                      control={(
                        <Switch
                          color="info"
                          checked={isIncluded}
                          onClick={onClick}
                        />
                      )}
                    />
                  </FormGroup>
                </div>
              )
            }
          </div>
        )
      }
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <MenuItem onClick={() => createItem(candidature.id)}>
          {Messages.t('applications.candidatureTab.asOccupant')}
        </MenuItem>
        <MenuItem onClick={() => createItem(candidature.id, true)}>
          {Messages.t('applications.candidatureTab.asGarant')}
        </MenuItem>
      </Menu>
    </div>
  );
}
