import React, { useState } from 'react';
import Image from 'theme/Image';
import Messages from 'services/i18n/Messages';
import { ArrowsExpandIcon, LocationMarkerIcon, TemplateIcon } from '@heroicons/react/solid';
import StringUtils from 'services/StringUtils';
import { Property } from 'types/Property';
import PropertyDetails from 'pages/common/PropertyDetails';
import PropertyUtils from 'services/PropertyUtils';
import DialogWrapper from 'pages/common/DialogWrapper';

type Props = {
  property: Property
  checked?: boolean
  selectProperty?: () => void
};

export default function PropertyCard(
  {
    property,
    checked,
    selectProperty,
  }: Props,
) {
  const [showPropertyDetails, setShowPropertyDetails] = useState(false);

  return (
    <>
      {
        showPropertyDetails && (
          <DialogWrapper open onClose={() => setShowPropertyDetails(false)}>
            <PropertyDetails
              showButton={!!selectProperty}
              selectProperty={() => {
                setShowPropertyDetails(false);
                if (selectProperty) {
                  selectProperty();
                }
              }}
              property={property}
            />
          </DialogWrapper>
        )
      }
      <button
        onClick={() => setShowPropertyDetails(true)}
        type="button"
        className={`property-card ${checked ? 'selected' : ''}`}
      >
        <div className="property-image">
          <Image
            src={PropertyUtils.getPropertyMainImage(property)}
            alt="property"
          />
          <div className="image-tag-container">
            <div className={`image-tag ${property.online ? 'success' : 'error'}`} />
          </div>
        </div>
        <div className="property-info">
          <div className="property-info-header">
            <div className="property-price">
              {property.price} &euro;<span className="price-month">{Messages.t('price.byMonth')}</span>
            </div>
            <div className="property-dimension">
              <TemplateIcon />
              {property.rooms}
              <ArrowsExpandIcon />
              {property.area} m2
            </div>
          </div>
          <div className="property-address">
            <LocationMarkerIcon />
            <div className="address-text">
              {
                property.address
                  ? property.address
                  : `${StringUtils.getPrettyAddress(property.city)}, ${property.zipCode || ''}`
              }
            </div>
          </div>
        </div>
        <div className="property-identifier">
          <div>#{property.identifier}</div>
        </div>
      </button>
    </>
  );
}
