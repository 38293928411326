import React, { useContext } from 'react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { MapContext } from 'lib/googleMap/GoogleMap';

const withMap = <P extends object>(WrappedComponent:(props: P) =>ReactJSXElement): (props: Omit<P, 'map'>) =>ReactJSXElement => (props) => {
  const sampleCtx = useContext(MapContext);
  return (
    // @ts-ignore
    <WrappedComponent
      {...props}
      map={sampleCtx}
    />
  );
};

export default withMap;
