import React from 'react';
import Messages from 'services/i18n/Messages';
import { Report } from '@material-ui/icons';
import { detectMobile } from 'services/utils';

export default function Candidatures() {
  return (
    detectMobile() ? <></> : (
      <div className="not-applied">
        <Report />
        <p>
          {Messages.t('candidature.notSelected')}
        </p>
      </div>
    )
  );
}
