import React, { useEffect, useState } from 'react';
import { SearchTypeDetails } from 'types/SearchType';

import { useSearchesBackend } from 'network/queries/SearchQueries';
import { NotificationService } from 'lib/notification';
import Messages from 'services/i18n/Messages';
import SearchCreationFunnel from 'pages/common/search/SearchCreationFunnel';
import sessionManager from 'services/sessionManager';
import { useHistory } from 'react-router-dom';
import { Routes } from 'routes/RoutesUtils';
import { ID, SEARCH_DETAILS } from 'routes/Routes';
import settings from 'services/settings';

export default function SearchCreation() {
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);

  const searchQueries = useSearchesBackend();
  const { createSearch } = searchQueries;

  const submitSearch = (data: SearchTypeDetails) => {
    setSubmitting(true);
    createSearch.mutateAsync({ data })
      .then((search) => {
        history.push(Routes.withPath(SEARCH_DETAILS, [{ label: ID, value: search.id }]));
        NotificationService.notifySuccess(Messages.t('notifications.update'));
      })
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => setSubmitting(false));
  };

  useEffect(() => {
    // @ts-ignore
    window.Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: settings.intercomKey,
      name: sessionManager.getSession()?.user_name,
      email: sessionManager.getSession()?.user_mail,
      created_at: Date.now(),
      custom_launcher_selector: '#intercom_help_link',
      hide_default_launcher: true,
    });
  }, []);

  return (
    <div className="client-root">
      <div className="page-content">
        <div className="page-body search-creation-funnel">
          <SearchCreationFunnel onSubmit={submitSearch} submitting={submitting} />
        </div>
      </div>
    </div>
  );
}
