type ContactNameBase = {
  firstname?: string,
  lastname?: string,
  email?: string,
  phoneNumber?: string,
};

export default class ContactUtils {
  public static getDisplayedName(contact: ContactNameBase, contactCount: number): string {
    let finalName = '';
    if ((contact.firstname || '') !== '' || (contact.lastname || '') !== '') {
      finalName = `${contact.firstname || ''} ${contact.lastname || ''}`;
    } else if (contact.email && contact.email !== '') {
      finalName = contact.email;
    } else if (contact.phoneNumber && contact.phoneNumber !== '') {
      finalName = contact.phoneNumber;
    }
    if (finalName !== '') {
      return `${finalName}${contactCount > 1 ? ` +${contactCount - 1}` : ''}`;
    }
    return 'No Data';
  }
}
