import React, { useState } from 'react';
import { useSearchesBackend } from 'network/queries/SearchQueries';
import { useParams } from 'react-router-dom';
import { SearchSortType, SearchTypeDetails } from 'types/SearchType';
import { Drawer, IconButton, Popover } from '@material-ui/core';
import { ChevronLeft, Sort, Tune } from '@material-ui/icons';
import SearchForm from 'pages/common/search/SearchForm';
import { NotificationService } from 'lib/notification';
import Messages from 'services/i18n/Messages';
import SearchTab from 'pages/common/search/SearchTab';
import SearchSort from 'pages/common/search/SearchSort';
import searchService from 'services/SearchService';
import SearchList from 'pages/common/search/SearchList';

type Param = {
  id?: string
};

type Props = {
  searchDetails: SearchTypeDetails
  searchSort: SearchSortType
  setSearchSort: (sort: SearchSortType) => void
};

export default function SearchHeader({ searchDetails, searchSort, setSearchSort }: Props) {
  const { id } = useParams<Param>();
  const [showSearchDetails, setShowSearchDetails] = useState(false);

  const [submitting, setSubmitting] = useState(false);
  const searchQueries = useSearchesBackend();
  const { getSearches, updateSearch } = searchQueries;
  const { data: searches } = getSearches(!!id);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const [anchorListEl, setAnchorListEl] = React.useState(null);
  const handleListClick = (event) => {
    setAnchorListEl(event.currentTarget);
  };
  const handleListClose = () => {
    setAnchorListEl(null);
  };
  const openList = Boolean(anchorListEl);

  const submitSearch = (data: SearchTypeDetails) => {
    if (id) {
      setSubmitting(true);
      updateSearch.mutateAsync({ data, searchId: id })
        .then(() => NotificationService.notifySuccess(Messages.t('notifications.update')))
        .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
        .finally(() => setSubmitting(false));
    } else {
      searchService.setSearch(data);
    }
  };

  return (
    <div className="search-header-container">
      <div className="search-header">
        {
          searches ? (
            <>
              <button
                className={`search-list-button ${openList ? 'active' : ''}`}
                type="button"
                onClick={handleListClick}
              >
                <span>{searchDetails.name}</span>
                <ChevronLeft />
              </button>
              {
                openList && (
                  <Popover
                    open
                    anchorEl={anchorListEl}
                    onClose={handleListClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <SearchList searchList={searches} />
                  </Popover>
                )
              }
            </>
          ) : (
            <div>{Messages.t('search.mySearch')}</div>
          )
        }
        <div className="header-action-container">
          <IconButton onClick={() => setShowSearchDetails(true)}>
            <Tune />
          </IconButton>
          <IconButton onClick={handleClick}>
            <Sort />
          </IconButton>
        </div>
        {
          open && (
            <Popover
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <SearchSort searchSort={searchSort} setSearchSort={setSearchSort} />
            </Popover>
          )
        }
        {
          showSearchDetails && (
            <Drawer
              anchor="right"
              open={showSearchDetails}
              onClose={() => setShowSearchDetails(false)}
            >
              <div>
                <button
                  type="button"
                  className="close-drawer-button"
                  onClick={() => setShowSearchDetails(false)}
                >
                  <ChevronLeft />
                  {Messages.t('search.results')}
                </button>
              </div>
              <SearchForm
                search={searchDetails}
                showNotifications={!!id}
                onSubmit={submitSearch}
                submitting={submitting}
              />
            </Drawer>
          )
        }
      </div>
      <SearchTab />
    </div>
  );
}
