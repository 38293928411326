import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Messages from 'services/i18n/Messages';
import { Contact, ContactFrom } from 'types/Contact';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import {
  booleanToString,
  mailRegex,
  stringToBoolean,
  stringToNumber,
} from 'lib/form/FormUtils';
import PhoneNumberInputWrapper from 'lib/form/PhoneNumberInputWrapper';
import SelectWrapper from 'lib/form/SelectWrapper';
import { situationTypeEnum } from 'types/PersonalSituation';
import { contractTypeTypeEnum, proSituationTypeEnum } from 'types/forms/CandidateForm';
import SpinButton from 'theme/SpinButton';
import { useDocumentBackend } from 'network/queries/DocumentQueries';
import { NotificationService } from 'lib/notification';
import { useCandidatureBackend } from 'network/queries/CandidatureQueries';

type Props = {
  id?: string,
  contact?: Contact,
  contactList?: string[]
  candidatureId?: string
  onSubmitted?: () => void
};

export default function InformationsForm(
  {
    contact,
    id,
    contactList,
    candidatureId,
    onSubmitted,
  }: Props,
) {
  const [submitting, setSubmitting] = useState(false);
  const [apiErrors] = useState({});
  const candidatureQueries = useCandidatureBackend();
  const { createContact } = candidatureQueries;
  const documentQueries = useDocumentBackend();
  const { updateDocumentGroupContact } = documentQueries;
  const toContactForm = (contactData: Contact): ContactFrom => ({
    ...contactData,
    monthlyIncome: contactData.monthlyIncome?.toString(),
    trialPeriod: booleanToString(contactData.trialPeriod) || undefined,
  });

  const toContact = (contactData: ContactFrom): Contact => ({
    ...contactData,
    monthlyIncome: stringToNumber(contactData.monthlyIncome) || undefined,
    trialPeriod: stringToBoolean(contactData.trialPeriod),
  });
  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors, dirtyFields },
  } = useForm<ContactFrom>({
    defaultValues: contact ? { ...toContactForm(contact) } : undefined,
  });
  useEffect(() => {
    if (contact) {
      reset(toContactForm(contact));
    }
  }, [id]);
  const formField = watch();
  const situationArr = formField.situation?.split(',');

  const onSubmit: SubmitHandler<ContactFrom> = async (formData: ContactFrom) => {
    setSubmitting(true);
    const contactToUpdate = toContact(formData);
    if (id && contactList) {
      const updateData = {};
      Object.keys(contactToUpdate).forEach((key) => {
        if (dirtyFields[key]) {
          updateData[key] = contactToUpdate[key];
        }
      });
      updateDocumentGroupContact.mutateAsync({
        documentGroupId: id,
        data: {
          isGarant: false,
          updateData,
          data: contactToUpdate,
          contacts: contactList,
        },
      })
        .then((data) => {
          NotificationService.notifySuccess(Messages.t('notifications.update'));
          if (onSubmitted) {
            onSubmitted();
          }
          reset(toContactForm(data));
        })
        .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      setSubmitting(true);
      createContact.mutateAsync({
        data: {
          contact: contactToUpdate,
          candidatureId,
        },
      }).then(() => {
        NotificationService.notifySuccess(Messages.t('notifications.update'));
        if (onSubmitted) {
          onSubmitted();
        }
      })
        .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
        .finally(() => setSubmitting(false));
    }
  };

  return (
    <div className="page-body">
      <form onSubmit={handleSubmit(onSubmit)} className="application-informations">
        <h5>{Messages.t('field.personalDatas')}</h5>
        <div className="form-row">
          <Controller
            name="firstname"
            control={control}
            rules={{ required: { value: !formField.lastname, message: Messages.t('form.error.name') } }}
            render={(controller) => (
              <TextFieldWrapper
                apiErrors={apiErrors}
                error={errors}
                type="text"
                control={controller}
                label={Messages.t('field.firstName')}
              />
            )}
          />
          <Controller
            name="lastname"
            control={control}
            rules={{ required: { value: !formField.firstname, message: Messages.t('form.error.name') } }}
            render={(controller) => (
              <TextFieldWrapper
                apiErrors={apiErrors}
                error={errors}
                type="text"
                control={controller}
                label={Messages.t('field.lastName')}
              />
            )}
          />
        </div>
        <div className="form-row">
          <Controller
            name="email"
            control={control}
            rules={{
              pattern: {
                value: mailRegex,
                message: Messages.t('form.error.email'),
              },
            }}
            render={(controller) => (
              <TextFieldWrapper
                apiErrors={apiErrors}
                error={errors}
                disabled={!!contact}
                type="text"
                control={controller}
                label={Messages.t('field.email')}
              />
            )}
          />
          <Controller
            name="phoneNumber"
            control={control}
            render={(controller) => (
              <PhoneNumberInputWrapper
                apiErrors={apiErrors}
                error={errors}
                control={controller}
                label={Messages.t('field.phoneNumber')}
                flag
              />
            )}
          />
        </div>
        <div className="form-row">
          <Controller
            name="type"
            control={control}
            render={(controller) => (
              <SelectWrapper
                apiErrors={apiErrors}
                error={errors}
                control={controller}
                label={Messages.t('field.personalSituation')}
                values={Object.values(situationTypeEnum).map((key) => ({
                  key, label: Messages.t(`personalSituations.${key}`),
                }))}
              />
            )}
          />
        </div>
        <h5>{Messages.t('field.professionalDatas')}</h5>
        <div className="form-row">
          <Controller
            name="situation"
            control={control}
            rules={{ required: true }}
            render={(controller) => (
              <SelectWrapper
                multiple
                apiErrors={apiErrors}
                error={errors}
                control={controller}
                label={Messages.t('field.professionalSituation')}
                values={Object.values(proSituationTypeEnum).map((key) => ({
                  key, label: Messages.t(`professionalSituation.${key}`),
                }))}
              />
            )}
          />
        </div>
        <div className="form-row">
          {
            (situationArr && (situationArr.includes(proSituationTypeEnum.EMPLOYED)
              || situationArr.includes(proSituationTypeEnum.OFFICIAL)
              || situationArr.includes(proSituationTypeEnum.FREELANCER)))
            && (
              <>
                <Controller
                  name="profession"
                  control={control}
                  render={(controller) => (
                    <TextFieldWrapper
                      apiErrors={apiErrors}
                      error={errors}
                      type="text"
                      control={controller}
                      label={Messages.t('field.profession')}
                    />
                  )}
                />
                <Controller
                  name="enterprise"
                  control={control}
                  render={(controller) => (
                    <TextFieldWrapper
                      apiErrors={apiErrors}
                      error={errors}
                      type="text"
                      control={controller}
                      label={Messages.t('field.enterprise')}
                    />
                  )}
                />
              </>
            )
          }
        </div>
        {situationArr && situationArr.includes(proSituationTypeEnum.EMPLOYED)
          && (
            <div className="form-row">
              <Controller
                name="leaseType"
                control={control}
                render={(controller) => (
                  <SelectWrapper
                    apiErrors={apiErrors}
                    error={errors}
                    control={controller}
                    label={Messages.t('field.leaseType')}
                    values={Object.values(contractTypeTypeEnum).map((key) => ({
                      key, label: Messages.t(`contractType.${key}`),
                    }))}
                  />
                )}
              />
              <Controller
                name="trialPeriod"
                control={control}
                render={(controller) => (
                  <SelectWrapper
                    apiErrors={apiErrors}
                    error={errors}
                    control={controller}
                    label={Messages.t('field.trialPeriod')}
                    values={[
                      { key: 'true', label: Messages.t('generics.YES') },
                      { key: 'false', label: Messages.t('generics.NO') },
                    ]}
                  />
                )}
              />
            </div>
          )}
        {
          formField.situation
          && formField.situation !== proSituationTypeEnum.STUDENT
          && (
            <div className="form-row">
              <Controller
                name="monthlyIncome"
                control={control}
                render={(controller) => (
                  <TextFieldWrapper
                    apiErrors={apiErrors}
                    error={errors}
                    type="text"
                    control={controller}
                    label={Messages.t('field.monthlyIncome')}
                  />
                )}
              />
            </div>
          )
        }
        <SpinButton
          editing
          className="submit-button"
          spin={submitting}
          title={Messages.t('formButton.confirm')}
        />
      </form>
    </div>
  );
}
