import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSearchesBackend } from 'network/queries/SearchQueries';
import { Routes } from 'routes/RoutesUtils';
import { ID, PROPERTY_ID, SEARCH_PROPERTY } from 'routes/Routes';
import SearchPropertyCard from 'pages/common/search/SearchPropertyCard';
import SearchHeader from 'pages/common/search/SearchHeader';
import {
  OrderType,
  orderTypeEnum,
  SearchSortType,
  sortTypeEnum,
} from 'types/SearchType';
import { useCandidatureBackend } from 'network/queries/CandidatureQueries';
import house from 'assets/images/house.svg';
import Messages from 'services/i18n/Messages';

type Param = {
  id: string,
};

export default function SearchShortlist() {
  const { id } = useParams<Param>();
  const [searchSort, setSearchSort] = useState<SearchSortType>({
    field: sortTypeEnum.published_at,
  });

  const candidatureQueries = useCandidatureBackend();
  const { getCandidatures } = candidatureQueries;
  const { data: candidatures } = getCandidatures();

  const searchesQueries = useSearchesBackend();
  const { getSearchShortlist, getSearchDetail } = searchesQueries;
  const { data: properties } = getSearchShortlist(id);
  const { data: search } = getSearchDetail(id);

  const toCamel = (s) => s.replace(/([-_][a-z])/ig, ($1) => $1.toUpperCase()
    .replace('-', '')
    .replace('_', ''));

  return (
    <div className="page-content">
      {
        search && (
          <SearchHeader
            searchDetails={search}
            searchSort={searchSort}
            setSearchSort={setSearchSort}
          />
        )
      }
      <div className="page-body">
        <div className="property-list-container">
          {
            properties && properties
              .sort((a, b) => {
                let order: OrderType = orderTypeEnum.desc;
                let { field } = searchSort;
                if (searchSort.field.includes('_asc')) {
                  order = orderTypeEnum.asc;
                  field = field.split('_asc')[0];
                }
                const aField = ((order === orderTypeEnum.desc
                  ? b
                  : a)[toCamel(field)] || '');
                const bField = ((order === orderTypeEnum.desc
                  ? a
                  : b)[toCamel(field)] || '');

                if (typeof aField === 'number' && typeof bField === 'number') {
                  return aField - bField;
                }
                return aField.toString().localeCompare(bField.toString());
              })
              .map((property) => (
                <Link
                  key={property.id}
                  to={{
                    pathname: Routes.withPath(SEARCH_PROPERTY, [
                      { value: property.id, label: PROPERTY_ID },
                      { value: id, label: ID },
                    ]),
                    state: {
                      fromSearch: true,
                    },
                  }}
                >
                  <SearchPropertyCard
                    showSeen={false}
                    candidatures={candidatures}
                    searchId={id}
                    property={property}
                  />
                </Link>
              ))
          }
        </div>
        {
          properties && properties.length === 0 && (
            <div className="no-result-page">
              <div className="no-result-content">
                <img alt="logo" src={house} />
                <div>
                  {Messages.t('search.noShortlist')}
                </div>
              </div>
            </div>
          )
        }
      </div>
    </div>
  );
}
