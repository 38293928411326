import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Messages from 'services/i18n/Messages';
import SpinButton from 'theme/SpinButton';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import { Candidature } from 'types/CandidatureBase';
import { useCandidatureBackend } from 'network/queries/CandidatureQueries';
import { NotificationService } from 'lib/notification';
import { DialogActions } from '@material-ui/core';
import Button from 'theme/Button';

type Props = {
  candidature: Candidature,
  candidatureList: Candidature[],
  onClose: () => void
};

export default function MotivationModalForm({ candidature, candidatureList, onClose }: Props) {
  const [apiErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const candidatureQueries = useCandidatureBackend();
  const { updateCandidature } = candidatureQueries;
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<{ motivation: string }>({
    defaultValues: {
      motivation: candidature.motivation || candidatureList
        .filter((candidatureData) => candidatureData.motivation)?.[0]?.motivation,
    },
  });

  const formField = watch();

  const submit: SubmitHandler<{ motivation: string }> = (data: { motivation: string }) => {
    setSubmitting(true);
    updateCandidature.mutateAsync({ data, candidatureId: candidature.id })
      .then(() => {
        NotificationService.notifySuccess(Messages.t('notifications.update'));
        onClose();
      })
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => setSubmitting(false));
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="form-message">
        {Messages.t('case.share.congrat')}
      </div>
      <Controller
        name="motivation"
        control={control}
        render={(controller) => (
          <TextFieldWrapper
            apiErrors={apiErrors}
            error={errors}
            control={controller}
            type="textarea"
            rows={5}
            label={Messages.t('field.message')}
          />
        )}
      />
      <DialogActions>
        <Button onClick={onClose}>
          {Messages.t('case.congrat.continue')}
        </Button>
        <SpinButton
          editing
          disabled={!formField.motivation}
          spin={submitting}
          title={Messages.t('formButton.send')}
        />
      </DialogActions>
    </form>
  );
}
