import React, { useState } from 'react';
import {
  Link, useHistory,
  useParams, useLocation,
} from 'react-router-dom';
import { useVisitBackend } from 'network/queries/VisitQueries';
import {
  HighlightOff,
  History,
  Notes,
  PersonOutline,
  Today,
} from '@material-ui/icons';
import { format } from 'date-fns';
import localService from 'services/i18n/LocalService';
import Image from 'theme/Image';
import Messages from 'services/i18n/Messages';
import { LocationMarkerIcon } from '@heroicons/react/solid';
import { Routes } from 'routes/RoutesUtils';
import {
  CANDIDATURE_DETAILS,
  ID,
  VISIT_BOOKING,
  VISIT_ID,
  VISITS,
} from 'routes/Routes';
import confirmationService from 'services/ConfirmationService';
import { NotificationService } from 'lib/notification';
import AddToCalendarButton from 'pages/common/AddToCalendarButton';
import { detectMobile } from 'services/utils';
import PageBackButton from 'pages/common/PageBackButton';
import PropertyUtils from 'services/PropertyUtils';

type Param = {
  id: string,
};

export default function VisitDetails() {
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);
  const { id } = useParams<Param>();
  const visitsQueries = useVisitBackend();
  const { getVisit, updateVisit } = visitsQueries;
  const { data: visit } = getVisit(id);
  const location = useLocation<{ prevPath?: string }>();
  const prevPath = location?.state?.prevPath;
  const deleteVisit = async () => {
    const isConfirmed = await confirmationService.confirm({
      title: Messages.t('confirmation.cancelVisit.title'),
      actionColor: 'error',
      message: Messages.t('confirmation.cancelVisit.message'),
    });
    if (isConfirmed && visit) {
      setSubmitting(true);
      updateVisit.mutateAsync({
        visitId: visit.id,
        data: { isCancel: true },
      }).then(async () => {
        NotificationService.notifySuccess(Messages.t('visit.canceled'));
        history.push(VISITS);
      }).catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
        .finally(() => setSubmitting(false));
    }
  };

  return (
    <>
      {detectMobile() && <PageBackButton to={prevPath ? undefined : VISITS} />}
      <div className="visit-page page-body">
        {
          visit && (
            <>
              <div
                className={`visit-item ${visit.isCancel ? 'cancel' : ''}`}
              >
                <div className="visit-item-content">
                  <div className="visit-item-header">
                    <Today />
                    <div>
                      {format(new Date(visit.startDate), 'ccc dd LLLL à HH:mm', { locale: localService.getDateLocal() })}
                    </div>
                  </div>
                  <div className="visit-item-infos">
                    <div className="property-list-item">
                      <div className="property-image">
                        <Image
                          src={PropertyUtils.getPropertyMainImage(visit.property)}
                          alt="property"
                        />
                      </div>
                      <div className="property-info">
                        <div className="property-price">
                          {visit.property.price} &euro;
                          <span className="price-month">{Messages.t('price.byMonth')}</span>
                        </div>
                        <div className="property-address">
                          <LocationMarkerIcon />
                          <span>{visit.address}</span>
                        </div>
                      </div>
                    </div>
                    <div className="agent-infos-container">
                      {
                        visit.isCancel ? (
                          <div className="agent-infos">
                            {Messages.t('visit.canceled')}
                          </div>
                        ) : (
                          <>
                            <div className="agent-infos">
                              <PersonOutline />
                              <div className="agent-infos-body">
                                <div className="agent-infos-header">{Messages.t('visit.responsible')}</div>
                                <div>
                                  {`${visit.agent.firstname || ''} ${visit.agent.lastname || ''}`}
                                </div>
                              </div>
                            </div>
                            {
                              visit.notes && (
                                <div className="agent-infos">
                                  <Notes />
                                  <div className="agent-infos-body">
                                    <div className="agent-infos-header">{Messages.t('visit.notes')}</div>
                                    <div>
                                      {visit.notes?.replaceAll('\\n', '\n')}
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                          </>
                        )
                      }
                    </div>
                  </div>
                  {
                    !visit.isCancel && (
                      <div className="visit-tile-actions">
                        <Link
                          to={
                            Routes.withPath(VISIT_BOOKING, [
                              {
                                label: ID,
                                value: visit.candidatureID,
                              }, {
                                label: VISIT_ID,
                                value: visit.id,
                              },
                            ])
                          }
                          className="text-icon visit-action"
                        >
                          <History />
                          <div>{Messages.t('visit.move')}</div>
                        </Link>
                        <button
                          onClick={deleteVisit}
                          disabled={submitting}
                          type="button"
                          className="text-icon visit-action"
                        >
                          <HighlightOff />
                          <div>{Messages.t('visit.cancel')}</div>
                        </button>
                      </div>
                    )
                  }
                </div>
              </div>
              <Link
                to={
                  Routes.withPath(CANDIDATURE_DETAILS, [
                    {
                      label: ID,
                      value: visit.candidatureID,
                    },
                  ])
                }
                className="link-as-button"
              >
                {Messages.t('case.shareMine')}
              </Link>
              {
                !visit.isCancel && (
                  <AddToCalendarButton visit={visit} className="link-as-button add-to-calendar">
                    {Messages.t('visit.addToCalendar')}
                  </AddToCalendarButton>
                )
              }
            </>
          )
        }
      </div>
    </>
  );
}
