/* global google */

const createHtmlMapOverlay = ({ OverlayView = google.maps.OverlayView, ...args }) => {
  class HtmlMapOverlay extends OverlayView {
    latlng: google.maps.LatLng;

    div: HTMLElement;

    overlayAdded = false;

    constructor() {
      super();
      this.latlng = args.latlng;
      this.div = args.div;
      this.div.style.position = 'absolute';
      this.div.style.transform = 'translate(-50%, -50%)';
      this.setMap(args.map);
    }

    appendDivToOverlay() {
      this.overlayAdded = true;
      const panes = this.getPanes();
      if (this.div) {
        panes?.overlayMouseTarget.appendChild(this.div);
      }
    }

    positionDiv() {
      const point = this.getProjection().fromLatLngToDivPixel(this.latlng);
      if (point && this.div) {
        this.div.style.left = `${point.x}px`;
        this.div.style.top = `${point.y}px`;
      }
    }

    draw() {
      if (!this.overlayAdded) {
        this.appendDivToOverlay();
      }
      this.positionDiv();
    }

    remove() {
      this.div.parentNode?.removeChild(this.div);
    }
  }

  return new HtmlMapOverlay();
};

export default createHtmlMapOverlay;
