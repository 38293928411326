import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import UploadFiles from 'pages/client-app/client-application/documents/documentDetails/uploadDocs/UploadFiles';
import FileCarrousel from 'pages/client-app/client-application/documents/documentDetails/uploadDocs/FileCarrousel';
import { useParams } from 'react-router-dom';
import { useDocumentBackend } from 'network/queries/DocumentQueries';
import { DocumentData, documentTagPendingEnum } from 'types/DocumentData';

type Param = {
  id: string,
};

type Props = {
  isFullScreen: boolean,
  setIsFullScreen: Dispatch<SetStateAction<boolean>>,
};

export default function UploadDocumentPage({ isFullScreen, setIsFullScreen }: Props) {
  const documentQueries = useDocumentBackend();
  const { id } = useParams<Param>();
  const { getDocuments, getDocumentGroup } = documentQueries;
  const [hasUploadedDocuments, setHasUploadedDocuments] = useState(false);
  const { data: documents } = getDocuments(id);
  const { data: documentGroup } = getDocumentGroup(id);
  const documentsByTags: { [tag: string]: DocumentData[] | undefined } = documents?.reduce(
    (acc, value) => {
      const tags = value.tags.split(',');
      for (let i = 0; i < tags.length; i++) {
        const tag = tags[i];
        if (!acc[tag]) {
          acc[tag] = [];
        }
        acc[tag].push(value);
      }
      return acc;
    },
    {},
  ) || {};

  const pendingDoc = documentsByTags[documentTagPendingEnum.PENDING] || [];

  useEffect(() => {
    if (pendingDoc.length > 0) {
      setHasUploadedDocuments(true);
    }
  }, [pendingDoc.length]);
  return (
    <div className="page-body">
      {
        !hasUploadedDocuments && (
          <UploadFiles
            id={id}
          />
        )
      }
      <div className="file-carrousel-container">
        {
          documentGroup && hasUploadedDocuments && (
            <FileCarrousel
              setIsFullScreen={setIsFullScreen}
              isFullScreen={isFullScreen}
              documentGroup={documentGroup}
              documentsByTag={documentsByTags}
              files={pendingDoc}
            />
          )
        }
      </div>
    </div>
  );
}
