/* eslint jsx-a11y/click-events-have-key-events: "off" */

import React, { useEffect } from 'react';
import CloseIcon from '@material-ui/icons/Close';

type Props = {
  isOpen: boolean,
  onClose: () => void,
  centered?: boolean,
  contentClassName?: string,
  children: React.ReactNode,
  header?: React.ReactNode,
  className?: string,
  disableOutClose?: boolean,
};

export default function Modal(
  {
    isOpen,
    onClose,
    children,
    centered,
    contentClassName,
    header,
    className,
    disableOutClose,
  }: Props,
) {
  useEffect(() => {
    const handleKeyBoardEvent = (event: KeyboardEvent) => {
      if (event.keyCode === 27) {
        onClose();
      }
    };
    document.addEventListener('keydown', handleKeyBoardEvent);
    return () => {
      document.removeEventListener('keydown', handleKeyBoardEvent);
    };
  }, []);

  return (
    <div
      role="presentation"
      className={`modal-background ${isOpen ? 'show' : ''} ${centered ? 'centered' : ''}${className || ''}`}
      onClick={!disableOutClose ? onClose : undefined}
    >
      <div
        role="presentation"
        className={`modal-content ${contentClassName || ''}`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {
          header && (
            <div className="header-container">
              <div>
                {header}
              </div>
              <button type="button" onClick={onClose}>
                <CloseIcon />
              </button>
            </div>
          )
        }
        {children}
      </div>
    </div>
  );
}
