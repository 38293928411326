import React, { useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useVisitBackend } from 'network/queries/VisitQueries';
import Image from 'theme/Image';
import Messages from 'services/i18n/Messages';
import {
  ID,
  VISITS, VISITS_DETAILS,
} from 'routes/Routes';
import { NotificationService } from 'lib/notification';
import { detectMobile } from 'services/utils';
import PageBackButton from 'pages/common/PageBackButton';
import Button from 'theme/Button';
import { Routes } from 'routes/RoutesUtils';
import { format } from 'date-fns';
import localService from 'services/i18n/LocalService';
import PropertyUtils from 'services/PropertyUtils';

type Param = {
  id: string,
};

export default function VisitCancel() {
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);
  const { id } = useParams<Param>();
  const visitsQueries = useVisitBackend();
  const { getVisit, updateVisit } = visitsQueries;
  const { data: visit } = getVisit(id);

  const deleteVisit = async () => {
    if (visit) {
      setSubmitting(true);
      updateVisit.mutateAsync({
        visitId: visit.id,
        data: { isCancel: true },
      }).then(async () => {
        NotificationService.notifySuccess(Messages.t('visit.canceled'));
        history.push(VISITS);
      }).catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
        .finally(() => setSubmitting(false));
    }
  };

  if (visit?.isCancel) {
    return <Redirect to={Routes.withPath(VISITS_DETAILS, [{ label: ID, value: visit.id }])} />;
  }

  return (
    <>
      {
        detectMobile() && (
          <PageBackButton to={VISITS} />
        )
      }
      <div className="visit-page page-body">
        {
          visit && (
            <div className="cancel-visit-container">
              <div className="property-image">
                <Image
                  src={PropertyUtils.getPropertyMainImage(visit.property)}
                  alt="property"
                />
              </div>
              <div className="property-info">
                <div className="property-price">
                  {visit.property.price} &euro;
                  <span className="price-month">{Messages.t('price.byMonth')}</span>
                </div>
              </div>
              <div>
                {Messages.t('visit.cancel.message', { date: format(new Date(visit.startDate), 'EEEE dd MMMM', { locale: localService.getDateLocal() }) })}
              </div>
              {
                !visit.isCancel && (
                  <Button
                    color="error"
                    onClick={deleteVisit}
                    disabled={submitting}
                  >
                    {Messages.t('visit.cancel.confirm')}
                  </Button>
                )
              }
            </div>
          )
        }
      </div>
    </>
  );
}
