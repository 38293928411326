import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ChevronRight } from '@material-ui/icons';

type Props = {
  label: string,
  icon: React.ReactNode
  path: string | string[]
  className?: string,
  exact?: boolean
  alternativeAction?: () => void
};

function NavItem(
  {
    label,
    className,
    icon,
    path,
    alternativeAction,
    exact,
  }: Props,
) {
  const location = useLocation();
  let matchPath = false;
  if (typeof path === 'string') {
    matchPath = exact ? path === location.pathname : location.pathname.startsWith(path);
  } else {
    for (let i = 0; i < path.length; i++) {
      if (exact ? path[i] === location.pathname : location.pathname.startsWith(path[i])) {
        matchPath = true;
        break;
      }
    }
  }
  return (
    <Link
      onClick={(e) => {
        if (alternativeAction) {
          alternativeAction();
          e.preventDefault();
        }
      }}
      to={typeof path === 'string' ? path : path[0]}
      className={`nav-item ${matchPath ? 'active' : ''} ${className || ''}`}
    >
      {icon}
      <span>{label}</span>
      {
        matchPath && <ChevronRight />
      }
    </Link>
  );
}

export default NavItem;
