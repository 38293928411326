import { Candidature } from 'types/CandidatureBase';
import { FileUpload } from 'types/FileUpload';

export interface Property {
  id: string,
  agencyId: string,
  price: number | null,
  area: number | null,
  address?: string,
  rooms: number | null,
  online: boolean,
  isArchived: boolean,
  floor: number | null,
  bedrooms: number | null,
  bathrooms: number | null,
  furnished: boolean | null,
  description?: string,
  identifier: string,
  latitude?: number,
  longitude?: number,
  city?: string,
  zipCode?: string,
  images?: PropertyImage[]
  agentID: string,
  createdAt?: string,
  updatedAt?: string,
  lastPublishAt?: string,
  lastUnpublishAt?: string,
  pinelEnabled?: boolean,
}

export interface PropertyIsValide {
  valide: boolean,
}

export interface PropertyDetailled extends Property {
  candidatures?: Candidature[]
}

export interface PropertyImage {
  id?: string,
  imageURL: string,
  propertyID?: string,
  createdAt?: string,
  updatedAt?: string
  order?: number,
}

export interface PropertyPartial extends Partial<Property> {
  id: string
}

export interface PropertyForm {
  id: string,
  agencyId: string,
  price: number | null,
  area: number | null,
  address?: string,
  rooms: number | null,
  online: boolean,
  floor: number | null,
  imagesToUpload: FileUpload[]
  bedrooms: number | null,
  bathrooms: number | null,
  furnished: string | null,
  description: string,
  identifier: string,
  latitude?: number,
  longitude?: number,
  city: string,
  zipCode: string,
  images?: PropertyImage[]
  agentID: string,
}

export type PropertyType = 'ALL' | 'HOUSE' | 'APARTMENT';

type PropertyTypeEnum = {
  [key in PropertyType]: key;
};

export const propertyType: PropertyTypeEnum = {
  APARTMENT: 'APARTMENT',
  HOUSE: 'HOUSE',
  ALL: 'ALL',
};
