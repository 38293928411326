import {
  contractTypeTypeEnum,
  FormField,
  proSituationTypeEnum,
  rentingSituationTypeEnum,
} from 'types/forms/CandidateForm';
import Messages from 'services/i18n/Messages';
import {
  Dispatch,
  SetStateAction,
} from 'react';
import { mailRegex } from 'lib/form/FormUtils';

export const getContactsFields = (
  contactIndex: number,
  setContactNumber: Dispatch<SetStateAction<number>>,
  contactNumber: number,
): FormField[] => (
  [
    {
      pageId: `contacts.${contactIndex}.firstname`,
      questionlabel: Messages.t('form.firstname.question'),
      fieldLabel: Messages.t('form.firstname.label'),
      rules: { required: true },
      type: 'textField',
    },
    {
      pageId: `contacts.${contactIndex}.lastname`,
      questionlabel: Messages.t('form.lastname.question'),
      fieldLabel: Messages.t('form.lastname.label'),
      rules: { required: true },
      type: 'textField',
    },
    {
      pageId: `contacts.${contactIndex}.email`,
      questionlabel: Messages.t('form.email.question'),
      fieldLabel: Messages.t('form.email.label'),
      condition: () => contactIndex === 0,
      rules: {
        required: true,
        pattern: {
          value: mailRegex,
          message: Messages.t('form.error.email'),
        },
      },
      type: 'textField',
    },
    {
      pageId: `contacts.${contactIndex}.phoneNumber`,
      questionlabel: Messages.t('form.phoneNumber.question'),
      fieldLabel: Messages.t('form.phoneNumber.label'),
      rules: { required: true },
      condition: () => contactIndex === 0,
      type: 'phoneNumber',
    },
    {
      pageId: `contacts.${contactIndex}.situation`,
      questionlabel: Messages.t('form.situation.question'),
      fieldLabel: Messages.t('form.situation.label'),
      type: 'select',
      rules: { required: true },
      multiSelect: true,
      selectOption: Object.values(proSituationTypeEnum).map((key) => ({
        key,
        label: Messages.t(`professionalSituation.${key}`),
      })),
    },
    {
      pageId: `contacts.${contactIndex}.leaseType`,
      questionlabel: Messages.t('form.leaseType.question'),
      fieldLabel: Messages.t('form.leaseType.label'),
      rules: { required: true },
      condition: (formfield) => contactIndex === 0
        && formfield.contacts[contactIndex]?.situation
        && formfield.contacts[contactIndex]?.situation?.includes(proSituationTypeEnum.EMPLOYED),
      type: 'select',
      selectOption: Object.values(contractTypeTypeEnum).map((key) => ({
        key,
        label: Messages.t(`contractType.${key}`),
      })),
    },
    {
      pageId: `contacts.${contactIndex}.trialPeriod`,
      questionlabel: Messages.t('form.trialPeriode.question'),
      fieldLabel: Messages.t('form.trialPeriode.label'),
      rules: { required: true },
      condition: (formfield) => contactIndex === 0
        && formfield.contacts[contactIndex]?.situation
        && formfield.contacts[contactIndex]?.situation?.includes(proSituationTypeEnum.EMPLOYED),
      type: 'select',
      selectOption: [
        { key: 'true', label: Messages.t('generics.YES') },
        { key: 'false', label: Messages.t('generics.NO') }],
    },
    {
      pageId: `contacts.${contactIndex}.moreThan10Employees`,
      questionlabel: Messages.t('form.moreThan10Employe.question'),
      fieldLabel: Messages.t('form.moreThan10Employe.label'),
      rules: { required: true },
      condition: (formfield) => contactIndex === 0
        && !!formfield.contacts[contactIndex]?.professionalMoveOut
        && formfield.contacts[contactIndex]?.professionalMoveOut === 'true'
        && formfield.contacts[contactIndex]?.situation?.includes(proSituationTypeEnum.EMPLOYED),
      type: 'select',
      selectOption: [
        { key: 'true', label: Messages.t('generics.YES') },
        { key: 'false', label: Messages.t('generics.NO') }],
    },
    {
      pageId: `contacts.${contactIndex}.longDistance`,
      questionlabel: Messages.t('form.longDistance.question'),
      fieldLabel: Messages.t('form.longDistance.label'),
      rules: { required: true },
      condition: (formfield) => contactIndex === 0
        && !!formfield.contacts[contactIndex]?.professionalMoveOut
        && formfield.contacts[contactIndex]?.professionalMoveOut === 'true'
        && formfield.contacts[contactIndex]?.situation?.includes(proSituationTypeEnum.EMPLOYED),
      type: 'select',
      selectOption: [
        { key: 'true', label: Messages.t('generics.YES') },
        { key: 'false', label: Messages.t('generics.NO') }],
    },
    {
      pageId: `contacts.${contactIndex}.profession`,
      questionlabel: Messages.t('form.profession.question'),
      fieldLabel: Messages.t('form.profession.label'),
      rules: { required: true },
      condition: (formfield) => contactIndex === 0
        && formfield.contacts[contactIndex]?.situation
        && (
          formfield.contacts[contactIndex]?.situation?.includes(proSituationTypeEnum.EMPLOYED)
          || formfield.contacts[contactIndex]?.situation?.includes(proSituationTypeEnum.FREELANCER)
          || formfield.contacts[contactIndex]?.situation?.includes(proSituationTypeEnum.OFFICIAL)
        ),
      type: 'textField',
    },
    {
      pageId: `contacts.${contactIndex}.enterprise`,
      questionlabel: Messages.t('form.enterprise.question'),
      fieldLabel: Messages.t('form.enterprise.label'),
      rules: { required: true },
      condition: (formfield) => contactIndex === 0
        && formfield.contacts[contactIndex]?.situation
        && (
          formfield.contacts[contactIndex]?.situation?.includes(proSituationTypeEnum.EMPLOYED)
          || formfield.contacts[contactIndex]?.situation?.includes(proSituationTypeEnum.FREELANCER)
          || formfield.contacts[contactIndex]?.situation?.includes(proSituationTypeEnum.OFFICIAL)
        ),
      type: 'textField',
    },
    {
      pageId: `contacts.${contactIndex}.monthlyIncome`,
      fieldType: 'number',
      questionlabel: Messages.t('form.monthlyIncome.question'),
      fieldLabel: Messages.t('form.monthlyIncome.label'),
      rules: { required: true },
      condition: (formfield) => formfield.contacts[contactIndex]?.situation
        && (
          formfield.contacts[contactIndex]?.situation !== proSituationTypeEnum.STUDENT
        ),
      type: 'textField',
    },
    {
      pageId: 'occupantRelationship',
      questionlabel: Messages.t('form.occupantRelationship.question'),
      fieldLabel: Messages.t('form.occupantRelationship.label'),
      rules: { required: true },
      condition: () => contactIndex === 0,
      onSelectChange: (value) => {
        if (value === rentingSituationTypeEnum.SOLO) {
          setContactNumber(1);
        } else if (contactNumber === 1) {
          setContactNumber(2);
        }
      },
      type: 'select',
      selectOption: Object.values(rentingSituationTypeEnum).map((key) => ({
        key,
        label: Messages.t(`rentingSituation.${key}`),
      })),
    },
  ]
);
