import backend from 'network/BackendFetchAdapter';
import { Token } from 'types/security/token';
import { LogInFormType } from 'types/forms/LogInFormType';
import { SignInFormType } from 'types/forms/SignInFormType';

export const authentificationApi = {
  signIn: (signInData: SignInFormType): Promise<Token> => backend.post('/api/authentification/signin', signInData),
  firebaseSignIn: (token: string): Promise<Token> => backend.post('/api/authentification/firebase/signin', { token }),
  logIn: (logInData: LogInFormType): Promise<Token> => backend.post('/api/authentification/login', logInData),
  getTokenFromFirebase: (token: string): Promise<Token> => backend.post('/api/authentification/firebase', { token }),
  refreshToken: (token: string): Promise<Token> => backend.post('/api/authentification/refresh', { token }),
  recoverToken: (logInData: LogInFormType): Promise<void> => backend.post('/api/authentification/recover', logInData),
};
