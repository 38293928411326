import React, { useEffect, useRef, useState } from 'react';
import {
  PropertySearch,
  SearchSortType, SearchTypeDetails,
  sortTypeEnum,
} from 'types/SearchType';
import { usePropertiesBackend } from 'network/queries/PropertyQueries';
import InfiniteScroll from 'lib/InfiniteScroll';
import SearchPropertyCard from 'pages/common/search/SearchPropertyCard';

import { Link } from 'react-router-dom';
import SearchHeader from 'pages/common/search/SearchHeader';
import { Routes } from 'routes/RoutesUtils';
import {
  ID,
  PROPERTIES_DETAILS,
  PROPERTY_ID,
  SEARCH_PROPERTY,
} from 'routes/Routes';
import Messages from 'services/i18n/Messages';
import house from 'assets/images/house.svg';
import { CandidatureShort } from 'types/Candidature';

type Props = {
  search: SearchTypeDetails,
  searchId?: string,
  candidatures?: CandidatureShort[],
};

export default function SearchResults({ search, searchId, candidatures }: Props) {
  const [searchSort, setSearchSort] = useState<SearchSortType>({
    field: sortTypeEnum.published_at,
  });

  const propertyQueries = usePropertiesBackend();
  const { searchProperty, invalidateSearch } = propertyQueries;
  const {
    data: propertiesPages,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = searchProperty(search, searchSort, searchId, !!search);

  useEffect(() => (() => invalidateSearch()), []);

  const properties: PropertySearch[] | undefined = propertiesPages?.pages
    .map((result) => result)?.flat();
  const scrollableRef = useRef<HTMLDivElement | null>(null);

  return (
    <>
      {
        search && (
          <SearchHeader
            searchDetails={search}
            searchSort={searchSort}
            setSearchSort={setSearchSort}
          />
        )
      }
      <div className="page-body" ref={scrollableRef}>
        <div className="property-list-container">
          {
            properties && (
              properties.length > 0 ? (
                <InfiniteScroll
                  isLoading={isLoading || isFetchingNextPage}
                  next={fetchNextPage}
                  size={properties.length}
                  hasNext={!!hasNextPage}
                  scrollableDivRef={scrollableRef}
                >
                  {
                    properties && properties
                      .filter((property) => !property.searchData?.isTrash)
                      .map((property) => (
                        <Link
                          key={property.id}
                          to={{
                            pathname: (
                              searchId ? (
                                Routes.withPath(SEARCH_PROPERTY, [
                                  { value: property.id, label: PROPERTY_ID },
                                  { value: searchId, label: ID },
                                ])
                              ) : (
                                Routes.withPath(PROPERTIES_DETAILS, [
                                  { value: property.id, label: PROPERTY_ID },
                                ])
                              )),
                            state: {
                              fromSearch: true,
                            },
                          }}
                        >
                          <SearchPropertyCard
                            showSeen
                            candidatures={candidatures}
                            searchId={searchId}
                            property={property}
                          />
                        </Link>
                      ))
                  }
                </InfiniteScroll>
              ) : (
                <div className="no-result-page">
                  <img alt="logo" src={house} />
                  <div>
                    {Messages.t('search.noResult')}
                  </div>
                </div>
              )
            )
          }
        </div>
      </div>
    </>
  );
}
