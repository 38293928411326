import { GarantType, garantTypeEnum } from 'types/Garant';

type GarantNameBase = {
  name?: string
  type?: GarantType
  firstname?: string
  lastname?: string
};

export default class GarantUtils {
  public static getDisplayedName(garant?: GarantNameBase): string {
    if (garant) {
      let finalName = '';
      if (garant.type === garantTypeEnum.PERSON
        && ((garant.firstname || '') !== '' || (garant.lastname || '') !== '')) {
        finalName = `${garant.firstname || ''} ${garant.lastname || ''}`;
      } else if (garant.name) {
        finalName = garant.name;
      }
      if (finalName !== '') {
        return finalName;
      }
      switch (garant.type) {
        case garantTypeEnum.PERSON: {
          if ((garant.firstname || '') !== '' || (garant.lastname || '') !== '') {
            return `${garant.firstname || ''} ${garant.lastname || ''}`;
          }
          return 'Personne physique';
        }
        case garantTypeEnum.INSURANCE: {
          return 'Assurance locative';
        }
        case garantTypeEnum.BANK: {
          return 'Caution bancaire';
        }
        case garantTypeEnum.ENTERPRISE: {
          return 'Garant entreprise';
        }
        default: {
          break;
        }
      }
    }
    return 'No Data';
  }
}
