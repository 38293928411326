import React, { useEffect, useState } from 'react';
import {
  Link,
  Route,
  Switch,
  useLocation,
  useHistory,
} from 'react-router-dom';
import Messages from 'services/i18n/Messages';
import { VISITS, VISITS_PAST } from 'routes/Routes';
import VisitList from 'pages/client-app/visits/visitList/VisitList';
import { useVisitBackend } from 'network/queries/VisitQueries';
import { isAfter, isBefore } from 'date-fns';
import DialogWrapper from 'pages/common/DialogWrapper';
import { DialogActions, DialogContent } from '@material-ui/core';
import Button from 'theme/Button';

export default function Visits() {
  const location = useLocation<{ showModalVisitAlreadyBooked?: boolean }>();
  const [showAlreadyBookedModal, setShowAlreadyBookedModal] = useState(
    location?.state?.showModalVisitAlreadyBooked || false,
  );
  const visitQueries = useVisitBackend();
  const { getVisits } = visitQueries;
  const { data: visits } = getVisits();
  const now = new Date();
  const history = useHistory();
  useEffect(
    () => setShowAlreadyBookedModal(location?.state?.showModalVisitAlreadyBooked || false),
    [location?.state?.showModalVisitAlreadyBooked],
  );
  return (
    <>
      <div className="page-header">
        <div className="page-selector-container">
          <Link
            className={`page-selector ${location.pathname === VISITS ? 'selected' : ''}`}
            to={VISITS}
          >
            {Messages.t('pageSelector.futurVisits')}
          </Link>
          <Link
            className={`page-selector ${location.pathname === VISITS_PAST ? 'selected' : ''}`}
            to={VISITS_PAST}
          >
            {Messages.t('pageSelector.pastVisits')}
          </Link>
        </div>
      </div>
      <div className="page-body">
        {
          visits && (
            <Switch>
              <Route
                exact
                path={VISITS}
                render={() => (
                  <VisitList
                    chronologicalSort
                    visitList={visits
                      .filter((visit) => isAfter(new Date(visit.startDate), now))}
                  />
                )}
              />
              <Route
                exact
                path={VISITS_PAST}
                render={() => (
                  <VisitList
                    visitList={visits
                      .filter((visit) => isBefore(new Date(visit.startDate), now))}
                  />
                )}
              />
            </Switch>
          )
        }
      </div>
      <DialogWrapper open={showAlreadyBookedModal} onClose={() => setShowAlreadyBookedModal(false)}>
        <div className="already-booked-modal">
          <DialogContent>
            {Messages.t('visit.alreadyBooked')}
          </DialogContent>
          <DialogActions>
            <div className="action">
              <Button onClick={() => {
                history.replace(VISITS, { showModalVisitAlreadyBooked: false });
              }}
              >
                {Messages.t('visit.seeUpcomingVisits')}
              </Button>
            </div>
          </DialogActions>
        </div>
      </DialogWrapper>
    </>
  );
}
