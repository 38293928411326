import React from 'react';
import {
  CANDIDATURE_WITH_OPTIONAL_ID,
  CANDIDATURES,
  APPLICATION_WITH_OPTIONAL_ID,
  PARAMETERS,
  VISITS,
  SEARCHES,
  PROPERTIES_DETAILS, PARAMETERS_PASSWORD,
} from 'routes/Routes';
import { Redirect, Route, Switch } from 'react-router-dom';
import CandidatureRouter from 'pages/client-app/candidature/CandidatureRouter';
import Settings from 'pages/client-app/settings/Settings';
import ApplicationRouter from 'pages/client-app/client-application/ApplicationRouter';
import VisitRouter from 'pages/client-app/visits/VisitRouter';
import SearchRouter from 'pages/client-app/search/SearchRouter';
import PropertyDetailsPage from 'pages/properties/PropertyDetailsPage';
import PasswordSettings from 'pages/client-app/settings/PasswordSettings';

export default function ClientRouter() {
  return (
    <Switch>
      <Route path={SEARCHES} component={SearchRouter} />
      <Route path={PROPERTIES_DETAILS} component={PropertyDetailsPage} />
      <Route path={CANDIDATURE_WITH_OPTIONAL_ID} component={CandidatureRouter} />
      <Route path={APPLICATION_WITH_OPTIONAL_ID} component={ApplicationRouter} />
      <Route path={VISITS} component={VisitRouter} />
      <Route path={PARAMETERS_PASSWORD} component={PasswordSettings} />
      <Route path={PARAMETERS} component={Settings} />
      <Route render={() => <Redirect to={CANDIDATURES} />} />
    </Switch>
  );
}
