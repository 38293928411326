import React from 'react';

import { VisitDetailed } from 'types/Visit';
import { format, isAfter, isBefore } from 'date-fns';
import localService from 'services/i18n/LocalService';
import { Link, useLocation } from 'react-router-dom';
import { Routes } from 'routes/RoutesUtils';
import { ID, VISITS_DETAILS } from 'routes/Routes';

type Props = {
  visits: VisitDetailed[],
};

export default function VisitTag({ visits }: Props) {
  const location = useLocation();
  const now = new Date();
  const getVisitTag = (visit: VisitDetailed) => (
    <div className="visit-tag">
      <div className="visit-tag-date">
        <div>🗓️</div>
        <div>{format(new Date(visit.startDate), 'EEEE dd LLLL yyyy', { locale: localService.getDateLocal() })}</div>
      </div>
      <div className="visit-tag-date">
        <div>🕛️</div>
        <div>{format(new Date(visit.startDate), 'kk\'h\'mm', { locale: localService.getDateLocal() })}</div>
      </div>
    </div>
  );
  if (visits.length >= 1) {
    const futureVisit = visits
      .filter((visit) => !visit.isCancel && isAfter((new Date(visit.startDate)), now))
      .sort((a, b) => a.startDate.localeCompare(b.startDate));
    if (futureVisit.length > 0) {
      return (
        <Link
          onClick={(e) => e.stopPropagation()}
          className="visit-tag-container futur-visit"
          to={{
            pathname: Routes.withPath(VISITS_DETAILS, [{ label: ID, value: futureVisit[0].id }]),
            state: { prevPath: location.pathname },
          }}
        >
          {getVisitTag(futureVisit[0])}
        </Link>
      );
    }
    const pastVisit = visits
      .filter((visit) => !visit.isCancel && isBefore((new Date(visit.startDate)), now))
      .sort((a, b) => b.startDate.localeCompare(a.startDate));
    if (pastVisit.length > 0) {
      return (
        <Link
          onClick={(e) => e.stopPropagation()}
          className="visit-tag-container past-visit"
          to={{
            pathname: Routes.withPath(VISITS_DETAILS, [{ label: ID, value: pastVisit[0].id }]),
            state: { prevPath: location.pathname },
          }}
        >
          {getVisitTag(pastVisit[0])}
        </Link>
      );
    }
  }
  return <></>;
}
