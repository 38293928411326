import { useQuery } from 'react-query';
import backend from 'network/BackendFetchAdapter';
import sessionManager from 'services/sessionManager';
import { Property } from 'types/Property';
import { PublicAgency } from 'types/Agency';

export function useAgencyBackend() {
  const GetAgencyProperties = (agencyId: string, enabled = true) => useQuery<Property[]>(
    ['GetAgencyProperties', sessionManager.getSession()?.user_id, agencyId],
    async () => {
      const response: Property[] = await backend.fetchJson(
        `/api/public/agencies/${agencyId}/properties`,
      );
      return response;
    },
    { enabled },
  );

  const GetAgency = (agencyId: string, enabled = true) => useQuery<PublicAgency>(
    ['GetAgency', sessionManager.getSession()?.user_id, agencyId],
    async () => {
      const response: PublicAgency = await backend.fetchJson(
        `/api/public/agencies/${agencyId}`,
      );
      return response;
    },
    { enabled },
  );
  return {
    getAgencyProperties: GetAgencyProperties,
    getAgency: GetAgency,
  };
}
