import React, { useEffect } from 'react';
import sessionManager from 'services/sessionManager';
import { detectMobile } from 'services/utils';
import { Route, Switch, useLocation } from 'react-router-dom';
import { SEARCH_CREATE, VISIT_BOOKING_CONFIRM, VISIT_BOOKING_WITH_OPTIONAL_VISIT_ID } from 'routes/Routes';
import VisitBooking from 'pages/client-app/visit-booking/VisitBooking';
import VisitBookingConfirmPage from 'pages/client-app/visit-booking/VisitBookingConfirmPage';
import ClientMainView from 'pages/client-app/ClientMainView';
import SearchCreation from 'pages/client-app/search/SearchCreation';
import settings from 'services/settings';

type Props = {
  isLoading: boolean,
  setIsLoading: (isLoading: boolean) => void
};

function ClientRoot({ isLoading, setIsLoading }: Props) {
  const location = useLocation();
  useEffect(() => {
    // @ts-ignore
    window.Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: settings.intercomKey,
      name: sessionManager.getSession()?.user_name,
      email: sessionManager.getSession()?.user_mail,
      created_at: Date.now(),
      custom_launcher_selector: '#intercom_help_link',
      hide_default_launcher: detectMobile() || location.pathname === SEARCH_CREATE,
    });
  }, []);

  return (
    <Switch>
      <Route exact path={VISIT_BOOKING_WITH_OPTIONAL_VISIT_ID} component={VisitBooking} />
      <Route exact path={VISIT_BOOKING_CONFIRM} component={VisitBookingConfirmPage} />
      <Route path={SEARCH_CREATE} component={SearchCreation} />
      <Route render={() => (
        <ClientMainView
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
      />
    </Switch>
  );
}

export default ClientRoot;
