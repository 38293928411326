import React from 'react';
import { CircularProgress } from '@material-ui/core';

type Props = {
  percent: number,
  label?: string,
  size?: number,
};

export default function CircularProgressBar({ percent, label, size }: Props) {
  return (
    <div className="circular-container" style={{ height: size || 36 }}>
      <CircularProgress
        size={size || 36}
        thickness={5}
        color="inherit"
        variant="determinate"
        value={100}
      />
      <CircularProgress
        size={size || 36}
        thickness={5}
        className={`progress-top ${percent === 100 ? 'complete' : ''}`}
        variant="determinate"
        value={percent}
      />
      <div className="value-container">
        <div className="value-content">
          {
            label || (`${percent}%`)
          }
        </div>
      </div>
    </div>
  );
}
