import React, { useEffect } from 'react';

import { useSearchesBackend } from 'network/queries/SearchQueries';
import { Link, useHistory } from 'react-router-dom';
import { Routes } from 'routes/RoutesUtils';
import { ID, SEARCH_CREATE, SEARCH_DETAILS } from 'routes/Routes';
import house from 'assets/images/house.svg';
import Messages from 'services/i18n/Messages';
import searchService from 'services/SearchService';
import SearchList from 'pages/common/search/SearchList';

export default function SearchListPage() {
  const history = useHistory();
  const searchQueries = useSearchesBackend();
  const { getSearches } = searchQueries;
  const { data: searches } = getSearches();

  useEffect(() => {
    const lastVisitedSearch = searchService.getLastVisitedSearch();
    if (searches?.length === 1) {
      history.push(Routes.withPath(SEARCH_DETAILS, [{ label: ID, value: searches[0].id }]));
    } else if (searches && searches.length > 0 && lastVisitedSearch && lastVisitedSearch !== '') {
      for (let i = 0; i < searches.length; i++) {
        if (searches[i].id === lastVisitedSearch) {
          history.push(Routes.withPath(SEARCH_DETAILS, [{ label: ID, value: searches[i].id }]));
        }
      }
    }
  }, [searches?.length]);

  return (
    <div className="page-content">
      {
        searches && (
          <SearchList searchList={searches} />
        )
      }
      {
        searches && searches.length === 0 && (
          <div className="no-result-page">
            <div className="no-result-content">
              <img alt="logo" src={house} />
              <div>
                {Messages.t('search.noAlerts')}
                <Link className="action-link" to={SEARCH_CREATE}>{Messages.t('formButton.clickHere')}</Link>
                {Messages.t('search.toCreateOne')}
              </div>
            </div>
          </div>
        )
      }
    </div>
  );
}
