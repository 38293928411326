import React, { ReactNode } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import CalendarsUtils, { ICS_FILE_NAME } from 'services/CalendarsUtils';
import { VisitDetailed } from 'types/Visit';

type Props = {
  visit: VisitDetailed
  children: ReactNode,
  className?: string,
};

export default function AddToCalendarButton({ children, className, visit }: Props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <>
      <button type="button" onClick={handleClick} className={className}>
        {children}
      </button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          className="link-menu-item"
          onClick={() => {
            handleClose();
          }}
        >
          <a rel="noreferrer" href={CalendarsUtils.getGoogleUrl(visit)} target="_blank">Google</a>
        </MenuItem>
        <MenuItem
          className="link-menu-item"
          onClick={() => {
            handleClose();
          }}
        >
          <a rel="noreferrer" href={CalendarsUtils.getYahooUrl(visit)} target="_blank">Yahoo</a>
        </MenuItem>
        <MenuItem
          className="link-menu-item"
          onClick={() => {
            handleClose();
          }}
        >
          <a rel="noreferrer" href={CalendarsUtils.getMicrosoftUrl(visit, false)} target="_blank">365</a>
        </MenuItem>
        <MenuItem
          className="link-menu-item"
          onClick={() => {
            handleClose();
          }}
        >
          <a rel="noreferrer" href={CalendarsUtils.getMicrosoftUrl(visit, true)} target="_blank">Outlook</a>
        </MenuItem>
        <MenuItem
          className="link-menu-item"
          onClick={() => {
            handleClose();
          }}
        >
          <a rel="noreferrer" href={CalendarsUtils.getTeamsUrl(visit)} target="_blank">Teams</a>
        </MenuItem>
        <MenuItem
          className="link-menu-item"
          onClick={() => {
            handleClose();
          }}
        >
          <a
            rel="noreferrer"
            href={CalendarsUtils.generateIcs(visit)}
            download={ICS_FILE_NAME}
            target="_blank"
          >
            ICS
          </a>
        </MenuItem>
      </Menu>
    </>
  );
}
