import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  ClientSearch,
  ClientSearchProperty, PropertySearch,
  SearchType,
  SearchTypeDetails,
} from 'types/SearchType';
import backend from 'network/BackendFetchAdapter';
import sessionManager from 'services/sessionManager';

export function useSearchesBackend() {
  const queryClient = useQueryClient();

  const GetSearchDetail = (searchId: string, enabled = true) => useQuery<SearchTypeDetails>(
    ['getClientSearchDetail', searchId, sessionManager.getSession()?.user_id],
    async () => {
      const response: SearchTypeDetails = await backend.fetchJson(
        `/api/client/searches/${searchId}`,
      );
      return response;
    },
    { enabled },
  );

  const GetSearchShortlist = (searchId: string, enabled = true) => useQuery<PropertySearch[]>(
    ['getClientSearchShortlist', searchId, sessionManager.getSession()?.user_id],
    async () => {
      const response: PropertySearch[] = await backend.fetchJson(
        `/api/client/searches/${searchId}/shortlist`,
      );
      return response;
    },
    { enabled },
  );

  const GetSearches = (enabled = true) => useQuery<ClientSearch[]>(
    ['getClientSearches', sessionManager.getSession()?.user_id],
    async () => {
      const response: ClientSearch[] = await backend.fetchJson(
        '/api/client/searches',
      );
      return response;
    },
    { enabled },
  );

  const CreateSearch = useMutation<ClientSearch, any, {
    data: SearchTypeDetails,
  }>(
    async ({ data }) => {
      const response = await backend.post('/api/client/searches', data);
      return response;
    }, {
      onSuccess: () => {
        queryClient.refetchQueries(['getClientSearches', sessionManager.getSession()?.user_id]);
      },
    },
  );

  const UpdateSearch = useMutation<SearchType, any, {
    data: Partial<SearchTypeDetails>,
    searchId: string,
  }>(
    async ({ data, searchId }) => {
      const response = await backend.patch(`/api/client/searches/${searchId}`, data);
      return response;
    }, {
      onSuccess: (_, { searchId }) => {
        queryClient.refetchQueries(['getClientSearches', sessionManager.getSession()?.user_id]);
        queryClient.refetchQueries(['getClientSearchDetail', searchId, sessionManager.getSession()?.user_id]);
      },
    },
  );

  const DeleteSearch = useMutation<SearchType, any, string>(
    async (searchId) => {
      const response = await backend.delete(`/api/client/searches/${searchId}`);
      return response;
    }, {
      onSuccess: () => {
        queryClient.refetchQueries(['getClientSearches', sessionManager.getSession()?.user_id]);
      },
    },
  );

  const MarkPropertyAsSeen = useMutation<SearchType, any, {
    propertyId: string,
    searchId: string,
  }>(
    async ({ propertyId, searchId }) => {
      const response = await backend.post(`/api/client/searches/${searchId}/properties/${propertyId}/seen`);
      return response;
    }, {
      onSuccess: () => {
        queryClient.invalidateQueries(['searchProperties']);
      },
    },
  );

  const SaveClientSearchProperty = useMutation<void, any, {
    data: ClientSearchProperty,
    searchId: string,
    propertyId: string,
  }>(
    async ({ propertyId, searchId, data }) => {
      const response = await backend.post(`/api/client/searches/${searchId}/properties/${propertyId}`, data);
      return response;
    }, {
      onSuccess: (_, { searchId, propertyId }) => {
        queryClient.refetchQueries(['getPropertyDetails', propertyId, searchId, sessionManager.getSession()?.user_id]);
        queryClient.invalidateQueries(['searchProperties']);
        queryClient.invalidateQueries(['getClientSearchShortlist', searchId, sessionManager.getSession()?.user_id]);
      },
    },
  );

  return {
    getSearches: GetSearches,
    createSearch: CreateSearch,
    updateSearch: UpdateSearch,
    getSearchDetail: GetSearchDetail,
    markPropertyAsSeen: MarkPropertyAsSeen,
    saveClientSearchProperty: SaveClientSearchProperty,
    getSearchShortlist: GetSearchShortlist,
    deleteSearch: DeleteSearch,
  };
}
