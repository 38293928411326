import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import Messages from 'services/i18n/Messages';
import { MarketPlaceCandidatureForm } from 'types/Candidature';
import ListWrapper from 'lib/form/ListWrapper';
import { useDocumentBackend } from 'network/queries/DocumentQueries';
import SpinButton from 'theme/SpinButton';
import { useCandidatureBackend } from 'network/queries/CandidatureQueries';
import { NotificationService } from 'lib/notification';
import DialogWrapper from 'pages/common/DialogWrapper';
import { DialogContent, DialogTitle } from '@material-ui/core';
import InformationsForm from 'pages/common/InformationsForm';
import GarantInformationsForm from 'pages/common/GarantInformationsForm';
import CandidatureFormUtils from 'services/CandidatureFormUtils';
import Button from 'theme/Button';

type Props = {
  proId?: string,
  swtId: string,
  contactMail?: string,
  onSubmitted: (candidateId: string) => void,
};

export default function CandidatureForm(
  {
    proId,
    swtId,
    contactMail,
    onSubmitted,
  }: Props,
) {
  const [apiErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [createContactModal, setCreateContactModal] = useState(false);
  const [createGarantModal, setCreateGarantModal] = useState(false);

  const documentQueries = useDocumentBackend();
  const { getDocumentGroups } = documentQueries;
  const { data: documents } = getDocumentGroups();

  const candidatureQueries = useCandidatureBackend();
  const { createCandidature } = candidatureQueries;

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<MarketPlaceCandidatureForm>({
    defaultValues: CandidatureFormUtils.getCandidatureForm(),
  });

  const formField = watch();
  CandidatureFormUtils.setCandidatureForm(formField);

  const submitForm = (data: MarketPlaceCandidatureForm) => {
    setSubmitting(true);
    createCandidature.mutateAsync({
      data: {
        ...data,
        contactDocumentIds: data.contactDocumentIds.split(','),
        garantDocumentIds: data.garantDocumentIds?.split(','),
        propertyId: proId,
        swtId,
        contactMail,
      },
    }).then((candidate) => {
      onSubmitted(candidate.id);
      NotificationService.notifySuccess(Messages.t('notifications.candidature'));
    })
      .catch((error) => {
        const { status } = error;
        if (status === 500) {
          NotificationService.notifyError(Messages.t('notifications.error'));
        }
        if (error.json_response?.errorMessage) {
          // @ts-ignore
          NotificationService.notifyError(Messages.t(`field.error.${error.json_response.errorMessage[0]}`));
        }
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <div>
      {
        createContactModal && (
          <DialogWrapper
            open
            onClose={() => setCreateContactModal(false)}
          >
            <DialogTitle>{Messages.t('contacts.create')}</DialogTitle>
            <DialogContent>
              <InformationsForm
                onSubmitted={() => setCreateContactModal(false)}
              />
            </DialogContent>
          </DialogWrapper>
        )
      }
      {
        createGarantModal && (
          <DialogWrapper
            open
            onClose={() => setCreateGarantModal(false)}
          >
            <DialogTitle>{Messages.t('contacts.create')}</DialogTitle>
            <DialogContent>
              <GarantInformationsForm
                onSubmitted={() => setCreateGarantModal(false)}
              />
            </DialogContent>
          </DialogWrapper>
        )
      }
      <form onSubmit={handleSubmit(submitForm)}>
        <Controller
          name="moveInWanted"
          rules={{ required: true }}
          control={control}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              error={errors}
              type="textarea"
              control={controller}
              label={Messages.t('candidature.form.moveInWanted.label')}
            />
          )}
        />
        <Controller
          name="message"
          rules={{ required: true }}
          control={control}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              error={errors}
              type="textarea"
              rows={5}
              control={controller}
              label={Messages.t('candidature.form.message.label')}
            />
          )}
        />
        <h3>{Messages.t('candidate.creation.choose')}</h3>
        <div>{proId ? Messages.t('candidate.choose.recap.swtPro') : Messages.t('candidate.choose.recap')}</div>
        <h4>{Messages.t('occupants.title')}</h4>
        {
          documents && (
            <Controller
              name="contactDocumentIds"
              rules={{ required: true }}
              control={control}
              render={(controller) => (
                <ListWrapper
                  apiErrors={apiErrors}
                  error={errors}
                  multiple
                  control={controller}
                  values={documents.map((document) => ({ key: document.id, label: document.name }))}
                />
              )}
            />
          )
        }
        <Button color="secondary" onClick={() => setCreateContactModal(true)}>{Messages.t('contacts.create')}</Button>
        <h4>{Messages.t('garants.title')}</h4>
        {
          documents && (
            <Controller
              name="garantDocumentIds"
              control={control}
              render={(controller) => (
                <ListWrapper
                  apiErrors={apiErrors}
                  error={errors}
                  multiple
                  control={controller}
                  values={documents.map((document) => ({ key: document.id, label: document.name }))}
                />
              )}
            />
          )
        }
        <Button color="secondary" onClick={() => setCreateGarantModal(true)}>{Messages.t('garants.create')}</Button>
        <div className="candidate-save-message">{Messages.t('candidate.choose.save')}</div>
        <SpinButton
          editing={false}
          spin={submitting}
          title={Messages.t('formButton.confirm')}
        />
      </form>
    </div>
  );
}
