import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSearchesBackend } from 'network/queries/SearchQueries';

import SearchResults from 'pages/common/search/SearchResults';
import searchService from 'services/SearchService';
import { useCandidatureBackend } from 'network/queries/CandidatureQueries';

type Params = {
  id: string,
};

export default function Search() {
  const { id } = useParams<Params>();

  const searchQueries = useSearchesBackend();
  const { getSearchDetail } = searchQueries;
  const { data: search } = getSearchDetail(id);

  const candidatureQueries = useCandidatureBackend();
  const { getCandidatures } = candidatureQueries;
  const { data: candidatures } = getCandidatures();

  useEffect(() => {
    searchService.setLastVisitedSearch(id);
  }, [id]);

  if (!search) {
    return <div>Loading...</div>;
  }

  return (
    <SearchResults search={search} candidatures={candidatures} searchId={id} />
  );
}
