import { Enum } from 'lib/type/enum';

export interface Candidature {
  id: string,
  workflowStep: string,
  propertyID: string,
  order?: number,
  isRefused: boolean,
  agencyID: string,
  agentID?: string,
  createdAt?: string,
  updatedAt?: string,
  notation?: number | string,
  moveInWanted?: string,
  source?: string,
  plateform?: string,
  visitStatus?: string,
  message?: string,
  documentsStatus?: DocumentStatusType,
  documentStatusLastUpdatedAt?: string,
  motivation?: string,
  documentsMessage?: string,
  documentsMessageLastUpdatedAt?: string,
  swtID?: string
}

export interface CandidaturePartial extends Partial<Candidature> {
  id: string,
}

export const candidateNotation = {
  1: '🙁',
  2: '😐',
  3: '🙂',
  4: '🤩',
};

export type DocumentStatusType = 'NOT_ASKED' | 'ASKED' | 'REMINDED' | 'SENT' | 'MISSING' | 'VALIDATED' | 'ACCEPTED';

export const documentStatusTypeEnum: Enum<DocumentStatusType> = {
  NOT_ASKED: 'NOT_ASKED',
  ASKED: 'ASKED',
  REMINDED: 'REMINDED',
  SENT: 'SENT',
  MISSING: 'MISSING',
  VALIDATED: 'VALIDATED',
  ACCEPTED: 'ACCEPTED',
};

export type VisitStatusType = 'PROPOSED' | 'BOOKED' | 'VIEWED';

export const visitStatusTypeEnum: Enum<VisitStatusType> = {
  PROPOSED: 'PROPOSED',
  BOOKED: 'BOOKED',
  VIEWED: 'VIEWED',
};
