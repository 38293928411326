import React, { ReactNode, useState } from 'react';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import Messages from 'services/i18n/Messages';
import Image from 'theme/Image';
import CarrouselModal from 'lib/CarrouselModal';

type Props = {
  images: string[],
  imageOverlay?: ReactNode,
};

// TODO refacto with common/imageGallery
export default function ImageGallery({ images, imageOverlay }: Props) {
  const [initialImage, setInitalImage] = useState<number | undefined>(undefined);

  if (images.length > 0) {
    return (
      <div className="image-gallery">
        <div
          key={images[0]}
          role="presentation"
          className="image-container main-image"
          onClick={() => setInitalImage(0)}
        >
          <Image alt="property" src={images[0]} />
          {
            imageOverlay && (
              <div className="image-overlay">
                {imageOverlay}
              </div>
            )
          }
        </div>
        {
          images.length > 1 && images.slice(1, 5).map((image, index) => (
            <div
              key={image}
              role="presentation"
              className="image-container"
              onClick={() => setInitalImage(index)}
            >
              <Image alt="property" src={image} />
            </div>
          ))
        }
        {
          initialImage !== undefined && (
            <CarrouselModal
              onClose={() => setInitalImage(undefined)}
              isOpen
              imageUrls={images}
              intitialSelectedImage={initialImage}
            />
          )
        }
      </div>
    );
  }

  return (
    <div>
      <div>
        <br />
        <p className="text-center mt-5 mb-5 text-secondary">
          {Messages.t('property.image.noImage')} <PhotoCameraIcon />
        </p>
        <hr className="mb-3" />
      </div>
    </div>
  );
}
