import { CandidatureShort } from 'types/Candidature';

export function detectMobile(): boolean {
  return (window.innerWidth <= 600);
}

export function getContactsDocGroupId(candidatures?: CandidatureShort[]): (string | undefined)[] {
  return candidatures?.flatMap((candidature) => {
    if (candidature.garants) {
      return [
        ...candidature.contacts.map((contact) => contact.documentGroupID),
        ...candidature.garants?.map((garant) => garant.documentGroupID),
      ];
    }
    return [...candidature.contacts.map((contact) => contact.documentGroupID)];
  }) || [];
}
