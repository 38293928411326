import React from 'react';
import { useVisitBackend } from 'network/queries/VisitQueries';
import { useDocumentBackend } from 'network/queries/DocumentQueries';
import { useParams } from 'react-router-dom';
import Messages from 'services/i18n/Messages';
import { useCandidatureBackend } from 'network/queries/CandidatureQueries';
import { CircularProgress } from '@material-ui/core';
import CandidatureActions from './CandidatureActions';

type Params = {
  id: string,
};

export default function CandidaturesTab() {
  const visitQueries = useVisitBackend();
  const { getVisits } = visitQueries;
  const { data: visits } = getVisits();
  const { id } = useParams<Params>();
  const documentQueries = useDocumentBackend();
  const { getDocumentGroupContact } = documentQueries;
  const { data: documentGroupContact } = getDocumentGroupContact(id);
  const candidaturesQuery = useCandidatureBackend();
  const { getCandidatures } = candidaturesQuery;
  const { data: candidaturesData, isLoading } = getCandidatures();

  if (isLoading) {
    return (
      <CircularProgress />
    );
  }
  return (
    <div className="page-body candidatures-tab">
      {candidaturesData && candidaturesData.length && documentGroupContact ? (
        <>
          <div className="intro">
            <p>
              <b>
                {Messages.t('applications.candidatureTab.intro1')}
              </b>
            </p>
            <p>
              <i>
                {Messages.t('applications.candidatureTab.intro2')}
              </i>
            </p>
          </div>
          <div className="main-content">
            <CandidatureActions
              visits={visits || []}
              documentGroupContact={documentGroupContact}
              documentGroupId={id}
              candidaturesData={candidaturesData}
            />
          </div>
        </>
      ) : (
        <p>{Messages.t('candidature.notApplied')}</p>
      )}
    </div>
  );
}
