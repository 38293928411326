import React from 'react';
import { Control, Controller, UseFormTrigger } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import Button from 'theme/Button';
import { CandidateFormType, FormField } from 'types/forms/CandidateForm';
import Messages from 'services/i18n/Messages';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import ListWrapper from 'lib/form/ListWrapper';
import { foyerCompositionEnum } from 'types/Pinel';

type Props = {
  control?: Control<CandidateFormType>,
  errors: FieldErrors
  page: FormField,
  trigger: UseFormTrigger<CandidateFormType>,
  goToNextPage: () => void,
  formField: CandidateFormType,
};

export default function FormQuestionSelect(
  {
    control,
    page,
    trigger,
    goToNextPage,
    errors,
    formField,
  }: Props,
) {
  const options = page.selectOption;
  return (
    <div className="question-container">
      <h5>{page.questionlabel}</h5>
      {options && options.length > 0 && (
        <>
          <Controller
            name={page.pageId}
            control={control}
            rules={page.rules}
            render={(controller) => (
              <ListWrapper
                control={controller}
                values={options}
                error={errors}
                multiple={page.multiSelect}
                onChange={page.onSelectChange}
              />
            )}
          />
          {
            page.pageId === 'foyerCompositionN2' && formField.foyerCompositionN2 === foyerCompositionEnum.NCHILD && (
              <Controller
                name="foyerCompositionN2MoreThan3"
                control={control}
                rules={{
                  required: formField.foyerCompositionN2 === foyerCompositionEnum.NCHILD,
                  validate: (v) => (v && parseInt(v, 10) > 3 && parseInt(v, 10) < 16) || Messages.t('field.error.nbChildren'),
                }}
                render={(controller) => (
                  <TextFieldWrapper
                    control={controller}
                    label={Messages.t('form.nbChildren.label')}
                    error={errors}
                  />
                )}
              />
            )
          }
          <div className="form-button-container">
            <div className="validate-button">
              <Button
                onClick={async () => {
                  let isValid = await trigger(page.pageId);
                  if (page.pageId === 'foyerCompositionN2' && formField.foyerCompositionN2 === foyerCompositionEnum.NCHILD) {
                    isValid = await trigger(page.pageId) && await trigger('foyerCompositionN2MoreThan3');
                  }
                  if (isValid) {
                    goToNextPage();
                  }
                }}
              >
                {Messages.t('formButton.ok')}
              </Button>
            </div>
            {
              page.skipButton && (
                <Button onClick={() => goToNextPage()}>
                  {page.skipButton}
                </Button>
              )
            }
          </div>
        </>
      )}
    </div>
  );
}
