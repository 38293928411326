import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Routes } from 'routes/RoutesUtils';
import { ID, SEARCH_DETAILS, SEARCH_DETAILS_SHORTLIST } from 'routes/Routes';
import Messages from 'services/i18n/Messages';
import signInService from 'services/SignInService';

type Param = {
  id?: string,
};

export default function SearchTab() {
  const location = useLocation();
  const { id } = useParams<Param>();
  return (
    <div className="page-selector-container">
      <Link
        className={`page-selector ${(!id || Routes.locationMatchWithPath(location, SEARCH_DETAILS)) ? 'selected' : ''}`}
        to={Routes.withPath(SEARCH_DETAILS, [{ label: ID, value: id || '' }])}
      >
        {Messages.t('pageSelector.search')}
      </Link>
      <Link
        onClick={(e) => {
          if (!id) {
            e.preventDefault();
            signInService.setShowSignInModal(true, '');
          }
        }}
        className={`page-selector ${Routes.locationMatchWithPath(location, SEARCH_DETAILS_SHORTLIST) ? 'selected' : ''}`}
        to={Routes.withPath(SEARCH_DETAILS_SHORTLIST, [{ label: ID, value: id || '' }])}
      >
        {Messages.t('pageSelector.shortlist')}
      </Link>
    </div>
  );
}
