import React, { useState } from 'react';
import DialogWrapper from 'pages/common/DialogWrapper';
import { DialogTitle } from '@material-ui/core';
import GoogleMap, { Coordinates } from 'lib/googleMap/GoogleMap';
import GoogleMarker from 'lib/googleMap/GoogleMarker';
import GoogleDrawOverlay from 'lib/googleMap/GoogleDrawOverlay';
import { HomeIcon } from '@heroicons/react/solid';
import GoogleCircle from 'lib/googleMap/GoogleCircle';
import { locationType, LocationType } from 'types/SearchType';
import settings from 'services/settings';

type Props = {
  longitude: number,
  latitude: number,
  address: string,
  propertyLocationType?: LocationType,
  id: string,
};

export default function PropertyMap(
  {
    longitude,
    latitude,
    address,
    propertyLocationType,
    id,
  }: Props,
) {
  const [showDynamicMap, setShowDynamicMap] = useState(false);
  const position: Coordinates = {
    lat: latitude,
    lng: longitude,
  };

  return (
    <>
      {
        showDynamicMap && (
          <DialogWrapper className="dynamic-map-modal" open onClose={() => setShowDynamicMap(false)}>
            <DialogTitle>{address}</DialogTitle>
            <div className="dynamic-map-container">
              <GoogleMap center={position} zoom={15} apiKey={settings.googleMapsApiKey}>
                {
                  propertyLocationType !== locationType.ROOFTOP ? (
                    <>
                      <GoogleCircle
                        option={{
                          strokeWeight: 0,
                          fillColor: '#ED8936',
                          fillOpacity: 0.4,
                        }}
                        coordinates={position}
                        radius={100}
                      />
                      <GoogleDrawOverlay isOpen center={position}>
                        <div className="home-icon-container"><HomeIcon /></div>
                      </GoogleDrawOverlay>
                    </>
                  ) : (
                    <GoogleMarker
                      showMarkerIcon
                      className="marker-address"
                      text={address}
                      id={id}
                      coordinates={position}
                    />
                  )
                }
              </GoogleMap>
            </div>
          </DialogWrapper>
        )
      }
      <button
        type="button"
        onClick={() => setShowDynamicMap(true)}
        className="static-map-container"
      >
        <img
          alt="map"
          src={`https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&markers=color:red%7Clabel:A%7C${latitude},${longitude}&zoom=14&size=1500x300&key=${settings.googleMapsApiKey}`}
        />
      </button>
    </>
  );
}
