import { useMutation, useQuery, useQueryClient } from 'react-query';
import sessionManager from 'services/sessionManager';
import backend from 'network/BackendFetchAdapter';
import { DocumentGroup } from 'types/DocumentGroup';
import { DocumentGroupCreation } from 'types/DocumentGroupCreation';
import { DocumentCreation, DocumentData } from 'types/DocumentData';
import { Contact } from 'types/Contact';
import { DocumentGroupContact } from 'types/Candidature';

export function useDocumentBackend() {
  const queryClient = useQueryClient();

  const GetDocumentGroups = (enabled = true) => useQuery<DocumentGroup[]>(
    ['getDocumentGroups', sessionManager.getSession()?.user_id],
    async () => {
      const response: DocumentGroup[] = await backend.fetchJson(
        '/api/client/document-groups',
      );
      return response;
    },
    { enabled },
  );

  const GetDocumentGroup = (documentGroupId: string, enabled = true) => useQuery<DocumentGroup>(
    ['getDocumentGroup', sessionManager.getSession()?.user_id, documentGroupId],
    async () => {
      const response: DocumentGroup = await backend.fetchJson(
        `/api/client/document-groups/${documentGroupId}`,
      );
      return response;
    },
    { enabled },
  );

  const GetDocumentGroupContact = (
    documentGroupId: string,
    enabled = true,
  ) => useQuery<DocumentGroupContact>(
    ['getDocumentGroupContact', sessionManager.getSession()?.user_id, documentGroupId],
    async () => {
      const response: DocumentGroupContact = await backend.fetchJson(
        `/api/client/document-groups/${documentGroupId}/contact`,
      );
      return response;
    },
    { enabled },
  );

  const UpdateDocumentGroupContact = useMutation<Contact, any, {
    documentGroupId: string,
    data: {
      isGarant: boolean
      data: Contact,
      updateData: any,
      contacts: string[],
    },
  }>(
    async ({ data, documentGroupId }) => {
      const response = await backend.patch(`/api/client/document-groups/${documentGroupId}/contact`, data);
      return response;
    }, {
      onSuccess: (_, { documentGroupId }) => {
        queryClient.refetchQueries(['getDocumentGroupContact', sessionManager.getSession()?.user_id, documentGroupId]);
      },
    },
  );

  const GetDocuments = (documentGroupId: string, enabled = true) => useQuery<DocumentData[]>(
    ['getDocuments', sessionManager.getSession()?.user_id, documentGroupId],
    async () => {
      const response: DocumentData[] = await backend.fetchJson(
        `/api/client/documents/${documentGroupId}`,
      );
      return response;
    },
    { enabled },
  );

  const CreateDocumentGroup = useMutation<DocumentGroup, any, DocumentGroupCreation>(
    async (data) => {
      const response = await backend.post('/api/client/document-groups', data);
      return response;
    }, {
      onSuccess: () => {
        queryClient.refetchQueries(['getDocumentGroups', sessionManager.getSession()?.user_id]);
        queryClient.refetchQueries(['getCandidatures', sessionManager.getSession()?.user_id]);
      },
    },
  );

  const CreateDocuments = useMutation<DocumentGroup, any, {
    data: DocumentCreation[],
    documentGroupId: string,
    disableCacheUpdate?: boolean,
  }>(
    async ({ data, documentGroupId }) => {
      const response = await backend.post(`/api/client/documents/${documentGroupId}`, data);
      return response;
    }, {
      onSuccess: (_, { disableCacheUpdate, documentGroupId }) => {
        if (!disableCacheUpdate) {
          queryClient.refetchQueries(['getDocuments', sessionManager.getSession()?.user_id, documentGroupId]);
          queryClient.refetchQueries(['getDocumentGroup', sessionManager.getSession()?.user_id, documentGroupId]);
          queryClient.refetchQueries(['getDocumentGroups', sessionManager.getSession()?.user_id]);
        }
      },
    },
  );

  const DeleteDocument = useMutation<void, any, {
    documentId: string,
    documentGroupId: string,
  }>(
    async ({ documentId, documentGroupId }) => {
      const response = await backend.delete(`/api/client/documents/${documentGroupId}/files/${documentId}`);
      return response;
    }, {
      onSuccess: (_, { documentGroupId }) => {
        // TODO optimise those refetch
        queryClient.refetchQueries(['getDocuments', sessionManager.getSession()?.user_id, documentGroupId]);
        queryClient.refetchQueries(['getDocumentGroup', sessionManager.getSession()?.user_id, documentGroupId]);
        queryClient.refetchQueries(['getDocumentGroups', sessionManager.getSession()?.user_id]);
      },
    },
  );

  const DeleteDocumentWithTag = useMutation<void, any, {
    tag: string,
    documentGroupId: string,
  }>(
    async ({ tag, documentGroupId }) => {
      const response = await backend.delete(`/api/client/documents/${documentGroupId}/tags/${tag}`);
      return response;
    }, {
      onSuccess: (_, { documentGroupId }) => {
        queryClient.refetchQueries(['getDocuments', sessionManager.getSession()?.user_id, documentGroupId]);
        queryClient.refetchQueries(['getDocumentGroup', sessionManager.getSession()?.user_id, documentGroupId]);
        queryClient.refetchQueries(['getDocumentGroups', sessionManager.getSession()?.user_id]);
      },
    },
  );

  const UpdateDocument = useMutation<DocumentData, any, {
    documentId: string,
    documentGroupId: string,
    data: Partial<DocumentData>
  }>(
    async ({ documentId, documentGroupId, data }) => {
      const response = await backend.patch(`/api/client/documents/${documentGroupId}/files/${documentId}`, data);
      return response;
    }, {
      onSuccess: (_, { documentGroupId }) => {
        queryClient.refetchQueries(['getDocuments', sessionManager.getSession()?.user_id, documentGroupId]);
        queryClient.refetchQueries(['getDocumentGroup', sessionManager.getSession()?.user_id, documentGroupId]);
        queryClient.refetchQueries(['getDocumentGroups', sessionManager.getSession()?.user_id]);
      },
    },
  );

  const UpdateDocumentList = useMutation<void, any, {
    documentGroupId: string,
    data: DocumentData[],
  }>(
    async ({ documentGroupId, data }) => {
      const response = await backend.put(`/api/client/documents/${documentGroupId}/files`, data);
      return response;
    }, {
      onSuccess: (_, { documentGroupId }) => {
        queryClient.refetchQueries(['getDocuments', sessionManager.getSession()?.user_id, documentGroupId]);
        queryClient.refetchQueries(['getDocumentGroup', sessionManager.getSession()?.user_id, documentGroupId]);
        queryClient.refetchQueries(['getDocumentGroups', sessionManager.getSession()?.user_id]);
      },
    },
  );

  const DeleteDocumentGroup = useMutation<void, any, {
    documentGroupId: string,
  }>(
    async ({ documentGroupId }) => {
      const response = await backend.delete(`/api/client/document-groups/${documentGroupId}`);
      return response;
    }, {
      onSuccess: () => {
        queryClient.refetchQueries(['getDocumentGroups', sessionManager.getSession()?.user_id]);
        queryClient.refetchQueries(['getCandidatures', sessionManager.getSession()?.user_id]);
      },
    },
  );

  return {
    getDocumentGroup: GetDocumentGroup,
    updateDocument: UpdateDocument,
    updateDocumentList: UpdateDocumentList,
    getDocumentGroups: GetDocumentGroups,
    createDocumentGroup: CreateDocumentGroup,
    getDocuments: GetDocuments,
    createDocuments: CreateDocuments,
    deleteDocument: DeleteDocument,
    deleteDocumentGroup: DeleteDocumentGroup,
    deleteDocumentWithTag: DeleteDocumentWithTag,
    getDocumentGroupContact: GetDocumentGroupContact,
    updateDocumentGroupContact: UpdateDocumentGroupContact,
  };
}
