import { useMutation, useQuery, useQueryClient } from 'react-query';
import backend from 'network/BackendFetchAdapter';
import { CandidatureShort, MarketPlaceCandidature } from 'types/Candidature';
import sessionManager from 'services/sessionManager';
import { AssignToDocumentGroup } from 'types/forms/AssignToDocumentGroup';
import { Candidature } from 'types/CandidatureBase';
import { PossibleVisitSlot } from 'types/VisitSlot';
import { Garant } from 'types/Garant';
import { Contact } from 'types/Contact';

export function useCandidatureBackend() {
  const queryClient = useQueryClient();

  const GetCandidatures = (enabled = true) => useQuery<CandidatureShort[]>(
    ['getCandidatures', sessionManager.getSession()?.user_id],
    async () => {
      const response: CandidatureShort[] = await backend.fetchJson(
        '/api/client/candidatures',
      );
      return response;
    },
    { enabled },
  );

  const GetCandidature = (candidatureId: string, enabled = true) => useQuery<CandidatureShort>(
    ['getCandidature', sessionManager.getSession()?.user_id, candidatureId],
    async () => {
      const response: CandidatureShort = await backend.fetchJson(
        `/api/client/candidatures/${candidatureId}`,
      );
      return response;
    },
    { enabled },
  );

  const GetCandidaturePossibleVisitSlot = (
    candidatureId: string,
    enabled = true,
  ) => useQuery<PossibleVisitSlot[]>(
    ['getCandidaturePossibleVisitSlot', sessionManager.getSession()?.user_id, candidatureId],
    async () => {
      const response: PossibleVisitSlot[] = await backend.fetchJson(
        `/api/client/candidatures/${candidatureId}/possible-slots`,
      );
      return response;
    },
    { enabled },
  );

  const AssignDocumentGroup = useMutation<void, any, {
    data: AssignToDocumentGroup[],
    candidatureId: string,
  }>(
    async ({ data, candidatureId }) => {
      const response = await backend.post(`/api/client/candidatures/${candidatureId}/documentGroup`, data);
      return response;
    }, {
      onSuccess: () => {
        queryClient.refetchQueries(['getCandidatures', sessionManager.getSession()?.user_id]);
      },
    },
  );

  const CreateCandidature = useMutation<Candidature, any, {
    data: MarketPlaceCandidature,
  }>(
    async ({ data }) => {
      const response = await backend.post('/api/client/candidatures', data);
      return response;
    }, {
      onSuccess: () => {
        queryClient.refetchQueries(['getCandidatures', sessionManager.getSession()?.user_id]);
      },
    },
  );

  const UnlinkDocumentGroup = useMutation<void, any, {
    data: AssignToDocumentGroup,
    candidatureId: string,
  }>(
    async ({ data, candidatureId }) => {
      const response = await backend.post(`/api/client/candidatures/${candidatureId}/unlink`, data);
      return response;
    }, {
      onSuccess: () => {
        queryClient.refetchQueries(['getCandidatures', sessionManager.getSession()?.user_id]);
      },
    },
  );

  const DeleteContact = useMutation<void, any, {
    contactId: string,
    candidatureId: string,
    documentGroupId?: string,
  }>(
    async ({ contactId, candidatureId }) => {
      const response = await backend.delete(`/api/client/candidatures/${candidatureId}/contacts/${contactId}`);
      return response;
    }, {
      onSuccess: (_, { documentGroupId }) => {
        queryClient.refetchQueries(['getCandidatures', sessionManager.getSession()?.user_id]);
        if (documentGroupId) {
          queryClient.refetchQueries(['getDocumentGroupContact', sessionManager.getSession()?.user_id, documentGroupId]);
        }
      },
    },
  );

  const DeleteGarant = useMutation<void, any, {
    garantId: string,
    candidatureId: string,
    documentGroupId?: string,
  }>(
    async ({ garantId, candidatureId }) => {
      const response = await backend.delete(`/api/client/candidatures/${candidatureId}/garants/${garantId}`);
      return response;
    }, {
      onSuccess: (_, { documentGroupId }) => {
        queryClient.refetchQueries(['getCandidatures', sessionManager.getSession()?.user_id]);
        if (documentGroupId) {
          queryClient.refetchQueries(['getDocumentGroupContact', sessionManager.getSession()?.user_id, documentGroupId]);
        }
      },
    },
  );

  const CreateContact = useMutation<void, any, {
    data: {
      contact?: Contact,
      documentGroupId?: string,
      candidatureId?: string,
    },
  }>(
    async ({ data }) => {
      const response = await backend.post('/api/client/document-groups/contacts', data);
      return response;
    }, {
      onSuccess: () => {
        queryClient.refetchQueries(['getDocumentGroups', sessionManager.getSession()?.user_id]);
        queryClient.refetchQueries(['getCandidatures', sessionManager.getSession()?.user_id]);
      },
    },
  );

  const CreateGarant = useMutation<void, any, {
    data: {
      garant?: Garant,
      documentGroupId?: string,
      candidatureId?: string,
    },
  }>(
    async ({ data }) => {
      const response = await backend.post('/api/client/document-groups/garants', data);
      return response;
    }, {
      onSuccess: () => {
        queryClient.refetchQueries(['getDocumentGroups', sessionManager.getSession()?.user_id]);
        queryClient.refetchQueries(['getCandidatures', sessionManager.getSession()?.user_id]);
      },
    },
  );

  const UpdateCandidature = useMutation<Candidature, any, {
    data: Partial<Candidature>,
    candidatureId: string,
  }>(
    async ({ data, candidatureId }) => {
      const response = await backend.patch(`/api/client/candidatures/${candidatureId}`, data);
      return response;
    }, {
      onSuccess: () => {
        queryClient.refetchQueries(['getCandidatures', sessionManager.getSession()?.user_id]);
      },
    },
  );

  const ShareDocumentGroup = useMutation<void, any, {
    data: AssignToDocumentGroup[],
    candidatureId: string,
  }>(
    async ({ data, candidatureId }) => {
      const response = await backend.post(`/api/client/candidatures/${candidatureId}/share`, data);
      return response;
    }, {
      onSuccess: () => {
        queryClient.refetchQueries(['getCandidatures', sessionManager.getSession()?.user_id]);
      },
    },
  );

  return {
    getCandidaturePossibleVisitSlot: GetCandidaturePossibleVisitSlot,
    getCandidature: GetCandidature,
    getCandidatures: GetCandidatures,
    updateCandidature: UpdateCandidature,
    assignDocumentGroup: AssignDocumentGroup,
    unlinkDocumentGroup: UnlinkDocumentGroup,
    shareDocumentGroup: ShareDocumentGroup,
    createContact: CreateContact,
    createGarant: CreateGarant,
    deleteGarant: DeleteGarant,
    deleteContact: DeleteContact,
    createCandidature: CreateCandidature,
  };
}
