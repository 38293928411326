// eslint-disable-next-line max-classes-per-file
export class FetchError {
  json_response;

  status;

  constructor(json_response, status) {
    this.json_response = json_response;
    this.status = status;
  }

  toString() {
    return `${this.status} ${this.json_response}`;
  }
}

export class BackendAuthenticationError {
  private json_response;

  constructor(json_response) {
    this.json_response = json_response;
  }
}
