import {
  DocumentData,
  DocumentTag,
  documentTagEnum,
  documentTypeEnum,
  optionalTags,
} from 'types/DocumentData';
import { DocumentGroup } from 'types/DocumentGroup';

export default class DocumentUtils {
  public static getCompletionPercentFromTagList(tagList: string): number {
    const tagListMap = tagList.split(',').reduce((acc, value) => {
      acc[value.trim().toLowerCase()] = value;
      return acc;
    }, {});
    const tagEnumList = Object.values(documentTagEnum)
      .filter((tag) => tag !== documentTagEnum.OTHER);
    return Math.floor((
      tagEnumList
        .filter((tag) => tagListMap[tag.trim().toLowerCase()]).length
      / (tagEnumList.length)) * 100);
  }

  public static getCompletionPercentFromTagGroup(group: DocumentGroup | undefined): number {
    if (!group) {
      return 0;
    }
    const tagListMap = group.tagList.split(',')
      .filter((tag) => !optionalTags.includes(tag as DocumentTag))
      .reduce((acc, value) => {
        acc[value.trim().toLowerCase()] = value;
        return acc;
      }, {});
    const tagEnumList = group.possibleTags?.split(',')
      .filter((tag) => !optionalTags.includes(tag as DocumentTag)) || [];

    return Math.floor((
      tagEnumList
        .filter((tag) => tagListMap[tag.trim().toLowerCase()]).length
      / (tagEnumList.length)) * 100);
  }

  public static getFileName(link: string | undefined): string {
    if (!link) {
      return '';
    }
    return decodeURI(link.split('/').slice(-1)[0] || '');
  }

  public static getFileNumber(link: string | undefined): number {
    const filename = DocumentUtils.getFileName(link);
    const fileOrder = filename.split('-').splice((-1))[0].split('.')[0];
    return parseInt(fileOrder, 10);
  }

  public static getBase64(file): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  }

  public static getCompletionIndicator(documentList: DocumentData[] | undefined): string {
    if (documentList === undefined || documentList.length === 0) {
      return '';
    }
    if (documentList.length === 1 && documentList[0].type === documentTypeEnum.COMMENT) {
      return 'partial';
    }
    return 'complete';
  }
}
