import { useMutation, useQuery } from 'react-query';
import backend from 'network/BackendFetchAdapter';
import { Visit, VisitDetailed } from 'types/Visit';
import sessionManager from 'services/sessionManager';

export function useVisitBackend() {
  const GetVisits = (enabled = true) => useQuery<VisitDetailed[]>(
    ['getVisits', sessionManager.getSession()?.user_id],
    async () => {
      const response: VisitDetailed[] = await backend.fetchJson(
        '/api/client/visits',
      );
      return response;
    },
    { enabled },
  );

  const GetVisit = (visitId: string, enabled = true) => useQuery<VisitDetailed>(
    ['getVisit', sessionManager.getSession()?.user_id, visitId],
    async () => {
      const response: VisitDetailed = await backend.fetchJson(
        `/api/client/visits/${visitId}`,
      );
      return response;
    },
    { enabled },
  );

  const UpdateVisit = useMutation<Visit, any, {
    visitId: string,
    data: Partial<Visit>,
  }>(
    async ({ visitId, data }) => {
      const response = await backend.patch(`/api/client/visits/${visitId}`, data);
      return response;
    },
  );

  return {
    getVisits: GetVisits,
    getVisit: GetVisit,
    updateVisit: UpdateVisit,
  };
}
