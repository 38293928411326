import { DocumentGroup } from 'types/DocumentGroup';
import { ContractType, SituationType } from 'types/forms/CandidateForm';

export interface Garant {
  id: string,
  enterprise: string,
  order?: number
  profession?: string
  monthlyIncome?: number
  trialPeriod?: boolean
  name?: string
  amount?: number
  type?: GarantType
  firstname?: string
  lastname?: string
  documentGroupID?: string,
  documentGroup?: DocumentGroup,
  email?: string,
  phoneNumber?: string
  situation?: SituationType
  leaseType: ContractType
  manuallyAssigned?: boolean,
  isShared?: boolean
}

export interface GarantWithPotentialDocument extends Garant {
  potentialDocumentGroup?: Garant,
}

export interface GarantFrom {
  id: string,
  enterprise: string,
  order?: number
  profession?: string
  monthlyIncome?: string
  trialPeriod?: string
  name?: string
  amount?: string
  type?: GarantType
  firstname?: string
  lastname?: string
  email?: string,
  phoneNumber?: string
  situation?: SituationType
  leaseType: ContractType
}

export type GarantType = 'INSURANCE' | 'BANK' | 'ENTERPRISE' | 'PERSON';

type GarantTypeEnum = {
  [key in GarantType]: key
};

export const garantTypeEnum: GarantTypeEnum = {
  INSURANCE: 'INSURANCE',
  BANK: 'BANK',
  ENTERPRISE: 'ENTERPRISE',
  PERSON: 'PERSON',
};
