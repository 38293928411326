import { observable, WritableObservable } from 'micro-observables';

export class SignInService {
  private showSignInModal: WritableObservable<boolean> = observable(false);

  private propertyIdAction: string = '';

  setShowSignInModal(showModal: boolean, propertyId?: string) {
    if (propertyId !== undefined) {
      this.propertyIdAction = propertyId;
    }
    this.showSignInModal.set(showModal);
  }

  getShowSignInModal() {
    return this.showSignInModal.readOnly();
  }

  getPropertyIdAction() {
    return this.propertyIdAction;
  }
}

const signInService = new SignInService();
export default signInService;
