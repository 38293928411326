// TODO Refacto Types
export interface SearchFormType {
  name: string
  postalCodes: string,
  priceMin?: string,
  priceMax?: string,
  areaMin?: string,
  areaMax?: string,
  propertyType?: string,
  rooms?: string,
  bedrooms?: string,
  furnished?: string,
  active?: boolean,
}

export interface SearchType {
  postalCodes?: string,
  priceMin?: number,
  priceMax?: number,
  areaMin?: number,
  areaMax?: number,
  propertyType?: string,
  rooms?: string,
  furnished?: boolean,
  bedrooms?: string,
  active?: boolean,
}

export type OrderType = 'asc' | 'desc';

type OrderTypeEnum = {
  [key in OrderType]: key
};

export const orderTypeEnum: OrderTypeEnum = {
  asc: 'asc',
  desc: 'desc',
};

export type SortType = 'price' | 'area' | 'published_at' | 'price_asc' | 'area_asc' | 'published_at_asc';

type SortTypeEnum = {
  [key in SortType]: key
};

export const sortTypeEnum: SortTypeEnum = {
  published_at: 'published_at',
  published_at_asc: 'published_at_asc',
  price: 'price',
  price_asc: 'price_asc',
  area: 'area',
  area_asc: 'area_asc',
};

export interface SearchSortType {
  field: string,
}

export interface ClientSearchProperty {
  isShortList: boolean,
  isTrash: boolean
}

export interface SearchAlert extends SearchType {
  excludedIds?: string[]
}

export interface SearchTypeDetails extends SearchAlert {
  name: string
}

export interface ClientSearch {
  id: string,
  name: string,
  createdAt: string,
  alert: SearchType,
}

export interface PropertyData {
  seen?: boolean
}

export interface PropertySearchData {
  isShortlist?: boolean,
  isTrash?: boolean,
}

// TODO Refacto with propertyType
export interface PropertySearch {
  id: string,
  createdAt: string,
  updatedAt: string,
  publishedAt?: string,
  title: string,
  description: string,
  postalCode: string,
  city: string,
  imageURL?: string,
  price?: number,
  area?: number,
  rooms?: number,
  bedrooms?: number,
  floor?: number,
  elevator?: boolean,
  furnished?: boolean,
  sourceURL: string,
  address: string,
  available?: boolean,
  originalPrice?: number,
  propertyType: string,
  latitude?: number,
  longitude?: number,
  locationType?: LocationType,
  squareMeterPrice?: number,
  source: string,
  isAgency: boolean,
  agencyName?: string,
  mainContact?: number,
  originalID?: string,
  contactName?: string,
  contactMail?: string,
  contactPhone?: string,
  lockedFields?: string,
  isScam: boolean,
  issueReport?: string,
  sourceWebsite: string,
  proID?: string,
  fluximmoUpdatedAt?: string,
  meloUpdatedAt?: string,
  hunterUpdatedAt?: string,
  proUpdatedAt?: string,
  dpe?: string,
  ges?: string,
  balcony?: boolean,
  terrace?: boolean,
  pool?: boolean,
  parking?: boolean,
  jardin?: boolean,
  landSurface?: number,
  isSwtPro: boolean,
  data?: PropertyData,
  searchData?: PropertySearchData,
}

export interface PropertyDuplicate {
  duplicateURL: string,
}

export interface PropertySearchDetail extends PropertySearch {
  duplicates?: PropertyDuplicate[]
}

export interface CountByDay {
  avgPropertiesDay: number,
}

export type LocationType = 'ROOFTOP' | 'RANGE_INTERPOLATED' | 'GEOMETRIC_CENTER' | 'APPROXIMATE';

type LocationTypeEnum = { [key in LocationType]: key };

export const locationType: LocationTypeEnum = {
  ROOFTOP: 'ROOFTOP', // indicates that the returned result is a precise geocode for which we have location information accurate down to street address precision.
  RANGE_INTERPOLATED: 'RANGE_INTERPOLATED', // indicates that the returned result reflects an approximation (usually on a road) interpolated between two precise points (such as intersections). Interpolated results are generally returned when rooftop geocodes are unavailable for a street address.
  GEOMETRIC_CENTER: 'GEOMETRIC_CENTER', // indicates that the returned result is the geometric center of a result such as a polyline (for example, a street) or polygon (region).
  APPROXIMATE: 'APPROXIMATE', // indicates that the returned result is approximate.
};
