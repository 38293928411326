import React, { useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import Messages from 'services/i18n/Messages';

type Props = {
  descriptionProps?: string
  maxCharDisplayed?: number,
};

export default function Description({ descriptionProps, maxCharDisplayed }: Props) {
  const [showAll, setShowAll] = useState(false);
  const description = descriptionProps?.replaceAll('\\n', '\n');
  const displayNChar = maxCharDisplayed || 20;

  if (!description) {
    return null;
  }
  return (
    <div className="candidate-section">
      <h4>{Messages.t('property.description.description')}</h4>
      <div className="candidate-description">
        <div className="description">
          {(description && (description.length > displayNChar ? (
            showAll
              ? description
              : `${description?.split(' ').join(' ').slice(0, displayNChar)}...`
          ) : (
            description
          )))}
        </div>
        {description && description.length > displayNChar && (
          <button className="button-description" type="button" onClick={() => setShowAll(((prevState) => !prevState))}>
            {
              showAll ? (
                <b>
                  <ChevronLeftIcon />{Messages.t('property.description.hide')}
                </b>
              ) : (
                <b>{Messages.t('property.description.showMore')}<ChevronRightIcon /></b>
              )
            }
          </button>
        )}
      </div>
    </div>
  );
}
