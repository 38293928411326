import React, { useRef, useState } from 'react';
import Messages from 'services/i18n/Messages';
import {
  DocumentData,
  documentSection,
  DocumentSection,
  DocumentTag,
  documentTypeEnum,
  optionalTags,
} from 'types/DocumentData';
import {
  CheckCircle,
  ChevronRight,
  RadioButtonUnchecked, Report, Warning,
} from '@material-ui/icons';
import { DocumentGroup } from 'types/DocumentGroup';
import DocumentUtils from 'services/DocumentUtils';

type Props = {
  documentGroup: DocumentGroup,
  section: DocumentSection
  documentsByTags: { [key: string]: DocumentData[] | undefined }
  selectedTag: DocumentTag | null
  setSelectedTag: (value: DocumentTag | null) => void
};

export default function DocumentSectionRow(
  {
    section,
    documentsByTags,
    documentGroup,
    selectedTag,
    setSelectedTag,
  }: Props,
) {
  const sectionTags = documentSection[section]
    .filter((tag) => documentGroup.possibleTags?.split(',').includes(tag));

  const mandatorySectionTags = sectionTags
    .filter((tag) => !optionalTags.includes(tag));

  const sectionNumber = mandatorySectionTags
    .reduce((acc, value) => {
      if (documentsByTags[value]) {
        return acc + 1;
      }
      return acc;
    }, 0);

  const containComment = mandatorySectionTags
    .reduce((acc, value) => {
      if (documentsByTags[value]) {
        if (documentsByTags[value]?.length === 1
          && documentsByTags[value]?.[0].type === documentTypeEnum.COMMENT) {
          return true;
        }
      }
      return acc;
    }, false);

  const containEmptyComment = mandatorySectionTags
    .reduce((acc, value) => {
      if (documentsByTags[value]) {
        if (documentsByTags[value]?.length === 1
          && documentsByTags[value]?.[0].type === documentTypeEnum.COMMENT
          && (!documentsByTags[value]?.[0].comment || documentsByTags[value]?.[0].comment === '')) {
          return true;
        }
      }
      return acc;
    }, false);

  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const containerHeight = (isOpen) ? ref.current?.offsetHeight : 0;

  const extendStep = () => {
    setIsOpen(((prevState) => !prevState));
  };

  const hasAtLeastOnDocument = sectionTags
    .some((key) => (documentsByTags[key]?.length || 0) > 0);

  return (
    <div className="extendable-container">
      <div
        className={`selector-row ${(selectedTag && sectionTags.length === 1 && sectionTags.includes(selectedTag)) ? 'selected' : ''}`}
      >
        <div
          className={`completion-indicator ${sectionNumber === mandatorySectionTags.length && hasAtLeastOnDocument
            ? (containComment ? 'partial' : 'complete')
            : ''
          } ${
            !sectionTags.some((tag) => !optionalTags.includes(tag))
              ? 'optional'
              : ''
          }`}
        />
        <button
          type="button"
          onClick={
            sectionTags.length > 1
              ? extendStep
              : (() => setSelectedTag(sectionTags[0]))
          }
          className="content"
        >
          <div
            className={`section-title ${sectionNumber === mandatorySectionTags.length ? (containComment ? 'partial' : 'complete') : ''}`}
          >
            <div className="document-section-title-content">
              {
                sectionTags.some((tag) => !optionalTags.includes(tag)) && (
                  sectionNumber === mandatorySectionTags.length
                    ? (containComment ? <Report /> : <CheckCircle />)
                    : <RadioButtonUnchecked />
                )
              }
              <div>
                {Messages.t(`document.section.${section}`)}
              </div>
              <div>
                {
                  sectionTags.length > 1 && (
                    `${sectionNumber}/${mandatorySectionTags.length}`
                  )
                }
              </div>
            </div>
            {
              containEmptyComment && sectionTags.length <= 1 && (
                <div className="no-message-indicator">
                  <Warning />
                  {Messages.t('document.missing.help')}
                </div>
              )
            }
          </div>
          {
            sectionTags.length > 1 && (
              <ChevronRight className={`extend-icon ${isOpen ? 'extended' : ''}`} />
            )
          }
        </button>
      </div>
      <div style={containerHeight !== undefined ? { height: containerHeight } : {}} className="list-container">
        <div ref={ref}>
          {
            sectionTags
              .map((tag) => (
                <div
                  key={tag}
                  className={`selector-row selector-sub-row docs ${(selectedTag && tag === selectedTag) ? 'selected' : ''}`}
                >
                  <div
                    className={`completion-indicator ${
                      DocumentUtils.getCompletionIndicator(documentsByTags[tag])
                    }  ${
                      optionalTags.includes(tag)
                        ? 'optional'
                        : ''
                    }`}
                  />
                  <button onClick={() => setSelectedTag(tag)} type="button" className="content">
                    <div
                      className={`section-title ${DocumentUtils.getCompletionIndicator(documentsByTags[tag])}`}
                    >
                      <div className="document-section-title-content">
                        {
                          !optionalTags.includes(tag) && (
                            !!documentsByTags[tag]
                              ? (DocumentUtils.getCompletionIndicator(documentsByTags[tag]) === 'partial'
                                ? <Report />
                                : <CheckCircle />)
                              : <RadioButtonUnchecked />
                          )
                        }
                        {Messages.t(`document.tag.${tag}`)}
                      </div>
                      {
                        documentsByTags?.[tag]?.[0]?.type === documentTypeEnum.COMMENT
                        && (documentsByTags?.[tag]?.[0]?.comment === ''
                          || !documentsByTags?.[tag]?.[0]?.comment) && (
                          <div className="no-message-indicator">
                            <Warning />
                            {Messages.t('document.missing.help')}
                          </div>
                        )
                      }
                    </div>
                  </button>
                </div>
              ))
          }
        </div>
      </div>
    </div>
  );
}
