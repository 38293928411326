import React from 'react';
import Button from 'theme/Button';
import Messages from 'services/i18n/Messages';
import { PublicAgency } from 'types/Agency';
import Image from 'theme/Image';

type Props = {
  goToNextPage: () => void,
  agency?: PublicAgency,
};

export default function FormStartPage(
  {
    goToNextPage,
    agency,
  }: Props,
) {
  return (
    <div className="form-text-page">
      <div className="form-text-content-container">
        <div className="form-text-content">
          <h2 className="form-text agency-name">
            {agency?.name}
          </h2>
          <div className="image-container">
            <Image src={agency?.imageURL} alt="agency" />
          </div>
          <div className="form-text">
            {Messages.t('form.start.hello')}
          </div>
          <div className="form-text">
            {Messages.t('form.start.organize')}
          </div>
          <div className="form-text">
            {Messages.t('form.start.justification')}
          </div>
          <div className="form-text">
            {Messages.t('form.start.team')}
          </div>
          <Button onClick={() => goToNextPage()}>
            {Messages.t('formButton.start')}
          </Button>
          <div className="form-text form-text-subtitle">
            {Messages.t('form.start.subPart1')}
          </div>
        </div>
      </div>
    </div>
  );
}
