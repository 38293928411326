export interface Pinel {
  id: string,
  foyerComposition: FoyerComposition,
  zoneA: number,
  zoneABis: number,
  zoneB1: number,
  zoneB2: number,
  zoneC: number,
}

export type FoyerComposition = 'SOLO' | 'COUPLE' | 'NCHILD' | 'UNKNOWN';

export type FoyerCompositionEnum = {
  [key in FoyerComposition]: key
};

export const foyerCompositionEnum: FoyerCompositionEnum = {
  SOLO: 'SOLO',
  COUPLE: 'COUPLE',
  NCHILD: 'NCHILD',
  UNKNOWN: 'UNKNOWN',
};
