import React, { useState } from 'react';
import { CandidatureShort } from 'types/Candidature';
import Image from 'theme/Image';
import Messages from 'services/i18n/Messages';
import DateUtils from 'services/DateUtils';
import { Link, useLocation } from 'react-router-dom';
import { detectMobile } from 'services/utils';
import PropertyUtils from 'services/PropertyUtils';
import { VisitDetailed } from 'types/Visit';
import { Routes } from 'routes/RoutesUtils';
import { PROPERTIES_DETAILS, PROPERTY_ID } from 'routes/Routes';
import { TemplateIcon } from '@heroicons/react/outline';
import {
  AspectRatio,
  BedOutlined,
  Bolt,
  LuggageOutlined,
} from '@material-ui/icons';
import PropertyCandidatureTags from 'pages/common/PropertyCandidatureTags';
import VisitTag from 'pages/client-app/candidature/candidatureList/VisitTag';
import { Popover } from '@material-ui/core';
import { useCandidatureBackend } from 'network/queries/CandidatureQueries';
import { NotificationService } from 'lib/notification';
import {
  DocumentStatusType,
  documentStatusTypeEnum,
  VisitStatusType,
  visitStatusTypeEnum,
} from 'types/CandidatureBase';

type Props = {
  candidature: CandidatureShort,
  visits: VisitDetailed[],
};

export default function CandidatureItem(
  {
    candidature,
    visits,
  }: Props,
) {
  const [submitting, setSubmitting] = useState(false);
  const location = useLocation();
  const isSelected = location.pathname.includes(candidature.id);
  const candidaturesQueries = useCandidatureBackend();
  const { updateCandidature } = candidaturesQueries;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const refuseCandidate = (candidatureId: string) => {
    setSubmitting(true);
    updateCandidature.mutateAsync({ data: { isRefused: true }, candidatureId })
      .then(() => {
        NotificationService.notifySuccess(Messages.t('notifications.update'));
        handleClose();
      })
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => setSubmitting(false));
  };

  const updateCandidatureDocumentStatus = (
    documentsStatus: DocumentStatusType,
    candidatureId: string,
  ) => {
    setSubmitting(true);
    updateCandidature.mutateAsync({ data: { documentsStatus, isRefused: false }, candidatureId })
      .then(() => {
        NotificationService.notifySuccess(Messages.t('notifications.update'));
        handleClose();
      })
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => setSubmitting(false));
  };

  const updateCandidatureVisitStatus = (
    visitStatus: VisitStatusType,
    candidatureId: string,
  ) => {
    setSubmitting(true);
    updateCandidature.mutateAsync({ data: { visitStatus, isRefused: false }, candidatureId })
      .then(() => {
        NotificationService.notifySuccess(Messages.t('notifications.update'));
        handleClose();
      })
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => setSubmitting(false));
  };

  return (
    <div
      className={`candidature-item ${(isSelected && !detectMobile()) ? 'selected' : ''}`}
    >
      {
        open && (
          <Popover
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <div className="tag-selector-container">
              <button
                disabled={submitting}
                onClick={() => updateCandidatureVisitStatus(
                  visitStatusTypeEnum.PROPOSED,
                  candidature.id,
                )}
                type="button"
                className={!candidature.isRefused && candidature.visitStatus === visitStatusTypeEnum.PROPOSED ? 'selected' : ''}
              >
                {Messages.t('pageSelector.proposed')}
              </button>
              <button
                disabled={submitting}
                onClick={() => updateCandidatureVisitStatus(
                  visitStatusTypeEnum.BOOKED,
                  candidature.id,
                )}
                className={!candidature.isRefused && candidature.visitStatus === visitStatusTypeEnum.BOOKED ? 'selected' : ''}
                type="button"
              >
                {Messages.t('pageSelector.futurVisit')}
              </button>
              <button
                disabled={submitting}
                onClick={() => updateCandidatureVisitStatus(
                  visitStatusTypeEnum.VIEWED,
                  candidature.id,
                )}
                className={!candidature.isRefused && candidature.visitStatus === visitStatusTypeEnum.VIEWED ? 'selected' : ''}
                type="button"
              >
                {Messages.t('pageSelector.pastVisit')}
              </button>
              <button
                disabled={submitting}
                onClick={() => updateCandidatureDocumentStatus(
                  documentStatusTypeEnum.SENT,
                  candidature.id,
                )}
                className={!candidature.isRefused && candidature.documentsStatus === documentStatusTypeEnum.SENT ? 'selected' : ''}
                type="button"
              >
                {Messages.t('document.status.sent')}
              </button>
              <button
                disabled={submitting}
                onClick={() => updateCandidatureDocumentStatus(
                  documentStatusTypeEnum.ACCEPTED,
                  candidature.id,
                )}
                className={!candidature.isRefused && candidature.documentsStatus === documentStatusTypeEnum.ACCEPTED ? 'selected' : ''}
                type="button"
              >
                {Messages.t('document.status.accepted')}
              </button>
              <button
                disabled={submitting}
                onClick={() => refuseCandidate(candidature.id)}
                className={candidature.isRefused ? 'selected' : ''}
                type="button"
              >
                {Messages.t('document.status.refused')}
              </button>
            </div>
          </Popover>
        )
      }
      <div className="candidature-info-header">
        <div
          className="tag-container"
          role="presentation"
          onClick={(e) => {
            if (!candidature.propertyID) {
              handleClick(e);
            }
          }}
        >
          <PropertyCandidatureTags hideSwtProTag candidature={candidature} visits={visits} />
        </div>
        <div>
          {
            candidature.createdAt
            && Messages.t('candidature.postulationDate', { date: DateUtils.getPrettyDateDeltaFromNow(candidature.createdAt, true) })
          }
        </div>
      </div>
      {
        isSelected && (
          <div className="agency-name">{candidature.agency.name}</div>
        )
      }
      <div className="property-image">
        <Image
          src={PropertyUtils.getPropertyMainImage(candidature.property)}
          alt="property"
        />
        <div className="image-overlay">
          {
            candidature.propertyID && (
              <Bolt />
            )
          }
        </div>
      </div>
      <div className="candidature-info-content">
        <div className="candidature-info">
          <div className="candidature-info-body">
            {
              candidature.property ? (
                <div className="property-data">
                  <div className="property-city-container">
                    <div className="property-city">
                      {candidature.property?.city}
                    </div>
                    {
                      candidature.swtID && (
                        <Link
                          onClick={(e) => e.stopPropagation()}
                          to={
                            Routes.withPath(
                              PROPERTIES_DETAILS,
                              [{ label: PROPERTY_ID, value: candidature.swtID }],
                            )
                          }
                          className="property-city-container"
                        >
                          {Messages.t('property.showDetails')}
                        </Link>
                      )
                    }
                  </div>
                  <div className="property-info-body">
                    <div className="property-info-container">
                      {
                        !!candidature.property.rooms && (
                          <div className="property-info">
                            <TemplateIcon />
                            <span> {Messages.t('property.rooms', { smart_count: candidature.property.rooms })}</span>
                          </div>
                        )
                      }
                      {
                        !!candidature.property.bedrooms && (
                          <div className="property-info">
                            <BedOutlined />
                            <span> {Messages.t('property.bedroom', { smart_count: candidature.property.bedrooms })}</span>
                          </div>
                        )
                      }
                      {
                        !!candidature.property.area && (
                          <div className="property-info">
                            <AspectRatio />
                            <span> {Messages.t('property.area', { smart_count: candidature.property.area })}</span>
                          </div>
                        )
                      }
                      {
                        !!candidature.property.furnished && (
                          <div className="property-info">
                            <LuggageOutlined />
                            <span>{Messages.t('property.furnished')}</span>
                          </div>
                        )
                      }
                    </div>
                    <div className="property-price">
                      {candidature.property.price}€
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  {Messages.t('candidature.noProperty')}
                </div>
              )
            }
          </div>
        </div>
      </div>
      <VisitTag visits={visits} />
    </div>
  );
}
