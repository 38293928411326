export const DASHBOARD = '/';
export const ID = ':id';
export const VISIT_ID = ':visitId';
export const PROPERTY_ID = ':propertyId';

export const EXPIRED = 'expired';

export const SEARCHES = '/searches';
export const SEARCH_CREATE = `${SEARCHES}/create`;
export const SEARCH_CREATE_LANDING = `${SEARCH_CREATE}/landing`;
export const SEARCH_DETAILS = `${SEARCHES}/${ID}`;
export const SEARCH_DETAILS_SHORTLIST = `${SEARCH_DETAILS}/shortlist`;
export const SEARCH_PROPERTY = `${SEARCH_DETAILS}/properties/${PROPERTY_ID}`;

export const CANDIDATURES = '/candidatures';
export const CANDIDATURE_DETAILS = `${CANDIDATURES}/${ID}`;
export const CANDIDATURE_WITH_OPTIONAL_ID = `${CANDIDATURE_DETAILS}?`;

export const VISITS = '/visits';
export const VISITS_PAST = `${VISITS}/past`;

export const VISITS_DETAILS = `${VISITS}/details/${ID}`;
export const VISITS_DETAILS_CANCEL = `${VISITS}/details/${ID}/cancel`;

export const PARAMETERS = '/parameters';
export const PARAMETERS_PASSWORD = `${PARAMETERS}/password`;

export const VISIT_BOOKING = `/visit-booking/${ID}/visits/${VISIT_ID}`;
export const VISIT_BOOKING_WITH_OPTIONAL_VISIT_ID = `${VISIT_BOOKING}?`;

export const VISIT_BOOKING_CONFIRM = `${VISIT_BOOKING}/confirm`;

export const APPLICATION = '/application';
export const APPLICATION_DETAILS = `${APPLICATION}/${ID}`;
export const APPLICATION_DETAILS_INFO = `${APPLICATION}/${ID}/infos`;

export const APPLICATION_WITH_OPTIONAL_ID = `${APPLICATION_DETAILS}?`;

export const APPLICATION_DOCUMENT = `${APPLICATION_DETAILS}/documents`;
export const DOCUMENTS_DETAILS_UPLOAD = `${APPLICATION_DOCUMENT}/upload`;

export const APPLICATION_CANDIDATURE = `${APPLICATION_DETAILS}/candidatures`;

export const LOG_IN = '/login';
export const SIGN_IN = '/signin';
export const CANDIDATE_FORM = `/agency/${ID}`;
export const CANDIDATE_FORM_ENDING = `${CANDIDATE_FORM}/ending`;

export const PROPERTIES = '/properties';
export const PROPERTIES_DETAILS = `${PROPERTIES}/${PROPERTY_ID}`;
