import React, { ReactNode } from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import Button from 'theme/Button';
import Messages from 'services/i18n/Messages';
import confirmationService from 'services/ConfirmationService';
import DialogWrapper from 'pages/common/DialogWrapper';

type Props = {
  title?: string,
  message: ReactNode,
  actionColor?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'
  actionMessage?: string,
  inverseButton?: boolean
  cancelMessage?: string,
  columnButton?: boolean,
};

// TODO move to lib
export default function ConfirmationModalBase(
  {
    title,
    message,
    actionColor,
    actionMessage,
    inverseButton,
    cancelMessage,
    columnButton,
  }: Props,
) {
  const buttons = [
    (
      <Button color={actionColor} onClick={() => confirmationService.onConfirm()}>
        {actionMessage || Messages.t('formButton.confirm')}
      </Button>
    ),
    (
      <Button onClick={() => confirmationService.onClose()}>
        {cancelMessage || Messages.t('formButton.cancel')}
      </Button>
    ),
  ];

  return (
    <DialogWrapper
      onPointerUp={(e) => {
        e.stopPropagation();
      }}
      onMouseUp={(e) => {
        e.stopPropagation();
      }}
      onTouchEnd={(e) => {
        e.stopPropagation();
      }}
      open
      onClose={() => confirmationService.onClose()}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {message}
      </DialogContent>
      <DialogActions>
        <div className={columnButton ? 'confirmation-column-button confirmation-button' : 'confirmation-button'}>
          {
            !inverseButton ? (
              <>
                {buttons[1]}
                {buttons[0]}
              </>
            ) : (
              <>
                {buttons[0]}
                {buttons[1]}
              </>
            )
          }
        </div>
      </DialogActions>
    </DialogWrapper>
  );
}
