import React, { useEffect, useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import Messages from 'services/i18n/Messages';
import { SearchFormType, SearchTypeDetails } from 'types/SearchType';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { usePropertiesBackend } from 'network/queries/PropertyQueries';
import {
  ChartOptions, ChartData,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Slider } from '@material-ui/core';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';

type Props = {
  search: SearchTypeDetails | undefined
  control: Control<SearchFormType>
  isMaxRange: boolean,
  formField: SearchFormType,
  setIsMaxRange: (isMaxRange: boolean) => void,
  setIsMinRange: (isMaxRange: boolean) => void,
  apiErrors?: { [key: string]: string[] }
  errors?: FieldErrors,
  // TODO should refacto that to use the control props instead
  setValue: UseFormSetValue<SearchFormType>
};

const MIN_RANGE = 0;
const MAX_RANGE = 200;
const RANGE_DELTA = MAX_RANGE - MIN_RANGE;
const BAR_NUMBER = 50;
const PERCENT_MULTIPLIER = RANGE_DELTA / 100;

// TODO Refacto this as a component
export default function AreaRangeInputWrapper(
  {
    isMaxRange,
    setIsMaxRange,
    setIsMinRange,
    formField,
    search,
    control,
    apiErrors,
    errors,
    setValue,
  }: Props,
) {
  const propertyQueries = usePropertiesBackend();
  const { getAreaQuantils } = propertyQueries;

  const [dataValue, setDataValue] = useState([
    formField.areaMin ? (Number(formField.areaMin) - MIN_RANGE) / PERCENT_MULTIPLIER : 0,
    formField.areaMax ? (Number(formField.areaMax) - MIN_RANGE) / PERCENT_MULTIPLIER : 100,
  ]);
  // This weird way to manage values is like that for performance issue
  const [doUpdateForm, setDoUpdateForm] = useState(true);

  const handleChange = (event: Event, newValue: number | number[]) => {
    setDoUpdateForm(true);
    setDataValue(newValue as number[]);
  };

  useEffect(() => {
    if (!doUpdateForm) {
      setDataValue([
        formField.areaMin ? (Number(formField.areaMin) - MIN_RANGE) / PERCENT_MULTIPLIER : 0,
        formField.areaMax ? (Number(formField.areaMax) - MIN_RANGE) / PERCENT_MULTIPLIER : 100,
      ]);
    }
  }, [formField.areaMin, formField.areaMax, doUpdateForm]);

  useEffect(() => {
    if (doUpdateForm) {
      setIsMinRange(dataValue[0] === 0);
      setIsMaxRange(dataValue[1] === 100);
      setValue('areaMin', (MIN_RANGE + (dataValue[0] * PERCENT_MULTIPLIER)).toString());
      setValue('areaMax', (MIN_RANGE + (dataValue[1] * PERCENT_MULTIPLIER)).toString());
    }
  }, [dataValue[0], dataValue[1], doUpdateForm]);

  const {
    data: quantils,
  } = getAreaQuantils({
    ...search,
    areaMax: undefined,
    areaMin: undefined,
  }, !!search?.postalCodes && search?.postalCodes.length > 4);

  const dataSet = [...Array(BAR_NUMBER).keys()].map((number) => ({
    range: MIN_RANGE + number * (RANGE_DELTA / BAR_NUMBER),
    count: 0,
  }));

  if (quantils) {
    quantils.forEach((quantil) => {
      if (quantil.range < dataSet[0].range) {
        dataSet[0].count += quantil.count;
      } else {
        for (let i = 0; i < dataSet.length; i++) {
          if (i === dataSet.length - 1
            || (quantil.range >= dataSet[i].range && quantil.range < dataSet[i + 1].range)) {
            dataSet[i].count += quantil.count;
            break;
          }
        }
      }
    });
  }

  const options: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    scales: {
      y: { display: false },
      x: { display: false },
    },
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  const labels = dataSet.map((quantil) => quantil.range) || [];

  const data: ChartData = {
    labels,
    datasets: [
      {
        data: dataSet?.map((quantil) => quantil.count) || [],
        backgroundColor: (context) => {
          const index = context.dataIndex;
          const value = labels[index];
          if (value < Number(formField.areaMin)
            || value > Number(formField.areaMax)) {
            return '#DCDCDC';
          }
          return '#999999';
        },
      },
    ],
  };

  return (
    <div>
      <div className="range-chart-container">
        <Bar
          // @ts-ignore
          options={options}
          // @ts-ignore
          data={data}
        />
        <div className="range-slider">
          <Slider
            value={dataValue}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="form-row-always">
        <Controller
          name="areaMin"
          rules={{ required: true }}
          control={control}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              error={errors}
              type="text"
              control={controller}
              onChange={() => {
                setDoUpdateForm(false);
                setIsMinRange(false);
              }}
              label={Messages.t('field.areaMin')}
            />
          )}
        />
        <Controller
          name="areaMax"
          rules={{ required: true }}
          control={control}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              error={errors}
              type="text"
              prefix={isMaxRange ? '+' : undefined}
              control={controller}
              onChange={() => {
                setDoUpdateForm(false);
                setIsMaxRange(false);
              }}
              label={Messages.t('field.areaMax')}
            />
          )}
        />
      </div>
    </div>
  );
}
