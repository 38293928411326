import React, { useState } from 'react';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import Messages from 'services/i18n/Messages';
import { Property } from 'types/Property';
import Image from 'theme/Image';
import CarrouselModal from 'lib/CarrouselModal';
import { detectMobile } from 'services/utils';
import PropertyUtils from 'services/PropertyUtils';

type Props = {
  property: Property
};

export default function ImageGallery({ property }: Props) {
  const [initialImage, setInitialImage] = useState<number | undefined>(undefined);
  const sortedImages = PropertyUtils.getSortedPropertyImage(property);
  if (sortedImages && sortedImages.length > 0) {
    return (
      <>
        {
          detectMobile() ? (
            <div
              role="presentation"
              className="image-container"
              onClick={() => setInitialImage(0)}
            >
              <Image
                alt={sortedImages[0].imageURL}
                src={sortedImages[0].imageURL}
              />
            </div>
          ) : (
            <div className="image-gallery">
              {
                sortedImages.slice(0, 5).map((image, index) => (
                  <div
                    key={image.id}
                    role="presentation"
                    className="image-container"
                    onClick={() => setInitialImage(index)}
                  >
                    <Image
                      alt={image.imageURL}
                      src={image.imageURL}
                    />
                  </div>
                ))
              }
            </div>
          )
        }
        {
          initialImage !== undefined && (
            <CarrouselModal
              onClose={() => setInitialImage(undefined)}
              isOpen
              intitialSelectedImage={initialImage}
              imageUrls={sortedImages.map((image) => image.imageURL)}
            />
          )
        }
      </>
    );
  }
  return (
    <div>
      <div className="">
        <br />
        <p className="text-center mt-5 mb-5 text-secondary">
          {Messages.t('property.image.noImage')} <PhotoCameraIcon />
        </p>
        <hr className="mb-3" />
      </div>
    </div>
  );
}
