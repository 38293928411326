import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query';
import {
  CountByDay, OrderType, orderTypeEnum,
  PropertySearch,
  PropertySearchDetail,
  SearchAlert, SearchSortType,
  SearchType,
} from 'types/SearchType';
import backend from 'network/BackendFetchAdapter';
import { Quantil } from 'types/Quantil';
import sessionManager from 'services/sessionManager';

export function usePropertiesBackend() {
  const queryClient = useQueryClient();

  const InvalidateSearch = () => {
    queryClient.removeQueries(['searchProperties']);
  };

  const SearchProperty = (
    search?: SearchAlert,
    sort?: SearchSortType,
    searchId?: string,
    enabled = true,
  ) => useInfiniteQuery<PropertySearch[]>(
    ['searchProperties', JSON.stringify(search), searchId, JSON.stringify(sort), sessionManager.getSession()?.user_id],
    async ({ pageParam = 0 }) => {
      let searchParam = '';
      if (searchId) {
        searchParam = `&searchId=${searchId}`;
      }
      if (sort) {
        let order: OrderType = orderTypeEnum.desc;
        let { field } = sort;
        if (sort.field.includes('_asc')) {
          order = orderTypeEnum.asc;
          field = field.split('_asc')[0];
        }
        searchParam = `${searchParam}&sortBy=${field}&sortOrder=${order}`;
      }
      const response: PropertySearch[] = await backend.post(
        `/api/client/properties/search?page=${pageParam}${searchParam}`,
        search,
      );
      return response;
    },
    {
      enabled,
      getNextPageParam: (lastPage, allPages) => lastPage.length === 30 && allPages.length + 1,
    },
  );

  const GetProperty = (
    propertyId: string,
    searchId?: string,
    enabled = true,
  ) => useQuery<PropertySearchDetail>(
    ['getPropertyDetails', propertyId, searchId, sessionManager.getSession()?.user_id],
    async () => {
      let searchParam = '';
      if (searchId) {
        searchParam = `?searchId=${searchId}`;
      }
      const response: PropertySearchDetail = await backend.fetchJson(
        `/api/client/properties/${propertyId}${searchParam}`,
      );
      return response;
    },
    {
      enabled,
    },
  );

  const GetPriceQuantils = (
    search?: SearchType,
    enabled = true,
  ) => useQuery<Quantil[]>(
    ['getPriceQuantils', JSON.stringify(search), sessionManager.getSession()?.user_id],
    async () => {
      const response: Quantil[] = await backend.post(
        '/api/client/properties/search/price-quantils',
        search,
      );
      return response;
    },
    { enabled },
  );

  const GetPropertyList = (
    list: string[],
    enabled = true,
  ) => useQuery<PropertySearch[]>(
    ['getPropertyList', JSON.stringify(list), sessionManager.getSession()?.user_id],
    async () => {
      const response: PropertySearch[] = await backend.post(
        '/api/client/properties/list',
        { idList: list },
      );
      return response;
    },
    { enabled },
  );

  const GetCountByDay = (
    search?: SearchType,
    enabled = true,
  ) => useQuery<CountByDay>(
    ['getSearchCountByDay', JSON.stringify(search), sessionManager.getSession()?.user_id],
    async () => {
      const response: CountByDay = await backend.post(
        '/api/client/properties/search/count-by-day',
        search,
      );
      return response;
    },
    { enabled },
  );

  const GetAreaQuantils = (search?: SearchType, enabled = true) => useQuery<Quantil[]>(
    ['getAreaQuantils', JSON.stringify(search), sessionManager.getSession()?.user_id],
    async () => {
      const response: Quantil[] = await backend.post(
        '/api/client/properties/search/area-quantils',
        search,
      );
      return response;
    },
    { enabled },
  );

  return {
    searchProperty: SearchProperty,
    getPriceQuantils: GetPriceQuantils,
    getAreaQuantils: GetAreaQuantils,
    getProperty: GetProperty,
    invalidateSearch: InvalidateSearch,
    getCountByDay: GetCountByDay,
    getPropertyList: GetPropertyList,
  };
}
