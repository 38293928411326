import React from 'react';
import ClientRouter from 'pages/client-app/ClientRouter';
import NavBar from 'pages/client-app/layout/NavBar';
import { detectMobile } from 'services/utils';
import { useLocation } from 'react-router-dom';
import { Routes } from 'routes/RoutesUtils';
import { SEARCH_PROPERTY } from 'routes/Routes';
import PwaInstallation from 'lib/PwaInstallation';

type Props = {
  isLoading: boolean,
  setIsLoading: (isLoading: boolean) => void
};

export default function ClientMainView({ isLoading, setIsLoading }: Props) {
  const location = useLocation();

  const isMobile = detectMobile();

  const hideNavBar = Routes.locationMatchWithPath(location, SEARCH_PROPERTY) && isMobile;

  return (
    <>
      <PwaInstallation isLoading={isLoading} setIsLoading={setIsLoading} />
      <div className="client-root">
        {
          !hideNavBar && <NavBar />
        }
        <div className="page-content">
          <ClientRouter />
        </div>
      </div>
    </>
  );
}
