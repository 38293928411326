import React, { useState } from 'react';
import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Messages from 'services/i18n/Messages';
import { AddCircleOutline, CheckCircleOutline } from '@material-ui/icons';
import Button from 'theme/Button';
import DialogWrapper from 'pages/common/DialogWrapper';
import { DocumentGroup } from 'types/DocumentGroup';

type Props = {
  potentialDocumentGroups?: DocumentGroup[],
  onClose: () => void,
  submitting: boolean,
  showAddContactModal: boolean,
  showAddGarantModal: boolean,
  createContact: (docGroupId: string) => void,
  createGarant: (docGroupId: string) => void,
  showCreateContactModal: () => void,
  showCreateGarantModal: () => void,
};

export default function DialogAddContactOrGarant(
  {
    potentialDocumentGroups,
    onClose,
    submitting,
    showAddContactModal,
    showAddGarantModal,
    createContact,
    createGarant,
    showCreateContactModal,
    showCreateGarantModal,
  }: Props,
) {
  const [potentialDocumentGroupsCopy] = useState(
    potentialDocumentGroups,
  );

  return (
    <DialogWrapper
      open
      onClose={onClose}
    >
      <DialogTitle>{Messages.t(`case.${showAddContactModal ? 'addContact' : 'addGarant'}`)}</DialogTitle>
      <DialogContent>
        <div className="modal-add-contact">
          <p>
            {
              Messages.t('case.addContact.subtitle', {
                contactType: showAddContactModal ? (
                  Messages.t('occupants.field').toLowerCase()
                ) : (
                  Messages.t('garants.field').toLowerCase()
                ),
              })
            }
          </p>
          <div className="doc-group-lines-container">
            {
              potentialDocumentGroupsCopy?.sort(
                (a, b) => a.name.localeCompare(b.name),
              ).map((document) => (
                <div key={document.id} className="doc-group-line">
                  <div className="doc-name">{document.name}</div>
                  <div>
                    {
                      potentialDocumentGroups
                      && !potentialDocumentGroups
                        .map((doc) => doc.id)
                        .includes(document.id) ? (
                          <div className="status validated"><CheckCircleOutline /> {Messages.t('case.added')}</div>
                        ) : (
                          <button
                            type="button"
                            className="status"
                            onClick={() => {
                              if (showAddContactModal) {
                                createContact(document.id);
                              } else {
                                createGarant(document.id);
                              }
                            }}
                          >
                            <AddCircleOutline /> {Messages.t('case.add')}
                          </button>
                        )
                    }
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={submitting}
          onClick={() => {
            if (showAddContactModal) {
              showCreateContactModal();
            }
            if (showAddGarantModal) {
              showCreateGarantModal();
            }
            onClose();
          }}
        >
          {Messages.t('case.create')}
        </Button>
      </DialogActions>
    </DialogWrapper>
  );
}
