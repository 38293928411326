import React, { useState } from 'react';
import {
  Link,
  useHistory,
  useParams,
} from 'react-router-dom';
import { useVisitBackend } from 'network/queries/VisitQueries';
import Messages from 'services/i18n/Messages';
import { format } from 'date-fns';
import localService from 'services/i18n/LocalService';
import {
  Check,
  DateRange,
  HighlightOff,
  History,
  PersonOutlined,
} from '@material-ui/icons';
import { LocationMarkerIcon } from '@heroicons/react/outline';
import StringUtils from 'services/StringUtils';
import {
  ID,
  VISIT_BOOKING,
  VISIT_ID,
  VISITS_DETAILS,
} from 'routes/Routes';
import AddToCalendarButton from 'pages/common/AddToCalendarButton';
import confirmationService from 'services/ConfirmationService';
import { NotificationService } from 'lib/notification';
import { Routes } from 'routes/RoutesUtils';

type Param = {
  id: string,
  visitId: string,
};

export default function VisitBookingConfirmPage() {
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);
  const { visitId, id } = useParams<Param>();
  const visitsQueries = useVisitBackend();
  const { getVisit, updateVisit } = visitsQueries;
  const { data: visit } = getVisit(visitId);

  const deleteVisit = async () => {
    const isConfirmed = await confirmationService.confirm({
      title: Messages.t('confirmation.cancelVisit.title'),
      actionColor: 'error',
      message: Messages.t('confirmation.cancelVisit.message'),
    });
    if (isConfirmed && visit) {
      setSubmitting(true);
      updateVisit.mutateAsync({
        visitId: visit.id,
        data: { isCancel: true },
      }).then(async () => {
        NotificationService.notifySuccess(Messages.t('visit.canceled'));
        history.push(Routes.withPath(VISIT_BOOKING, [{
          label: ID,
          value: id,
        }, {
          label: VISIT_ID,
          value: '',
        }]));
      }).catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
        .finally(() => setSubmitting(false));
    }
  };

  return (
    <div className="client-root">
      <div className="page-content visit-booking-page">
        <div className="application-title">
          Lokaa
        </div>
        <div className="visit-confirm-tile-container">
          <div className="visit-confirm-tile">
            <div className="check-icon-container">
              <Check />
            </div>
            <h3>
              {Messages.t('visit.confirmed')}
            </h3>
            {
              visit && (
                <AddToCalendarButton visit={visit} className="text-icon add-to-calendar-button">
                  <DateRange />
                  <div>{Messages.t('visit.addToCalendar')}</div>
                </AddToCalendarButton>
              )
            }
            <div className="visit-tile">
              {
                visit && (
                  <div className="visit-informations">
                    <div className="visit-information-row text-icon">
                      <DateRange />
                      <div>{StringUtils.capitalizeFirstLetter(format(new Date(visit.startDate), Messages.t('booking.visit.dateFormatFull'), { locale: localService.getDateLocal() }))} </div>
                    </div>
                    <div className="visit-information-row text-icon">
                      <PersonOutlined />
                      <div>{Messages.t('visit.with', { name: `${visit.agent.firstname || ''} ${visit.agent.lastname || ''}` })} </div>
                    </div>
                    <div className="visit-information-row text-icon">
                      <LocationMarkerIcon />
                      <div>{visit.address} </div>
                    </div>
                  </div>
                )
              }
              <div className="visit-tile-actions">
                <Link
                  to={
                    Routes.withPath(VISIT_BOOKING, [
                      {
                        label: ID,
                        value: id,
                      }, {
                        label: VISIT_ID,
                        value: visitId,
                      },
                    ])
                  }
                  className="text-icon visit-action"
                >
                  <History />
                  <div>{Messages.t('visit.move')}</div>
                </Link>
                <button onClick={deleteVisit} disabled={submitting} type="button" className="text-icon visit-action">
                  <HighlightOff />
                  <div>{Messages.t('visit.cancel')}</div>
                </button>
              </div>
            </div>
            <Link
              to={Routes.withPath(VISITS_DETAILS, [{ label: ID, value: visitId }])}
              className="personal-space-button link-as-button"
            >
              {Messages.t('visit.personalSpace')}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
