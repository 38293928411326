import { MarketPlaceCandidatureForm } from 'types/Candidature';
import sessionManager from 'services/sessionManager';
import { booleanToString, stringToBoolean } from 'lib/form/FormUtils';

const CANDIDATURE_FORM = 'CANDIDATURE_FORM';

const CANDIDATURE_SHOW_MODAL = 'CANDIDATURE_SHOW_MODAL';

export default class CandidatureFormUtils {
  private static getUserKey(): string {
    return `${CANDIDATURE_FORM}_${sessionManager.getSession()?.user_id}`;
  }

  private static getUserShowModalKey(): string {
    return `${CANDIDATURE_SHOW_MODAL}_${sessionManager.getSession()?.user_id}`;
  }

  public static setCandidatureForm(form: MarketPlaceCandidatureForm) {
    localStorage.setItem(this.getUserKey(), JSON.stringify(form));
  }

  public static getCandidatureForm(): MarketPlaceCandidatureForm | undefined {
    const searchStr = localStorage.getItem(this.getUserKey());
    if (searchStr && searchStr !== '') {
      return JSON.parse(searchStr);
    }
    return undefined;
  }

  public static setCandidatureFormModal(showModal: boolean) {
    localStorage.setItem(this.getUserShowModalKey(), booleanToString(showModal));
  }

  public static getCandidatureFormModal(): boolean | undefined {
    const showModalStr = localStorage.getItem(this.getUserShowModalKey());
    if (showModalStr && showModalStr !== '') {
      return stringToBoolean(showModalStr);
    }
    return undefined;
  }
}
