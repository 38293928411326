import { VisitDetailed } from 'types/Visit';
import Messages from 'services/i18n/Messages';
import settings from 'services/settings';
import { Routes } from 'routes/RoutesUtils';
import {
  ID,
  VISITS_DETAILS_CANCEL,
} from 'routes/Routes';

export const ICS_FILE_NAME = 'lokaa_visit';
const VERSION = '1.0.0';

export default class CalendarsUtils {
  static getTime(
    startDate: string,
    endDate: string,
    isClean = false,
  ): { start: string, end: string } {
    let start = new Date(startDate).toISOString().replace('.000', '');
    let end = new Date(endDate).toISOString().replace('.000', '');
    if (isClean) {
      start = start.replace(/-/g, '').replace(/:/g, '');
      end = end.replace(/-/g, '').replace(/:/g, '');
    }
    return { start, end };
  }

  static getVisitTitle(data: VisitDetailed): string {
    return Messages.t('visit.calendar.title', {
      nRooms: data.property.rooms || 'NR ',
      area: data.property.area || 'NR ',
      price: data.property.price || 'NR ',
      agencyName: data.agencyName,
      address: data.address,
    });
  }

  static getVisitCancelLink(data: VisitDetailed): string {
    return `${settings.baseUrl}${Routes.withPath(VISITS_DETAILS_CANCEL, [{ label: ID, value: data.id }])}`;
  }

  static getVisitBodyText(data: VisitDetailed): string {
    /* eslint-disable */
    return `
${Messages.t('visit.calendar.appartement', {
      nRooms: data.property.rooms || 'NR ',
      area: data.property.area || 'NR ',
      price: data.property.price || 'NR',
    })}
${data.address}\n
${Messages.t('visit.calendar.agency')}${data.agencyName}
${Messages.t('visit.calendar.agent')}${data.agent.firstname || ''} ${data.agent.lastname || ''}
${Messages.t('visit.calendar.note')}${data.notes}\n\n
${Messages.t('visit.calendar.cancel')}
`;
    /* eslint-enable */
  }

  static getVisitBody(data: VisitDetailed): string {
    /* eslint-disable */
    return `
<div>
<ul>
<li>
${Messages.t('visit.calendar.appartement', {
      nRooms: data.property.rooms || 'NR ',
      area: data.property.area || 'NR ',
      price: data.property.price || 'NR',
    })}
</li>
<li>
${data.address}
</li>
</ul>
<br/>
<div>
<b>
${Messages.t('visit.calendar.agency')}
</b>
${data.agencyName}
</div>
<div>
<b>
${Messages.t('visit.calendar.agent')}
</b>
${data.agent.firstname || ''} ${data.agent.lastname || ''}
</div>
<br/>
<div>
<b>
${Messages.t('visit.calendar.note')}
</b>
${data.notes?.replaceAll('\n', '<br/>')}
</div>
<br/>
<a href="${this.getVisitCancelLink(data)}">
${Messages.t('visit.calendar.cancel')}
</a>
</div>
`.toString().replaceAll('\n', '');
    /* eslint-enable */
  }

  static getGoogleUrl(data: VisitDetailed): string {
    // base url
    const url = 'https://calendar.google.com/calendar/render?action=TEMPLATE';
    const formattedDate = CalendarsUtils.getTime(data.startDate, data.endDate, true);
    return `${url}&dates=${formattedDate.start}%2F${formattedDate.end}&text=${this.getVisitTitle(data)}&location=${data.address}&details=${encodeURIComponent(this.getVisitBody(data))}`;
  }

  static getYahooUrl(data: VisitDetailed): string {
    // base url
    const url = 'https://calendar.yahoo.com/?v=60';
    const formattedDate = CalendarsUtils.getTime(data.startDate, data.endDate, true);
    return `${url}&st=${formattedDate.start}&et=${formattedDate.end}&title=${encodeURIComponent(this.getVisitTitle(data))}&in_loc=${encodeURIComponent(data.address)}&desc=${encodeURIComponent(this.getVisitBody(data))}`;
  }

  static getMicrosoftUrl(data: VisitDetailed, isOutlook: boolean): string {
    // base url
    let url = 'https://';
    if (isOutlook) {
      url = `${url}outlook.live.com`;
    } else {
      url = `${url}outlook.office.com`;
    }
    url = `${url}/calendar/0/deeplink/compose?path=%2Fcalendar%2Faction%2Fcompose&rru=addevent`;
    const formattedDate = CalendarsUtils.getTime(data.startDate, data.endDate, true);
    return `${url}&startdt=${formattedDate.start}&enddt=${formattedDate.end}&subject=${encodeURIComponent(this.getVisitTitle(data))}&location=${encodeURIComponent(data.address)}&body=${encodeURIComponent(this.getVisitBody(data))}`;
  }

  static getTeamsUrl(data: VisitDetailed): string {
    // base url
    const url = 'https://teams.live.com/_#/scheduling-form/?opener=1&navCtx=new-meeting-button&calendarType=User&webinarType=None';
    const formattedDate = CalendarsUtils.getTime(data.startDate, data.endDate, true);
    return `${url}&startTime=${formattedDate.start}&endTime=${formattedDate.end}&subject=${encodeURIComponent(this.getVisitTitle(data))}&location=${encodeURIComponent(data.address)}&content=${encodeURIComponent(this.getVisitBody(data))}`;
  }

  static generateIcs(data: VisitDetailed): string {
    let now = new Date().toISOString();
    const formattedDate = CalendarsUtils.getTime(data.startDate, data.endDate, true);

    const icsLines = ['BEGIN:VCALENDAR', 'VERSION:2.0'];
    const corp = 'kaliz.fr';
    icsLines.push(`PRODID:-// ${corp} // atcb v${VERSION} //EN`);
    icsLines.push('CALSCALE:GREGORIAN');
    icsLines.push('BEGIN:VEVENT');
    icsLines.push(`UID:${now}@add-to-calendar-button`);
    icsLines.push(
      `DTSTAMP:${formattedDate.start}`,
      `DTSTART:${formattedDate.start}`,
      `DTEND:${formattedDate.end}`,
      `SUMMARY:${this.getVisitTitle(data).slice(0, 75)}`, // making sure it does not exceed 75 characters per line
    );
    if (data.notes) {
      icsLines.push(
        `DESCRIPTION:${this.getVisitBodyText(data).replace(/\n/g, '\\n').replace(/.{60}/g, '$&\r\n ')}`,
      );
    }
    icsLines.push(`LOCATION:${data.address.replace(',', '\\,')}`);
    icsLines.push(`URL;VALUE=URI:${this.getVisitCancelLink(data)}`);
    now = now.replace(/\.\d{3}/g, '').replace(/[^a-z\d]/gi, '');
    icsLines.push('STATUS:CONFIRMED', `LAST-MODIFIED:${now}`, 'SEQUENCE:0', 'END:VEVENT', 'END:VCALENDAR');
    return `data:text/calendar;charset=utf-8,${encodeURIComponent(icsLines.join('\r\n'))}`;
  }
}
