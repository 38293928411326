import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDocumentBackend } from 'network/queries/DocumentQueries';
import CircularProgressBar from 'pages/common/CircularProgressBar';
import {
  Link,
  useHistory,
  useLocation,
} from 'react-router-dom';
import {
  APPLICATION,
  APPLICATION_DETAILS_INFO,
  APPLICATION_DOCUMENT,
  ID,
} from 'routes/Routes';
import { Routes } from 'routes/RoutesUtils';
import DocumentUtils from 'services/DocumentUtils';
import confirmationService from 'services/ConfirmationService';
import Messages from 'services/i18n/Messages';
import { NotificationService } from 'lib/notification';
import {
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import {
  ArrowLeft,
  ArrowRight,
  MoreVert,
} from '@material-ui/icons';
import { DocumentGroupCreation } from 'types/DocumentGroupCreation';
import { detectMobile } from 'services/utils';
import PageBackButton from 'pages/common/PageBackButton';
import StringUtils from 'services/StringUtils';
import ApplicationCreation from 'pages/client-app/client-application/ApplicationCreation';
import { CandidatureShort } from 'types/Candidature';

type Props = {
  isFullScreen: boolean,
  id?: string
  toCreateList: DocumentGroupCreation[],
  candidaturesData: CandidatureShort[],
};

const SCROLL_STEP = 200;

export default function ApplicationsList(
  {
    isFullScreen,
    id,
    toCreateList,
    candidaturesData,
  }: Props,
) {
  const documentQueries = useDocumentBackend();
  const [submitting, setSubmitting] = useState(false);
  const [showCreateApplication, setShowCreateApplication] = useState(false);
  const history = useHistory();
  const {
    getDocumentGroups,
    deleteDocumentGroup,
    createDocumentGroup,
  } = documentQueries;
  const {
    data: documents,
    isLoading,
  } = getDocumentGroups();

  const { pathname } = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedGroupId, setSelectedGroupId] = useState<string | undefined>();
  const ref = useRef<HTMLAnchorElement | null>(null);
  const refGroups = useRef<HTMLDivElement | null>(null);
  const [showScrollLeftButton, setShowScrollLeftButton] = useState(false);
  const [showScrollRightButton, setShowScrollRightButton] = useState(false);

  const handleClick = (event, groupId: string) => {
    setSelectedGroupId(groupId);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setSelectedGroupId(undefined);

    setAnchorEl(null);
  };

  const addDocumentGroup = (group: DocumentGroupCreation) => {
    setSubmitting(true);
    createDocumentGroup.mutateAsync(group)
      .then((res) => {
        history.push(Routes.withPath(APPLICATION_DOCUMENT, [{
          label: ID,
          value: res.id,
        }]));
      })
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => setSubmitting(false));
  };

  const deleteDocGroup = async (documentGroupId: string) => {
    const isConfirmed = await confirmationService.confirm({
      title: Messages.t('confirmation.documentGroupDelete.title'),
      message: Messages.t('confirmation.documentGroupDelete.message'),
    });
    if (!isConfirmed) {
      return;
    }
    deleteDocumentGroup.mutateAsync({
      documentGroupId,
    }).then(() => NotificationService.notifySuccess(Messages.t('notifications.delete')))
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')));
  };

  const mobileMode = detectMobile();

  const setRef = useCallback((node) => {
    if (node) {
      node.scrollIntoView({
        behavior: 'smooth',
        inline: 'start',
      });
    }
    ref.current = node;
  }, []);

  useEffect(() => {
    const updateScrollPosition = () => {
      if (refGroups.current
        && refGroups.current.offsetWidth !== refGroups.current.scrollWidth
        && refGroups.current.scrollLeft !== 0) {
        setShowScrollLeftButton(true);
      } else {
        setShowScrollLeftButton(false);
      }
      if (refGroups.current
        && refGroups.current.offsetWidth !== refGroups.current.scrollWidth
        && refGroups.current.scrollLeft + refGroups.current.offsetWidth
        < (refGroups.current.scrollWidth - 10)) {
        setShowScrollRightButton(true);
      } else {
        setShowScrollRightButton(false);
      }
    };

    if (refGroups && refGroups.current) {
      refGroups.current.addEventListener('scroll', updateScrollPosition);
      return () => {
        refGroups.current?.removeEventListener('scroll', updateScrollPosition);
      };
    }
    return () => {
    };
  }, [refGroups.current]);

  if (isFullScreen) {
    return <></>;
  }

  const scrollHorizontal = (scrollStep: number) => {
    if (refGroups.current) {
      refGroups.current.scrollBy({
        left: scrollStep,
        behavior: 'smooth',
      });
    }
  };

  if (isLoading) {
    return (
      <CircularProgress />
    );
  }
  return (
    <>
      {pathname !== APPLICATION && mobileMode && (
        <PageBackButton to={APPLICATION} />
      )}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {
          selectedGroupId && (
            <MenuItem
              className="danger-item"
              disabled={submitting}
              onClick={() => deleteDocGroup(selectedGroupId)}
            >
              {Messages.t('document.deleteAll')}
            </MenuItem>
          )
        }
      </Menu>
      <div className="application-list-container">
        <div
          className={`application-list ${(pathname === APPLICATION) ? 'application-page' : 'application-details-page'}`}
          ref={refGroups}
        >
          {
            documents && documents.length > 0 && documents
              .sort((a, b) => a.name.localeCompare(b.name)).map((group) => (
                <Link
                  to={Routes.withPath(APPLICATION_DETAILS_INFO, [{
                    label: ID,
                    value: group.id,
                  }])}
                  key={group.id}
                  ref={(group.id === id) ? setRef : null}
                  className={`client-application ${(pathname.includes(group.id)) ? 'selected' : ''}`}
                >
                  <div className="progress">
                    <CircularProgressBar
                      percent={DocumentUtils.getCompletionPercentFromTagGroup(group)}
                    />
                  </div>
                  <div className="group-name">{StringUtils.shortenString(group.name, 25)}</div>
                  <div className="recycle-bin">
                    <IconButton
                      onClick={(e) => {
                        handleClick(e, group.id);
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                    >
                      <MoreVert />
                    </IconButton>
                  </div>
                </Link>
              ))
          }
          {
            toCreateList?.sort((a, b) => a.name.localeCompare(b.name)).map((toCreate) => (
              <button
                key={toCreate.name}
                type="button"
                disabled={submitting}
                onClick={() => addDocumentGroup(toCreate)}
                className="client-application"
              >
                <div className="group-name">{toCreate.name}</div>
                <div className="progress">
                  <CircularProgressBar
                    percent={0}
                  />
                </div>
              </button>
            ))
          }
          <button
            type="button"
            disabled={submitting}
            onClick={() => setShowCreateApplication(true)}
            className="client-application"
          >
            <div className="group-name">{Messages.t('application.add')}</div>
          </button>
          {
            showCreateApplication && (
              <ApplicationCreation
                onClose={() => setShowCreateApplication(false)}
                candidaturesData={candidaturesData}
              />
            )
          }
        </div>
        {!mobileMode && showScrollRightButton && (
          <IconButton
            onClick={() => scrollHorizontal(SCROLL_STEP)}
            className="scroll-btn scroll-right"
          >
            <ArrowRight />
          </IconButton>
        )}
        {!mobileMode && showScrollLeftButton && (
          <IconButton
            onClick={() => scrollHorizontal(-SCROLL_STEP)}
            className="scroll-btn scroll-left"
          >
            <ArrowLeft />
          </IconButton>
        )}
      </div>
    </>
  );
}
