import React, { useState } from 'react';
import { Property } from 'types/Property';
import Button from 'theme/Button';
import Messages from 'services/i18n/Messages';
import CarrouselModal from 'lib/CarrouselModal';
import StringUtils from 'services/StringUtils';
import ImageGallery from 'pages/common/ImageGallery';
import { LocationMarkerIcon } from '@heroicons/react/solid';
import Description from 'pages/common/Description';
import { detectMobile } from 'services/utils';
import PropertyUtils from 'services/PropertyUtils';
import { DialogActions } from '@material-ui/core';

type Props = {
  property: Property,
  selectProperty: () => void
  showButton?: boolean,
};

export default function PropertyDetails(
  {
    property,
    selectProperty,
    showButton,
  }: Props,
) {
  const [carrouselModalOpen, setCarrouselModalOpen] = useState(false);
  const sortedImages = PropertyUtils.getSortedPropertyImage(property);
  return (
    <div className="property-detail">
      <div className="property-identifier">
        {
          `${Messages.t('property.reference')} : ${property.identifier}`
        }
      </div>
      <h3>
        {`${
          StringUtils.getPrettyAddress(property.city)
        } ${
          property.zipCode
        } - ${
          property.price
        }€ ${property.rooms ? `- ${
          property.rooms
        } ${Messages.t('form.field.rooms')}` : ''} - ${
          property.area
        } m²`}
      </h3>
      <div className="gallery-container">
        <ImageGallery property={property} />
        {
          !detectMobile() && (
            <Button
              className="see-more-button"
              onClick={() => setCarrouselModalOpen(true)}
            >
              {Messages.t('property.seePhotos')}
            </Button>
          )
        }
        {
          sortedImages && (
            <CarrouselModal
              onClose={() => setCarrouselModalOpen(false)}
              isOpen={carrouselModalOpen}
              imageUrls={sortedImages.map((image) => image.imageURL)}
            />
          )
        }
      </div>
      {
        property.address && (
          <div className="text-icon">
            <LocationMarkerIcon />
            <a className="address-link" href={`https://www.google.com/maps/place/${property.address}`}>
              {property.address}
            </a>
          </div>
        )
      }
      <Description descriptionProps={property.description} />
      {
        showButton && (
          <DialogActions>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                selectProperty();
              }}
              className="property-details-select-button"
            >
              {Messages.t('property.select')}
            </Button>
          </DialogActions>
        )
      }
    </div>
  );
}
