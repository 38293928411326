import React from 'react';
import { CandidatureShort } from 'types/Candidature';
import { VisitDetailed } from 'types/Visit';
import { useCandidatureBackend } from 'network/queries/CandidatureQueries';
import { NotificationService } from 'lib/notification';
import Messages from 'services/i18n/Messages';
import { isAfter, isBefore } from 'date-fns';
import { Link, useLocation } from 'react-router-dom';
import { visitStatusTypeEnum } from 'types/CandidatureBase';
import Button from 'theme/Button';

type Props = {
  candidatureData?: CandidatureShort,
  openVisits: VisitDetailed[],
  setSubmitting: (val: boolean) => void,
  submitting: boolean,
};

export default function CandidatureDetailsActions(
  {
    candidatureData,
    openVisits,
    setSubmitting,
    submitting,
  }: Props,
) {
  const now = new Date();
  const latestOpenVisit = openVisits.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  )[0];

  const candidaturesQuery = useCandidatureBackend();
  const { updateCandidature } = candidaturesQuery;
  const location = useLocation();

  const rejectVisit = (candidatureId: string) => {
    setSubmitting(true);
    updateCandidature.mutateAsync({ data: { isRefused: true }, candidatureId })
      .then(() => {
        NotificationService.notifySuccess(Messages.t('notifications.update'));
      })
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => setSubmitting(false));
  };
  if (candidatureData?.isRefused) {
    return null;
  }
  if (openVisits.length >= 1) {
    if (
      openVisits.some((visit) => isAfter((new Date(visit.startDate)), now))
    ) {
      return (
        <div className="candidature-info-tag proposed booked">
          <p>
            {Messages.t('candidature.upcomingVisit')}
            <Link to={{ pathname: `/visits/details/${latestOpenVisit.id}`, state: { prevPath: location.pathname } }}>
              {Messages.t('candidature.upcomingVisitDetails')}
            </Link>
          </p>
        </div>
      );
    }
    if (candidatureData?.visitStatus === visitStatusTypeEnum.PROPOSED) {
      return (
        <div className="candidature-info-tag proposed">
          <p>{Messages.t('candidate.invitedToVisit')}</p>
          <div className="action-btns">
            <Link
              to={`/visit-booking/${candidatureData.id}/visits`}
              target="_blank"
            >
              <Button disabled={submitting}>{Messages.t('candidate.chooseSlot')}</Button>
            </Link>
            <Button onClick={() => rejectVisit(candidatureData.id)} disabled={submitting}>
              {Messages.t('candidate.notInterested')}
            </Button>
          </div>
        </div>
      );
    }
    if (
      openVisits.some((visit) => isBefore((new Date(visit.startDate)), now))
    ) {
      return (
        <div className="candidature-info-tag proposed booked">
          <p>
            {Messages.t('candidature.pastVisit')}
            <Link to={{ pathname: `/visits/details/${latestOpenVisit.id}`, state: { prevPath: location.pathname } }}>
              {Messages.t('candidature.upcomingVisitDetails')}
            </Link>
          </p>
        </div>
      );
    }
  }
  if (candidatureData?.visitStatus === visitStatusTypeEnum.PROPOSED) {
    return (
      <div className="candidature-info-tag proposed">
        <p>{Messages.t('candidate.invitedToVisit')}</p>
        <div className="action-btns">
          <Link
            to={`/visit-booking/${candidatureData.id}/visits`}
            target="_blank"
          >
            <Button disabled={submitting}>{Messages.t('candidate.chooseSlot')}</Button>
          </Link>
          <Button onClick={() => rejectVisit(candidatureData.id)} disabled={submitting}>
            {Messages.t('candidate.notInterested')}
          </Button>
        </div>
      </div>
    );
  }
  return null;
}
